
// import { Link } from 'react-router-dom';


import moment from "moment-timezone";
import { useEffect, useState } from 'react';
import Picker from "react-mobile-picker";

import {
    INTAKE_TIME_OPTION_GROUPS,
} from "../../assets/utils/disordersInfo";
import './TakePopup.css';



function TakePopup({ takePopupOpened, setTakePopupOpened, handleTake, cardToTake, setCardToTake, setCardHeightToTake }) {

    const [step, setStep] = useState(0)
    // eslint-disable-next-line no-unused-vars
    const [timerValue, setTimerValue] = useState(0);

    const [timeValueGroups, setTimeValueGroups] = useState({
        hours: moment().format("HH").toString(),
        min: moment().format("mm").toString(),
    });



    useEffect(() => {
        if (timerValue <= 60) {
            const timer = setInterval(() => {
                setTimerValue(timerValue + 1)
                clearInterval(timer)
            }, 1000);

        } else {
            setTimerValue(0)
        }

    }, [timerValue])


    function handleClose() {
        setTakePopupOpened(false)
        setTimeout(() => {
            setCardToTake(null)
            setStep(0)
        }, 300);
        setCardHeightToTake(0)
    }

    // function handleTakeCard() {
    //     setTakePopupOpened(false)
    //     setTimeout(() => {
    //         setCardToTake(null)
    //         setStep(0)
    //     }, 950);

    //     setCardHeightToTake(0)
    //     handleTake()
    // }

    function handleTakeOnTime() {
        handleTake({
            time_taken: {
                hours: moment(cardToTake.time, 'HH:mm').format('HH'),
                minutes: moment(cardToTake.time, 'HH:mm').format('mm'),
            }, card_to_take: cardToTake
        })
        setTakePopupOpened(false)
        setTimeout(() => {
            setCardToTake(null)
            setStep(0)
        }, 950);


    }

    function handleTakeNow() {
        handleTake({
            time_taken: {
                hours: moment().format('HH'),
                minutes: moment().format('mm'),
            },
            card_to_take: cardToTake
        })
        setTakePopupOpened(false)
        setTimeout(() => {
            setCardToTake(null)
            setStep(0)
        }, 950);


    }

    function handleTakeExactTime() {
        setStep(1)
    }

    function handleTimeChange(name, value) {
        setTimeValueGroups((prewValueGroups) => ({
            ...prewValueGroups,
            [name]: value,
        }));
    }

    function handlePickTimeCancel() {
        setStep(0)
    }

    function handlePickTimeSave() {
        handleTake({
            time_taken: {
                hours: timeValueGroups.hours,
                minutes: timeValueGroups.min,
            },
            card_to_take: cardToTake
        })
        setTakePopupOpened(false)
        setTimeout(() => {
            setCardToTake(null)
            setStep(0)
        }, 950);
    }

    return (
        <div className={`take-popup ${takePopupOpened ? 'take-popup_active' : ''}`}>
            <div className={`take-popup__container ${takePopupOpened ? 'take-popup__container_active' : ''}`}>
                <svg className="take-popup__close" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClose}>
                    <path className="take-popup__close-fill" d="M2.98555 13.0263C3.17461 13.2155 3.4304 13.3219 3.69701 13.3219C3.96363 13.3219 4.21942 13.2155 4.40848 13.0263L7.98126 9.37669L11.554 13.0263C11.8171 13.2914 12.2008 13.3949 12.5604 13.2979C12.9199 13.2009 13.2009 12.9179 13.2972 12.5557C13.3935 12.1936 13.2907 11.8071 13.0275 11.5421L9.40421 7.94341L13.0275 4.34471C13.25 4.08314 13.3257 3.72558 13.2287 3.39526C13.1318 3.06494 12.8751 2.80641 12.5472 2.70874C12.219 2.61105 11.8642 2.68729 11.6044 2.91142L7.98125 6.51012L4.40847 2.91142C4.14879 2.68729 3.79381 2.61105 3.46587 2.70874C3.13792 2.80642 2.88126 3.06493 2.78429 3.39526C2.6873 3.72558 2.763 4.08314 2.98551 4.34471L6.55829 7.94341L2.98551 11.5421C2.78175 11.7342 2.66602 12.003 2.66602 12.2841C2.66602 12.5654 2.78175 12.8339 2.98551 13.0262L2.98555 13.0263Z" fill="white" />
                </svg>



                <div className='take-popup__content'>
                    {step === 0 &&
                        <>
                            <h3 className='take-popup__title'>When did you take your medication?</h3>
                            <div className='take-popup__btns'>
                                <button className='take-popup__btn' onClick={handleTakeOnTime}>
                                    <p className='take-popup__btn-text'>On time ({cardToTake && cardToTake.time})</p>
                                </button>
                                <button className='take-popup__btn' onClick={handleTakeNow}>
                                    <p className='take-popup__btn-text'>Now ({moment().format('HH:mm')}{ })</p>
                                </button>
                                <button className='take-popup__btn' onClick={handleTakeExactTime}>
                                    <p className='take-popup__btn-text'>Pick exact time</p>
                                </button>
                            </div>
                        </>
                    }
                    {step === 1 &&
                        <>
                            <h3 className='take-popup__title'>Pick exact time</h3>
                            <div className="take-popup__time-input">
                                <div className="take-popup__time-input-line" />
                                <p className="take-popup__time-separator">:</p>
                                <div className="take-popup__time-input-container">
                                    <Picker
                                        optionGroups={INTAKE_TIME_OPTION_GROUPS}
                                        valueGroups={timeValueGroups}
                                        onChange={handleTimeChange}
                                    />
                                </div>
                            </div>
                            <div className="take-popup__exact-time-btns">
                                <button className="take-popup__exact-time-btn" onClick={handlePickTimeCancel}>
                                    <p className="take-popup__exact-time-btn-text">Cancel</p>
                                </button>
                                <button className="take-popup__exact-time-btn" onClick={handlePickTimeSave}>
                                    <p className="take-popup__exact-time-btn-text">Save</p>
                                </button>
                            </div>
                        </>
                    }


                </div>


            </div>
            <div className={`take-popup__background ${takePopupOpened ? 'take-popup__background_active' : ''}`} onClick={handleClose}>

            </div>
        </div>

    );
}

export default TakePopup;