import { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import SeizuresQuestionare from "./SeizuresQuestionare/SeizuresQuestionare";
import SeizureDetails from "./SeizureDetails/SeizureDetails";
import GeneralQuestionare from "./GeneralQuestionare/GeneralQuestionare";
import { BACK_ARROW_ICON } from "../../assets/utils/icons";
import "./DailyQuestionare.css";
import mainApi from "../../assets/api/mainApi";
import MiniPreloader from "../MiniPreloader/MiniPreloader";

const tracker = Array(2).fill("");

function DailyQuestionare({ setCurrentUser }) {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [index, setIndex] = useState(0);
  const [episode, setEpisode] = useState({});
  const [symptoms, setSymptoms] = useState({
    seizure: undefined,
    details: [],
  });
  const [isFormValid, setFormValid] = useState(false);
  const [timeValueGroups, setTimeValueGroups] = useState({
    hours: moment().format("HH"),
    min: moment().format("mm"),
  });
  const [durationValueGroups, setDurationValueGroups] = useState({
    min: 0,
    sec: 0,
  });
  const [seizureSeverity, setSeizureSeverity] = useState(1);
  const [medicalCondition, setMedicalCondition] = useState(1);
  const [mentalCondition, setMentalCondition] = useState(1);

  function handleAddButtonClick() {
    setStep("0-episode");
    setIndex(symptoms.details.length);
    setEpisode({
      exactTime: {},
      duration: {},
    });
    setTimeValueGroups({
      hours: moment().format("HH"),
      min: moment().format("mmm"),
    });
    setDurationValueGroups({
      min: 0,
      sec: 0,
    });
    setSeizureSeverity(1);
  }

  function handleEditClick(i) {
    setStep("0-episode");
    setIndex(i);
    setEpisode(symptoms.details[i]);
    setTimeValueGroups({
      hours: symptoms.details[i].exactTime.hours,
      min: symptoms.details[i].exactTime.min,
    });
    setDurationValueGroups({
      min: symptoms.details[i].duration.min,
      sec: symptoms.details[i].duration.sec,
    });
    setSeizureSeverity(symptoms.details[i].severity);
  }

  function handleTimeChange(name, value) {
    setTimeValueGroups((prewValueGroups) => ({
      ...prewValueGroups,
      [name]: value,
    }));
  }

  function handleDurationChange(name, value) {
    setDurationValueGroups((prewValueGroups) => ({
      ...prewValueGroups,
      [name]: value,
    }));
  }

  function handleSelectChange(name, value) {
    setEpisode({ ...episode, [name]: value });
  }
  const [isPreloaderVisible, setPreloaderVisible] = useState(false)
  const [submitError, setSubmitError] = useState('')

  function handleContinue() {
    switch (true) {
      case step === "0-episode":
        {
          const arr = symptoms.details.slice();
          arr[index] = { ...episode, severity: seizureSeverity };
          setSymptoms({ ...symptoms, details: arr });
          setStep(0);
        }
        break;

      case step === 1:
        if (!submitError && !isPreloaderVisible) {
          setSubmitError('')
          setPreloaderVisible(true)
          console.log({
            symptoms,
            medicalCondition,
            mentalCondition,
          })
          let formatedValues = {}
          if (symptoms.seizure) {
            formatedValues.seizures = symptoms.details.map((item) => {
              return {
                time: {
                  hours: Number(item.exactTime.hours),
                  minutes: Number(item.exactTime.min),
                },
                duration: {
                  seconds: Number(item.duration.sec),
                  minutes: Number(item.duration.min),
                },
                severity: item.severity - 1,
              }
            })
          } else {
            formatedValues.seizures = []
          }
          formatedValues.questionnaire = {
            general_medical_condition: medicalCondition - 1,
            general_mental_condition: mentalCondition - 1,
          }
          console.log(formatedValues)
          mainApi.answerQuestionaire({ seizures: formatedValues.seizures, questionnaire: formatedValues.questionnaire })
            .then((res) => {
              console.log(res)
              setCurrentUser(prevItem => ({
                ...prevItem,
                suggest_new_questionnaire: false,
              }))
              setStep(step + 1);
            })
            .catch((err) => {
              console.log(err)
              if (err.detail) {
                setSubmitError(err.detail)
              } else {
                setSubmitError('Something went wrong')
              }
            })
            .finally(() => {
              setPreloaderVisible(false)
              setTimeout(() => {
                setSubmitError('')
              }, 5000);
            })
        }

        // 
        break;

      case step === 2:
        navigate("/");
        // 
        break;
      default:
        setStep(step + 1);
    }
  }

  function handleBackStep() {
    if (step === 0) {
      navigate(-1)
    } else {
      switch (true) {
        case step === "0-episode":
          setStep(0);
          setEpisode({});
          break;
        default:
          setStep(step - 1);
      }
    }

  }

  return (
    <section className="daily">
      <div
        className={`daily__content ${step === 2 ? "daily__content_type_finish" : ""
          }`}
      >
        {step !== 2 ? (
          <div className="daily__step-tracker">
            {tracker.map((item, i) => (
              <div
                className={`daily__tracker-item ${step.toString().includes(i)
                  ? "daily__tracker-item_active"
                  : ""
                  }`}
                key={i}
              />
            ))}
          </div>
        ) : null}

        {step === 0 ? (
          <SeizuresQuestionare
            {...{
              symptoms,
              setSymptoms,
              setFormValid,
              index,
              setIndex,
            }}
            onAddClick={handleAddButtonClick}
            onEditClick={handleEditClick}
          />
        ) : null}

        {step === "0-episode" ? (
          <SeizureDetails
            {...{
              episode,
              seizureSeverity,
              setSeizureSeverity,
              timeValueGroups,
              handleTimeChange,
              durationValueGroups,
              handleDurationChange,
              handleSelectChange,
              setFormValid,
            }}
          />
        ) : null}

        {step === 1 ? (
          <GeneralQuestionare
            {...{
              medicalCondition,
              setMedicalCondition,
              mentalCondition,
              setMentalCondition,
              setFormValid,
            }}
          />
        ) : null}

        <p className="daily__submit-error">{submitError}</p>

        {step === 2 ? (
          <div className="daily__finish-block">
            <p className="daily__finish-title">
              Congratulations, you have successfully completed the questionnaire!
            </p>
            <p className="daily__finish-text">
              Thank you for your participation
            </p>
          </div>
        ) : null}

        <div className="daily__buttons-container">
          <div
            className={`daily__buttons-box ${step === 2 ? "daily__buttons-box_type_center" : ""
              }`}
          >
            {step !== 2 ? (
              <button
                className="daily__back-button"
                type="button"
                onClick={handleBackStep}
              >
                {BACK_ARROW_ICON}
              </button>
            ) : null}
            <button
              className={`daily__next-button ${!isFormValid || submitError ? "daily__next-button_disabled" : ""
                }`}
              type="button"
              onClick={handleContinue}
            >
              {isPreloaderVisible ? <MiniPreloader isLinkColor={true} /> : step === 2 ? "Done" : "Continue"}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DailyQuestionare;
