import { useEffect, useState } from "react";
import Input from "../../Input/Input";
import SelectInput from "../../SelectInput/SelectInput";
import {
  MEDICINE_MEASURE_LIST,
  MEDICINE_TYPE_LIST,
  MEDICINE_FOOD_INTAKE_LIST,
} from "../../../assets/utils/disordersInfo";
import "./MedicineForm.css";
import mainApi from "../../../assets/api/mainApi";

function MedicineForm({
  title,
  drug,
  isFormValid,
  handleChange,
  handleSelectChange,
  setFormValid,
  setDrugFormValid,
}) {
  useEffect(() => {
    const form = document.getElementById("form");
    setFormValid(form.checkValidity());
  }, [drug, setFormValid]);

  useEffect(() => {
    Boolean(drug.type) && Boolean(drug.foodIntake) && isFormValid
      ? setDrugFormValid(true)
      : setDrugFormValid(false);
  }, [drug, isFormValid, setDrugFormValid]);


  const [userSyndromes, setUserSyndromes] = useState([])
  useEffect(() => {
    mainApi.getSyndromes({ limit: 20 })
      .then((res) => {
        console.log(res)
        setUserSyndromes(res.user_syndromes)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])


  return (
    <div className="medicine-form">
      <p className="medicine-form__title">{title}</p>
      <div className="medicine-form__form-container">
        {/* <Input
          label="What is this medication for?"
          value={drug.diagnosis}
          name="diagnosis"
          type="text"
          handleChange={handleChange}
          isRequired={true}
        /> */}
        <SelectInput
          label="What is this medication for?"
          value={drug.diagnosis}
          name="diagnosis"
          isRequired={true}
          selectList={userSyndromes ? userSyndromes.map((item)=>{
            return{
              _id: item._id,
              text: item.syndrome.text
            }
          }) : []}
          popupTitle="Select syndrome"
          onSelect={handleSelectChange}
        />

        <Input
          label="Medicine name"
          value={drug.name}
          name="name"
          type="text"
          handleChange={handleChange}
          isRequired={true}
        />

        <div className="medicine-form__dosage-block">
          <Input
            label="Dosage"
            value={drug.dosage}
            name="dosage"
            type="text"
            inputMode="numeric"
            handleChange={handleChange}
            isRequired={true}
          />

          <SelectInput
            label=""
            value={drug.measure}
            name="measure"
            isRequired={true}
            selectList={MEDICINE_MEASURE_LIST}
            popupTitle="Select units of dosage measurement"
            onSelect={handleSelectChange}
          />
        </div>

        <Input
          label="Duration of this dose in days"
          value={drug.duration}
          name="duration"
          type="text"
          inputMode="numeric"
          handleChange={handleChange}
          isRequired={true}
        />

        <div className="medicine-form__select-card-block">
          <p className="medicine-form__inputs-title">Medicine type</p>
          <ul className="medicine-form__select-card-list">
            {MEDICINE_TYPE_LIST.map((type, i) => (
              <li
                className={`medicine-form__select-card ${drug.type === type.type
                    ? "medicine-form__select-card_selected"
                    : ""
                  }`}
                key={`medicine-type${i}`}
                onClick={() => handleSelectChange("type", type.type)}
              >
                <img
                  className="medicine-form__type-image"
                  src={type.image}
                  alt={type.type}
                />
                <p className="medicine-form__type-text">{type.type.substring(0,1).toUpperCase() + type.type.substring(1)}</p>
              </li>
            ))}
          </ul>
        </div>

        <div className="medicine-form__select-card-block">
          <p className="medicine-form__inputs-title">When you take it</p>
          <ul className="medicine-form__select-card-list">
            {MEDICINE_FOOD_INTAKE_LIST.map((food) => (
              <li
                className={`medicine-form__select-card medicine-form__select-card_type_food ${drug.foodIntake === food.text
                    ? "medicine-form__select-card_selected"
                    : ""
                  }`}
                key={food.text}
                onClick={() => handleSelectChange("foodIntake", food.text)}
              >
                <div className="medicine-form__food-icon">{food.image}</div>
                <p className="medicine-form__food-text">{food.text}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MedicineForm;
