import { NavLink } from "react-router-dom";
import "./Navigation.css";

function Navigation() {
  return (
    <nav className="nav">
      <ul className="nav__list">
        <li className="nav__item">
          <NavLink
            to="/"
            className={({ isActive }) =>
              "nav__link" + (isActive ? " nav__link_active" : "")
            }
          >
            <div className="nav__item-icon-container">
              <svg className="nav__item-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.0001 19.0007V14.0007H14.0001V19.0007C14.0001 19.5507 14.4501 20.0007 15.0001 20.0007H18.0001C18.5501 20.0007 19.0001 19.5507 19.0001 19.0007V12.0007H20.7001C21.1601 12.0007 21.3801 11.4307 21.0301 11.1307L12.6701 3.6007C12.2901 3.2607 11.7101 3.2607 11.3301 3.6007L2.9701 11.1307C2.6301 11.4307 2.8401 12.0007 3.3001 12.0007H5.0001V19.0007C5.0001 19.5507 5.4501 20.0007 6.0001 20.0007H9.0001C9.5501 20.0007 10.0001 19.5507 10.0001 19.0007Z" fill="#323232" />
              </svg>


            </div>
            <p className="nav__link-text">Home</p>
          </NavLink>
        </li>
        <li className="nav__item">
          <NavLink
            to="/history"
            className={({ isActive }) =>
              "nav__link" + (isActive ? " nav__link_active" : "")
            }
          >
            <div className="nav__item-icon-container">
              <svg className="nav__item-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 3C8.03 3 4 7.03 4 12H1L4.89 15.89L4.96 16.03L9 12H6C6 8.13 9.13 5 13 5C16.87 5 20 8.13 20 12C20 15.87 16.87 19 13 19C11.07 19 9.32 18.21 8.06 16.94L6.64 18.36C8.27 19.99 10.51 21 13 21C17.97 21 22 16.97 22 12C22 7.03 17.97 3 13 3ZM12 8V13L16.25 15.52L17.02 14.23L13.5 12.14V8H12Z" fill="#323232" />
              </svg>

            </div>
            <p className="nav__link-text">History</p>
          </NavLink>
        </li>
        <li className="nav__item">
          <NavLink
            to="/profile"
            className={({ isActive }) =>
              "nav__link" + (isActive ? " nav__link_active" : "")
            }
          >
            <div className="nav__item-icon-container">
              <svg className="nav__item-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z" fill="#323232" />
              </svg>
            </div>
            <p className="nav__link-text">Profile</p>
          </NavLink>
        </li>

      </ul>
    </nav>
  );
}

export default Navigation;
