import React, { useEffect, useRef, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { AddToHomeScreen } from 'react-pwa-add-to-homescreen';
// import moment from 'moment';

import Register from "../Register/Register";
import Disorders from "../Register/Disorders/Disorders";
import Onboarding from "../Onboarding/Onboarding";
import CountryPopup from '../CountryPopup/CountryPopup';
import Main from '../Main/Main';
import Profile from '../Profile/Profile';
import PersonalData from '../Profile/PersonalData/PersonalData';
import MedicationData from '../Profile/MedicationData/MedicationData';
import SkipPopup from '../SkipPopup/SkipPopup';
import TakePopup from '../TakePopup/TakePopup';
import Navigation from '../Navigation/Navigation';
import Questionare from '../Questionare/Questionare';
import DailyQuestionare from '../DailyQuestionare/DailyQuestionare';

import "./App.css";
import mainApi from '../../assets/api/mainApi';
import Login from '../Register/Login/Login';
import Preloader from '../Preloader/Preloader';
import moment from 'moment';
import { formatMedicineToShedule } from '../../assets/utils/utilis';
import TakeResultPopup from '../TakeResultPopup/TakeResultPopup';
import History from '../History/History';
import MedicineInfoPopup from '../MedicineInfoPopup/MedicineInfoPopup';
import { countries } from '../../assets/utils/countries';
import ChangeEmail from '../ChangeEmail/ChangeEmail';
import ChangePassword from '../ChangePassword/ChangePassword';


// function sortCards(array) {
//   console.log({ sort: array })
//   return array.sort(function (a, b) {
//     if (a.time && b.time) {
//       if (Number(moment(a.time).format('x')) > Number(moment(b.time).format('x'))) return 1
//       else if (Number(moment(a.time).format('x')) < Number(moment(b.time).format('x'))) return -1
//       else if (Number(moment(a.time).format('x')) === Number(moment(b.time).format('x'))) {
//         if (a.type_of_medication_intake === 'Before food' && b.type_of_medication_intake === 'Before food') return 0
//         else if (a.type_of_medication_intake === 'Before food' && b.type_of_medication_intake === 'With food') return -1
//         else if (a.type_of_medication_intake === 'Before food' && b.type_of_medication_intake === 'After food') return -1

//         else if (a.type_of_medication_intake === 'With food' && b.type_of_medication_intake === 'With food') return 0
//         else if (a.type_of_medication_intake === 'With food' && b.type_of_medication_intake === 'Before food') return 1
//         else if (a.type_of_medication_intake === 'With food' && b.type_of_medication_intake === 'After food') return -1

//         else if (a.type_of_medication_intake === 'After food' && b.type_of_medication_intake === 'After food') return 0
//         else if (a.type_of_medication_intake === 'After food' && b.type_of_medication_intake === 'With food') return 1
//         else if (a.type_of_medication_intake === 'After food' && b.type_of_medication_intake === 'Before food') return 1

//         else return 0
//       }
//       else return 0
//     }
//     else if (a.time && !b.time) {
//       return -1
//     }
//     else if (!a.time && b.time) {
//       return 1
//     }
//     else if (!a.time && !b.time) {
//       if (a.type_of_medication_intake === 'Before food' && b.type_of_medication_intake === 'Before food') return 0
//       else if (a.type_of_medication_intake === 'Before food' && b.type_of_medication_intake === 'With food') return -1
//       else if (a.type_of_medication_intake === 'Before food' && b.type_of_medication_intake === 'After food') return -1

//       else if (a.type_of_medication_intake === 'With food' && b.type_of_medication_intake === 'With food') return 0
//       else if (a.type_of_medication_intake === 'With food' && b.type_of_medication_intake === 'Before food') return 1
//       else if (a.type_of_medication_intake === 'With food' && b.type_of_medication_intake === 'After food') return -1

//       else if (a.type_of_medication_intake === 'After food' && b.type_of_medication_intake === 'After food') return 0
//       else if (a.type_of_medication_intake === 'After food' && b.type_of_medication_intake === 'With food') return 1
//       else if (a.type_of_medication_intake === 'After food' && b.type_of_medication_intake === 'Before food') return 1

//       else return 0
//     }
//     else return 0
//   })
// }

function App() {
  const appRef = useRef(null)
  const navigate = useNavigate()

  const location = useLocation()

  const [currentUser, setCurrentUser] = useState(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : undefined)
  const [countryPopupOpened, setCountryPopupOpened] = useState(false)
  const [skipPopupOpened, setSkipPopupOpened] = useState(false)
  const [takePopupOpened, setTakePopupOpened] = useState(false)
  const [takeResultPopupOpened, setTakeResultPopupOpened] = useState(false)
  const [medInfoPopupOpened, setMedInfoPopupOpened] = useState(false)

  const [weeklyHistory, setWeeklyHistory] = useState(undefined)


  const [takeError, setTakeError] = useState('')

  const [cardToSkip, setCardToSkip] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [cardHeightToSkip, setCardHeightToSkip] = useState('')

  const [cardToTake, setCardToTake] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [cardHeightToTake, setCardHeightToTake] = useState('')

  const [shedule, setShedule] = useState(undefined)
  const [cards, setCards] = useState(undefined)
  // eslint-disable-next-line no-unused-vars
  const [sheduleMissed, setSheduleMissed] = useState(undefined)
  // eslint-disable-next-line no-unused-vars
  const [cardsMissed, setCardsMissed] = useState(undefined)

  const [cardToEdit, setCardToEdit] = useState(null)


  const [isMainPreloaderVislible, setMainPreloaderVislible] = useState(true)


  const [sheduleHistory, setSheduleHistory] = useState(undefined)
  const [cardsHistory, setCardsHistory] = useState(undefined)


  function getCurentUser() {
    setMainPreloaderVislible(true)
    mainApi.getAppVersion()
      .then((res_ver) => {
        let prevVER = localStorage.getItem('app-v')
        if (prevVER !== res_ver.version || !prevVER) {
          window.location.reload(true);
          localStorage.setItem('app-v', res_ver.version)
        }
        mainApi.getUser()
          .then((res) => {
            setCurrentUser(res)
            console.log(res)
            if (res.register_stage === 0 || res.register_stage === 1) {
              navigate('/sign-up')
              setMainPreloaderVislible(false)
            } else if (res.register_stage > 1 && res.register_stage < 10) {
              navigate('/sign-up/disorders')
              setMainPreloaderVislible(false)
            } else {
              setCountryValue(countries.filter((item) => {
                if (item.name === res.country) return true
                else return false
              })[0].name)
              mainApi.getTodayIntakes({ limit: 10 })
                .then((res_medicine_for_now) => {

                  setCards(res_medicine_for_now.medicine_intakes)
                  setShedule(formatMedicineToShedule({ actualShedule: [], cardsToFormat: res_medicine_for_now.medicine_intakes }))
                  mainApi.getMissedIntakes({ limit: 10 })
                    .then((res_medicine_missed) => {

                      console.log(res_medicine_missed)
                      setCardsMissed(res_medicine_missed.intakes)
                      setSheduleMissed(formatMedicineToShedule({ actualShedule: [], cardsToFormat: res_medicine_missed.intakes, type: 'missed' }))

                      if (location.pathname === "/profile/medication-data" || location.pathname === "/profile" || location.pathname === "/history" || location.pathname === '/change-email' || location.pathname === '/change-password') {
                        // navigate('/')
                      } else {
                        navigate('/')
                      }
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                    .finally(() => {
                      setMainPreloaderVislible(false)
                    })

                })
                .catch((err) => {
                  console.log(err)
                  setMainPreloaderVislible(false)
                })


            }
          })
          .catch((err) => {
            navigate('/')
            console.log(err)
            setMainPreloaderVislible(false)
          })

      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setTimeout(() => {
          if (!currentUser) {
            setMainPreloaderVislible(false)
          }
        }, 10000);

      })
  }

  useEffect(() => {

    getCurentUser()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [timerValue, setTimerValue] = useState(0);


  useEffect(() => {
    let prevDate = localStorage.getItem('realDate')
    let realDate = moment().format('DD.MM.YYYY')
    if (!prevDate || (realDate !== prevDate)) {
      localStorage.setItem('realDate', realDate)
      getCurentUser()
    }
    const timer = setInterval(() => {
      setTimerValue(timerValue + 1)
      clearInterval(timer)
    }, 10000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerValue])


  // useEffect(() => {
  //   if (currentUser) {
  //     let cardsByDate = JSON.parse(localStorage.getItem('cardsByDate'))
  //     let usersCards = JSON.parse(localStorage.getItem('cards'))
  //     console.log({cardsByDate, usersCards})
  //     if (usersCards) {
  //       if (cardsByDate && cardsByDate.date === moment().format('DD.MM.YYYY')) {
  //         setCards(sortCards(cardsByDate.cardsForDate))
  //       } else {
  //         localStorage.setItem('cardsByDate', JSON.stringify({
  //           date: moment().format('DD.MM.YYYY'),
  //           cardsForDate: usersCards
  //         }))
  //         setCards(sortCards(usersCards))
  //       }
  //     }
  //     console.log(currentUser)
  //   } else {
  //     setCards(sortCards(defCards))
  //   }
  // }, [currentUser, location.pathname])

  //Medicine taken or discontinued
  const [medicine, setMedicine] = useState([]);

  useEffect(() => {
    localStorage.setItem("medicine", JSON.stringify(medicine));
  }, [medicine]);

  //Country
  const [countryValue, setCountryValue] = useState('')

  //SWIPE ON EDGES
  // useEffect(() => {
  //   if (appRef && appRef.current) {

  //     appRef.current.addEventListener('touchstart', (e) => {
  //       if (e.pageX > 25 && e.pageX < window.innerWidth - 25) return;
  //       e.preventDefault();
  //     });
  //   }
  // }, [appRef])

  const [scrollWindowPosition, setScrollWindowPosition] = useState(0);
  const [popupScrollPosition, setPopupScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;

    setScrollWindowPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  useEffect(() => {
    if (countryPopupOpened || takePopupOpened) {
      document.body.style.overflow = 'hidden';
      document.body.style.top = `-${scrollWindowPosition}px`;
      setPopupScrollPosition(scrollWindowPosition)
      setTimeout(() => {
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
        document.body.style.height = '100%';
      }, 10);

      console.log(window.pageYOffset)

    } else {
      document.body.style.overflow = 'unset';
      document.body.style.position = 'unset';
      document.body.style.width = 'unset';
      document.body.style.height = 'unset';
      document.body.style.top = `unset`;
      console.log(popupScrollPosition)
      window.scrollTo(0, popupScrollPosition)
      setScrollWindowPosition(popupScrollPosition)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryPopupOpened, takePopupOpened]);

  function handleSkipPopupOpen({ card, height }) {
    setCardToSkip(card)
    setCardHeightToSkip(height)
    console.log(height)
    setSkipPopupOpened(true)
  }

  function handleTakePopupOpen({ card, height }) {
    setCardToTake(card)
    setCardHeightToTake(height)
    console.log(height)
    setTakePopupOpened(true)
  }

  function handleEditPopupOpend({ card }) {
    setCardToEdit(card)
    setMedInfoPopupOpened(true)
  }

  function handleSkip({ card_to_skip, reasons }) {
    if (card_to_skip.editToSkip || card_to_skip.status === "missed") {
      mainApi.editIntake({
        medicine_id: card_to_skip.medicine_object._id,
        medicine_intake_id: card_to_skip._id,
        taken: false,
        reason: reasons,
        frequency: card_to_skip.frequency,
      })
        .then(() => {
          if (card_to_skip.status === "missed") {
            setSheduleMissed(prevItem => prevItem.map((shedule_item) => {
              let items = shedule_item.items.filter((card_item) => {
                if (card_item._id === card_to_skip._id) return false
                else return true
              })
              return {
                time: shedule_item.time,
                items: items
              }

            }).filter((item) => {
              if (item.items.length > 0) return true
              else return false
            }))
          }

          setSheduleHistory(prevItem => prevItem.map((shedule_item) => {
            let items = shedule_item.items.map((card_item) => {
              if (card_item._id === card_to_skip._id) return {
                ...card_item,
                status: 'skipped'
              }
              else return card_item
            })
            return {
              ...shedule_item,
              items: items
            }
          }))
          setTakeResultPopupOpened(true)
          mainApi.getWeeklyHistory()
            .then((res_weekly) => {
              if (res_weekly.percent < 10) {
                res_weekly.percent = ((parseInt(Number(res_weekly.percent) * 10)) / 10)
              } else {
                res_weekly.percent = ((parseInt(Number(res_weekly.percent) * 1)) / 1)
              }
              let end_day = moment(`${res_weekly.end_day}`)
              let start_day = moment(`${res_weekly.start_day}`)
              let end_week_day = {
                D: end_day.format('D'),
                MM: end_day.format('MM'),
                MMM: end_day.format('MMM'),
                MMMM: end_day.format('MMMM'),
              }
              let start_week_day = {
                D: start_day.format('D'),
                MM: start_day.format('MM'),
                MMM: start_day.format('MMM'),
                MMMM: start_day.format('MMMM'),
              }
              setWeeklyHistory({
                ...res_weekly,
                end_week_day: end_week_day,
                start_week_day: start_week_day,
              })
              console.log(res_weekly)
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      console.log({ card_to_skip, reasons })
      mainApi.takeOrSkipIntake({
        medicine_id: card_to_skip.medicine_object._id,
        medicine_intake_id: card_to_skip._id,
        taken: false,
        reason: reasons
      })
        .then((res) => {
          console.log(res)
          setShedule(prevItem => prevItem.map((shedule_item) => {
            let items = shedule_item.items.filter((card_item) => {
              if (card_item._id === card_to_skip._id) return false
              else return true
            })
            return {
              time: shedule_item.time,
              items: items
            }

          }).filter((item) => {
            if (item.items.length > 0) return true
            else return false
          }))
          setSheduleMissed(prevItem => prevItem.map((shedule_item) => {
            let items = shedule_item.items.filter((card_item) => {
              if (card_item._id === card_to_skip._id) return false
              else return true
            })
            return {
              time: shedule_item.time,
              items: items
            }

          }).filter((item) => {
            if (item.items.length > 0) return true
            else return false
          }))


        })
        .catch((err) => {


          console.log(err)
        })

    }

    // let cardToSkipFound = false
    // let cards_with_animation = cards.map((item, i) => {
    //   console.log({ cardToSkipFound, i })
    //   if (!cardToSkipFound) {
    //     if (item.id === cardToSkip.id) {
    //       cardToSkipFound = true
    //       return {
    //         ...item,
    //         skipping: true,
    //       }
    //     } else {
    //       return item
    //     }
    //   } else {
    //     return {
    //       ...item,
    //       animate_up: true,
    //       pixels_up: cardHeightToSkip,
    //     }
    //   }
    // })

    // console.log(cards_with_animation)
    // setCards(cards_with_animation)

    // setTimeout(() => {
    //   let pageHeight = Math.max(
    //     appRef.current.scrollHeight,
    //     appRef.current.offsetHeight,
    //     appRef.current.clientHeight
    //   );
    //   // console.log(window.scrollY - cardHeightToSkip, scheduleRef.current.offsetTop, window.scrollY - cardHeightToSkip >= cardHeightToSkip)
    //   if (pageHeight - (window.scrollY + window.innerHeight) <= cardHeightToSkip && window.scrollY - cardHeightToSkip >= cardHeightToSkip) {
    //     window.scrollBy({ top: -cardHeightToSkip - 12, behavior: "smooth" });
    //   }
    // }, 450);

    // setTimeout(() => {
    //   let upd_cards = cards.filter((item) => {
    //     if (item.id === cardToSkip.id) return false
    //     else return true
    //   })
    //   upd_cards = upd_cards.map((item) => {
    //     let upd_item = item
    //     if (upd_item.animate_up) {
    //       delete upd_item.animate_up
    //     }
    //     if (upd_item.pixels_up) {
    //       delete upd_item.pixels_up
    //     }
    //     return upd_item
    //   })

    //   console.log(upd_cards)
    //   setCards(upd_cards)
    //   // setTimeout(() => {
    //   //   if (currentUser) {
    //   //     let cardsByDate = JSON.parse(localStorage.getItem('cardsByDate'))
    //   //     if (cardsByDate) {
    //   //       cardsByDate.cardsForDate = upd_cards
    //   //       localStorage.setItem('cardsByDate', JSON.stringify(cardsByDate))
    //   //     }
    //   //   }
    //   // }, 1000);
    // }, 900);
  }

  function handleTake({ time_taken, card_to_take }) {
    console.log(card_to_take)
    setTakeError('')
    if (card_to_take.editToTake || card_to_take.status === "missed") {
      mainApi.editIntake({
        medicine_id: card_to_take.medicine_object._id,
        medicine_intake_id: card_to_take._id,
        taken: true,
        time: {
          hours: Number(time_taken.hours),
          minutes: Number(time_taken.minutes)
        },
        frequency: card_to_take.frequency,
      })
        .then(() => {
          if (card_to_take.status === "missed") {
            setSheduleMissed(prevItem => prevItem.map((shedule_item) => {
              let items = shedule_item.items.filter((card_item) => {
                if (card_item._id === card_to_take._id) return false
                else return true
              })
              return {
                time: shedule_item.time,
                items: items
              }

            }).filter((item) => {
              if (item.items.length > 0) return true
              else return false
            }))
          }
          setSheduleHistory(prevItem => prevItem.map((shedule_item) => {
            let items = shedule_item.items.map((card_item) => {
              if (card_item._id === card_to_take._id) return {
                ...card_item,
                status: 'intaked'
              }
              else return card_item
            })
            return {
              ...shedule_item,
              items: items
            }
          }))
          setTakeResultPopupOpened(true)
          mainApi.getWeeklyHistory()
            .then((res_weekly) => {
              if (res_weekly.percent < 10) {
                res_weekly.percent = ((parseInt(Number(res_weekly.percent) * 10)) / 10)
              } else {
                res_weekly.percent = ((parseInt(Number(res_weekly.percent) * 1)) / 1)
              }
              let end_day = moment(`${res_weekly.end_day}`)
              let start_day = moment(`${res_weekly.start_day}`)
              let end_week_day = {
                D: end_day.format('D'),
                MM: end_day.format('MM'),
                MMM: end_day.format('MMM'),
                MMMM: end_day.format('MMMM'),
              }
              let start_week_day = {
                D: start_day.format('D'),
                MM: start_day.format('MM'),
                MMM: start_day.format('MMM'),
                MMMM: start_day.format('MMMM'),
              }
              setWeeklyHistory({
                ...res_weekly,
                end_week_day: end_week_day,
                start_week_day: start_week_day,
              })
              console.log(res_weekly)
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch((err) => {
          if (err.detail) {
            setTakeError(err.detail)
          } else {
            setTakeError('Something went wrong')
          }
        })
    } else {
      console.log({ time_taken, card_to_take })
      mainApi.takeOrSkipIntake({
        medicine_id: card_to_take.medicine_object._id,
        medicine_intake_id: card_to_take._id,
        taken: true,
        time: {
          hours: Number(time_taken.hours),
          minutes: Number(time_taken.minutes)
        }
      })
        .then((res) => {
          console.log(res)
          setShedule(prevItem => prevItem.map((shedule_item) => {
            let items = shedule_item.items.filter((card_item) => {
              if (card_item._id === card_to_take._id) return false
              else return true
            })
            return {
              time: shedule_item.time,
              items: items
            }

          }).filter((item) => {
            if (item.items.length > 0) return true
            else return false
          }))

          setSheduleMissed(prevItem => prevItem.map((shedule_item) => {
            let items = shedule_item.items.filter((card_item) => {
              if (card_item._id === card_to_take._id) return false
              else return true
            })
            return {
              time: shedule_item.time,
              items: items
            }

          }).filter((item) => {
            if (item.items.length > 0) return true
            else return false
          }))

          setTakeResultPopupOpened(true)
        })
        .catch((err) => {
          if (err.detail) {
            setTakeError(err.detail)
          } else {
            setTakeError('Something went wrong')
          }
          setTakeResultPopupOpened(true)
          console.log(err)
        })
    }

    // let cardToTakeFound = false
    // let cards_with_animation = cards.map((item, i) => {
    //   console.log({ cardToTakeFound, i })
    //   if (!cardToTakeFound) {
    //     if (item.id === cardToTake.id) {
    //       cardToTakeFound = true
    //       return {
    //         ...item,
    //         taking: true,
    //       }
    //     } else {
    //       return item
    //     }
    //   } else {
    //     return {
    //       ...item,
    //       animate_up: true,
    //       pixels_up: cardHeightToTake,
    //     }
    //   }
    // })

    // console.log(cards_with_animation)
    // setCards(cards_with_animation)
    // setTimeout(() => {
    //   let pageHeight = Math.max(
    //     appRef.current.scrollHeight,
    //     appRef.current.offsetHeight,
    //     appRef.current.clientHeight
    //   );

    //   if (pageHeight - (window.scrollY + window.innerHeight) <= cardHeightToTake && window.scrollY - cardHeightToTake >= cardHeightToTake) {
    //     window.scrollBy({ top: -cardHeightToTake - 12, behavior: "smooth" });
    //   }
    // }, 450);

    // setTimeout(() => {
    //   let upd_cards = cards.filter((item) => {
    //     if (item.id === cardToTake.id) return false
    //     else return true
    //   })
    //   upd_cards = upd_cards.map((item) => {
    //     let upd_item = item
    //     if (upd_item.animate_up) {
    //       delete upd_item.animate_up
    //     }
    //     if (upd_item.pixels_up) {
    //       delete upd_item.pixels_up
    //     }
    //     return upd_item
    //   })

    //   console.log(upd_cards)
    //   setCards(upd_cards)
    //   // setTimeout(() => {
    //   //   if (currentUser) {
    //   //     let cardsByDate = JSON.parse(localStorage.getItem('cardsByDate'))
    //   //     if (cardsByDate) {
    //   //       cardsByDate.cardsForDate = upd_cards
    //   //       localStorage.setItem('cardsByDate', JSON.stringify(cardsByDate))
    //   //     }
    //   //   }
    //   // }, 1000);

    // }, 900);
  }

  function handleEdit({ card, type }) {
    if (type === 'take') {
      console.log(card)
      setCardToTake({
        ...card,
        time: moment(`${card.utc_date}+00:00`).format('HH:mm'),
        editToTake: true
      })
      setTakePopupOpened(true)
    } else {
      setCardToSkip({
        ...card,
        time: moment(`${card.utc_date}+00:00`).format('HH:mm'),
        editToSkip: true
      })
      setSkipPopupOpened(true)
    }
  }

  return (
    <div className="app" ref={appRef}>
      {isMainPreloaderVislible ?
        <div className="app__preloader">
          <Preloader />
        </div>
        :
        <>
          <CountryPopup setCountryValue={setCountryValue} countryPopupOpened={countryPopupOpened} setCountryPopupOpened={setCountryPopupOpened} countryValue={countryValue} />
          <SkipPopup cardToSkip={cardToSkip} handleSkip={handleSkip} isPopupOpen={skipPopupOpened} setPopupOpen={setSkipPopupOpened} setCardToSkip={setCardToSkip} />
          <TakePopup takePopupOpened={takePopupOpened} setTakePopupOpened={setTakePopupOpened} handleTake={handleTake} cardToTake={cardToTake} setCardToTake={setCardToTake} setCardHeightToTake={setCardHeightToTake} />
          <TakeResultPopup resultError={takeError} isPopupOpen={takeResultPopupOpened} setPopupOpen={setTakeResultPopupOpened} />
          <MedicineInfoPopup setPopupOpen={setMedInfoPopupOpened} isPopupOpen={medInfoPopupOpened} selectedItem={cardToEdit} setSelectedItem={setCardToEdit} handleEdit={handleEdit} />
          <Routes>
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/onboarding/:stepInLink" element={<Onboarding />} />
            <Route exact path="/" element={currentUser ? <Main setCardsMissed={setCardsMissed} cardsMissed={cardsMissed} sheduleMissed={sheduleMissed} setSheduleMissed={setSheduleMissed} setCards={setCards} cards={cards} shedule={shedule} setShedule={setShedule} currentUser={currentUser} handleTakePopupOpen={handleTakePopupOpen} handleSkipPopupOpen={handleSkipPopupOpen} /> : <Onboarding />} />
            {/* <Route path="/medicine" element={<AddMedicine {...{ medicine, setMedicine, setCards, sortCards }} />} /> */}

            <Route path="/sign-in" element={<Login getCurentUser={getCurentUser} currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
            <Route path="/sign-in/:emailPrewValue" element={<Login getCurentUser={getCurentUser} currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
            <Route path="/sign-up" element={<Register setCountryValue={setCountryValue} currentUser={currentUser} setCurrentUser={setCurrentUser} countryValue={countryValue} countryPopupOpened={countryPopupOpened} setCountryPopupOpened={setCountryPopupOpened} />} />
            <Route path="/sign-up/disorders" element={<Disorders getCurentUser={getCurentUser} {...{ medicine, setMedicine, setCurrentUser, currentUser }} />} />
            <Route path='/questionare' element={<Questionare />}></Route>
            <Route path='/daily-questionare' element={<DailyQuestionare setCurrentUser={setCurrentUser} />} />

            <Route path='/history' element={<History weeklyHistory={weeklyHistory} setWeeklyHistory={setWeeklyHistory} handleEditPopupOpend={handleEditPopupOpend} shedule={sheduleHistory} setShedule={setSheduleHistory} cards={cardsHistory} setCards={setCardsHistory} />}></Route>
            {/* <Route path='/profile' element={<CoomingSoon />}></Route> */}
            <Route path='/profile' element={<Profile currentUser={currentUser} />} />
            <Route path='/profile/personal-data' element={<PersonalData {...{ currentUser, countryPopupOpened, setCountryPopupOpened, countryValue, setCountryValue, setCurrentUser }} />} />
            <Route path='/profile/medication-data' element={<MedicationData getCurentUser={getCurentUser} {...{ medicine, setMedicine }} />} />
            <Route path='/change-email' element={<ChangeEmail currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
            <Route path='/change-password' element={<ChangePassword currentUser={currentUser} setCurrentUser={setCurrentUser} />} />
          </Routes>
          {currentUser && (location.pathname === '/' || location.pathname === '/history' || location.pathname === '/profile') ? <Navigation /> : <></>}
        </>
      }


      {<AddToHomeScreen
        skipFirstVisit={false}
        translate={{
          headline: 'To install the application',
          safariTapShare: 'Click "Share"',
          safariAddHomeScreen: 'Select "Add to Home Screen"',
          chromiumAddHomeScreen: 'Click "Add to Home Screen" in your browser menu',
          chromiumInstall: 'The app icon will be displayed on your screen',
          buttonInstall: 'Install'
        }}
        cookie={{
          name: 'add-to-home-screen-pwa',
          expireDays: 30
        }}
      />}
    </div>
  );
}

export default App;
