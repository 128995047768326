import { useState, useEffect, useRef } from "react";
import SelectInput from "../../SelectInput/SelectInput";
import Picker from "react-mobile-picker";
import moment from "moment-timezone";
import {
  SELECT_ARROW_ICON,
  REDUCE_ICON,
  INCREASE_ICON,
  MORE_BUTTON_ICON,
} from "../../../assets/utils/icons";
import {
  WEEK_LIST,
  FREQUENCY_LIST,
  INTAKE_TIME_OPTION_GROUPS,
} from "../../../assets/utils/disordersInfo";
import "./IntakeForm.css";
import mainApi from "../../../assets/api/mainApi";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";

function IntakeForm({
  drug,
  index,
  isFormValid,
  handleChange,
  handleSelectChange,
  setFormValid,
  setDrugFormValid,
  setIndex,
  setDrug,
  isEdit,
  prevDrug,
  isPreloaderVisible,
  setScrollPosition,
  listInnerRef,
  setPrevScrollPosition,
  setPageValue,
  setDrugChanged
}) {
  const overlay = useRef();
  const [intake, setIntake] = useState({});
  const [isDeleteVisible, setDeleteVisible] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState({
    frequency: false,
    intake: false,
  });
  const [timeValueGroups, setTimeValueGroups] = useState({
    hours: moment().format("HH"),
    min: moment().format("mm"),
  });

  useEffect(() => {
    if (isEdit && drug) {
      console.log(drug)
    }
  }, [isEdit, drug])

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        setPopupOpen({
          frequency: false,
          intake: false,
        });
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });

  useEffect(() => {
    const form = document.getElementById("form");
    setFormValid(form.checkValidity());
  }, [drug, setFormValid]);

  useEffect(() => {
    console.log(drug.frequency)

    switch (drug.frequency) {
      case "One time intake":

        setDrugFormValid(true);
        setFormValid(true)
        break;

      case "Specific days":

        setDrugFormValid(Boolean(drug.days.length > 0 && drug.intakeTime.length > 0));
        setFormValid(Boolean(drug.days.length > 0 && drug.intakeTime.length > 0))
        break;

      case "Every day":

        setDrugFormValid(Boolean(drug.intakeTime.length > 0));
        setFormValid(Boolean(drug.intakeTime.length > 0))
        break;
      default:
        setDrugFormValid(false);
        setFormValid(false)
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drug]);

  useEffect(() => {
    if (intake.exactTime) {
      setIntake({ ...intake, exactTime: timeValueGroups });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeValueGroups]);

  function openPopup(popup) {
    setPopupOpen({ ...isPopupOpen, [popup]: true });

  }


  function closePopup(popup) {
    setPopupOpen({ ...isPopupOpen, [popup]: false });

  }


  const [scrollWindowPosition, setScrollWindowPosition] = useState(0);
  const [popupScrollPosition, setPopupScrollPosition] = useState(0);

  const handleWindowScroll = () => {
    const position = window.pageYOffset;

    setScrollWindowPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, []);



  useEffect(() => {
    if (isPopupOpen.frequency || isPopupOpen.intake) {
      document.body.style.overflow = 'hidden';
      document.body.style.top = `-${scrollWindowPosition}px`;
      setPopupScrollPosition(scrollWindowPosition)
      setTimeout(() => {
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
        document.body.style.height = '100%';
      }, 10);

      console.log(window.pageYOffset)

    } else {
      document.body.style.overflow = 'unset';
      document.body.style.position = 'unset';
      document.body.style.width = 'unset';
      document.body.style.height = 'unset';
      document.body.style.top = `unset`;
      console.log(popupScrollPosition)
      window.scrollTo(0, popupScrollPosition)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopupOpen]);

  function handleTimeChange(name, value) {
    setTimeValueGroups((prewValueGroups) => ({
      ...prewValueGroups,
      [name]: value,
    }));
  }

  function handleAddButtonClick() {
    openPopup("intake");
    setDeleteVisible(false);
    setIndex(drug.intakeTime.length);
    setIntake({
      exactTime: timeValueGroups,
      dose: 1,
    });
    setTimeValueGroups({
      hours: moment().format("HH"),
      min: moment().format("mmm"),
    });
  }

  function handleIntakeClick(item) {

    setDeleteVisible(true);
    console.log({
      item,
      hours: item.exactTime.hours,
      min: item.exactTime.min,
    })
    // setIndex(i);
    setIntake(item);
    setTimeValueGroups({
      hours: item.exactTime.hours,
      min: item.exactTime.min,
    });
    openPopup("intake");
  }

  function handleDoseChange(type) {
    if (type === "more") {
      setIntake({ ...intake, dose: (intake.dose += 1) });
    } else if (type === "less") {
      setIntake({ ...intake, dose: (intake.dose -= 1) });
    }
  }

  function handleDoneClick() {
    if (isEdit && prevDrug.frequency === drug.frequency) {
      if (listInnerRef && listInnerRef.current) {
        listInnerRef.current.scrollTo(0, 0)
      }

      setPrevScrollPosition(0)
      setPageValue(0)
      console.log(intake)
      setDrugChanged(true)
      if (intake._id) {
        mainApi.editIntakeInDrug({
          id: intake._id,
          medicine_id: drug._id,
          intake_time: {
            intake_time: {
              hours: Number(intake.exactTime.hours),
              minutes: Number(intake.exactTime.min),
            },
            dose_amount: intake.dose
          }
        })
          .then(() => {
            mainApi.getDrugIntakes({ medicine_id: drug._id, limit: 15 })
              .then((res) => {
                setDrug(prevItem => ({
                  ...prevItem,
                  total_intakes: res.medicine_intakes.total_intakes,
                  intakeTime: res.medicine_intakes.map((item) => {
                    let time = moment(`${item.intake_time}`)
                    return {
                      _id: item._id,
                      dose: item.dose_amount,
                      exactTime: {
                        hours: time.format('HH'),
                        min: time.format('mm'),
                      }
                    }
                  })
                }))
              })
              .catch((err) => {
                console.log(err)
              })
          })
          .catch((err) => {
            console.log(err)
          })
      } else {

        mainApi.addIntakeToDrug({
          medicine_id: drug._id,
          intake_time: {
            intake_time: {
              hours: Number(intake.exactTime.hours),
              minutes: Number(intake.exactTime.min),
            },
            dose_amount: intake.dose
          }
        })
          .then(() => {
            mainApi.getDrugIntakes({ medicine_id: drug._id, limit: 15 })
              .then((res) => {
                setDrug(prevItem => ({
                  ...prevItem,
                  total_intakes: res.medicine_intakes.total_intakes,
                  intakeTime: res.medicine_intakes.map((item) => {
                    let time = moment(`${item.intake_time}`)
                    return {
                      _id: item._id,
                      dose: item.dose_amount,
                      exactTime: {
                        hours: time.format('HH'),
                        min: time.format('mm'),
                      }
                    }
                  })
                }))
              })
              .catch((err) => {
                console.log(err)
              })
          })
          .catch((err) => {
            console.log(err)
          })
      }

    } else {
      const newIntake = drug.intakeTime.slice();
      newIntake[index] = intake;
      setDrug({ ...drug, intakeTime: newIntake });
    }

    closePopup("intake");
  }

  function deleteIntake() {
    if (drug._id && intake._id && drug.frequency === prevDrug.frequency) {
      setDrugChanged(true)
      mainApi.deleteIntakeInDrug({
        medicine_id: drug._id,
        id: intake._id
      })
        .then((res) => {
          console.log(res)
          if (listInnerRef && listInnerRef.current) {
            listInnerRef.current.scrollTo(0, 0)
          }

          setPrevScrollPosition(0)
          setPageValue(0)

          // setDrug(prevItem => ({ ...prevItem, intakeTime: prevItem.intakeTime.filter((item)=>{
          //   if(item._id === intake._id) return false
          //   else return true
          // }) }))
          mainApi.getDrugIntakes({ medicine_id: drug._id, limit: 15 })
            .then((res) => {
              setDrug(prevItem => ({
                ...prevItem,
                total_intakes: res.medicine_intakes.total_intakes,
                intakeTime: res.medicine_intakes.map((item) => {
                  let time = moment(`${item.intake_time}`)
                  return {
                    _id: item._id,
                    dose: item.dose_amount,
                    exactTime: {
                      hours: time.format('HH'),
                      min: time.format('mm'),
                    }
                  }
                })
              }))
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch((err) => {
          console.log(err)
        })
    }
    const newIntake = drug.intakeTime.slice();
    const newArr = newIntake.filter((item, i) => i !== index);
    setDrug({ ...drug, intakeTime: newArr });
    closePopup("intake");
  }

  function handleSelectOnChange(name, value) {


    handleSelectChange(name, value)
  }

  useEffect(() => {
    console.log(timeValueGroups)
  }, [timeValueGroups])


  // .sort((a, b) => {
  //   let hours_a = a.exactTime.hours
  //   let hours_b = b.exactTime.hours
  //   let minutes_a = a.exactTime.min
  //   let minutes_b = b.exactTime.min
  //   let time_a = Number(moment(`${hours_a}:${minutes_a}`, 'HH:mm').format('x'))
  //   let time_b = Number(moment(`${hours_b}:${minutes_b}`, 'HH:mm').format('x'))
  //   if (time_a < time_b) return -1
  //   else if (time_a > time_b) return 1
  //   else return 0
  // })

  function handleScroll(e) {
    // console.log(e.target.scrollTop)
    if (isEdit) {

      setScrollPosition(e.target.scrollTop);
    }

  }



  return (
    <div className="intake-form">
      <p className="intake-form__title">Intake schedule</p>
      <div className="intake-form__form-container">
        <SelectInput
          label="Frequency"
          value={drug.frequency}
          name="frequency"
          isRequired={true}
          selectList={FREQUENCY_LIST}
          popupTitle="Select frequency"
          onSelect={handleSelectOnChange}
        />

        {drug.frequency === "Specific days" ? (
          <ul className="intake-form__days-list">
            {WEEK_LIST.map((day) => (
              <li
                className={`intake-form__day-card ${drug && drug.days && drug.days.length > 0 && drug.days.includes(day)
                  ? "intake-form__day-card_selected"
                  : ""
                  }`}
                key={day}
                onClick={() => handleSelectChange("days", day)}
              >
                <p className="intake-form__day-text">{day}</p>
              </li>
            ))}
          </ul>
        ) : null}

        {Boolean(drug.frequency) && drug.frequency !== "One time intake" ? (
          <div className="intake-form__time-block">
            {/* <Input
              label="How many times a day?"
              boxType="intakes-per-day"
              value={drug.intakesPerDay}
              name="intakesPerDay"
              type="text"
              inputMode="numeric"
              handleChange={handleChange}
              isRequired={
                Boolean(drug.frequency) && drug.frequency !== "One time intake"
              }
            /> */}

            <div className="intake-form__intakes-box">
              <p className="intake-form__intakes-title">Set time and dose</p>
              {isPreloaderVisible !== undefined ?
                isPreloaderVisible ?
                  <div className="intake-form__intakes-list-preloader">
                    <MiniPreloader />
                  </div>
                  :
                  <>
                    <ul className="intake-form__intakes-list" ref={listInnerRef} id="listInner" onScroll={handleScroll}>
                      {drug.intakeTime.length > 0
                        ? drug.intakeTime.map((item, i) =>
                          Boolean(item.dose) ? (
                            <li
                              className="intake-form__intake-item"
                              key={`intake${i}`}
                              onClick={() => handleIntakeClick(item)}
                            >
                              <div className="intake-form__intake-text-block">
                                <p className="intake-form__intake-time">
                                  {item.exactTime.hours}:{item.exactTime.min}
                                </p>
                                <p className="intake-form__intake-text">
                                  Take {item.dose}{" "}
                                  {item.dose > 1 ? "doses" : "dose"}
                                </p>
                              </div>
                              <div className="intake-form__intake-icon">
                                {SELECT_ARROW_ICON}
                              </div>
                            </li>
                          ) : null
                        )
                        : null}
                    </ul>
                    <button
                      className="intake-form__add-intake-button"
                      type="button"
                      onClick={handleAddButtonClick}
                    >
                      <div className="intake-form__add-intake-icon">
                        {MORE_BUTTON_ICON}
                      </div>
                      <p className="intake-form__add-intake-text">
                        Add{" "}
                        {drug.intakeTime !== undefined && drug.intakeTime.length === 0
                          ? "an"
                          : "another"}{" "}
                        intake
                      </p>
                    </button>
                  </>
                :
                <>
                  <ul className="intake-form__intakes-list">
                    {drug.intakeTime.length > 0
                      ? drug.intakeTime.map((item, i) =>
                        Boolean(item.dose) ? (
                          <li
                            className="intake-form__intake-item"
                            key={`intake${i}`}
                            onClick={() => handleIntakeClick(item)}
                          >
                            <div className="intake-form__intake-text-block">
                              <p className="intake-form__intake-time">
                                {item.exactTime.hours}:{item.exactTime.min}
                              </p>
                              <p className="intake-form__intake-text">
                                Take {item.dose}{" "}
                                {item.dose > 1 ? "doses" : "dose"}
                              </p>
                            </div>
                            <div className="intake-form__intake-icon">
                              {SELECT_ARROW_ICON}
                            </div>
                          </li>
                        ) : null
                      )
                      : null}
                  </ul>
                  <button
                    className="intake-form__add-intake-button"
                    type="button"
                    onClick={handleAddButtonClick}
                  >
                    <div className="intake-form__add-intake-icon">
                      {MORE_BUTTON_ICON}
                    </div>
                    <p className="intake-form__add-intake-text">
                      Add{" "}
                      {drug.intakeTime !== undefined && drug.intakeTime.length === 0
                        ? "an"
                        : "another"}{" "}
                      intake
                    </p>
                  </button>
                </>
              }

            </div>
          </div>
        ) : null}
      </div>

      <div
        className={`intake-form__popup ${isPopupOpen.intake ? "intake-form__popup_opened" : ""
          }`}
      >
        <div className="intake-form__popup-container">
          <div className="intake-form__buttons-box">
            <button
              className="intake-form__popup-button"
              type="button"
              onClick={() => closePopup("intake")}
            >
              Cancel
            </button>
            <button
              className={`intake-form__popup-button ${intake.dose === 0 ? "intake-form__popup-button_disabled" : ""
                }`}
              type="button"
              onClick={handleDoneClick}
            >
              Save
            </button>
          </div>
          <p className="intake-form__popup-title">Set intake time</p>
          <div className="intake-form__time-input">
            <div className="intake-form__time-input-line" />
            <p className="intake-form__time-separator">:</p>
            <div className="intake-form__time-input-container">
              <Picker
                optionGroups={INTAKE_TIME_OPTION_GROUPS}
                valueGroups={timeValueGroups}
                onChange={handleTimeChange}
              />
            </div>
          </div>
          <div className="intake-form__dose-block">
            <p className="intake-form__dose-title">Dose</p>
            <div className="intake-form__dose-box">
              <button
                className={`intake-form__dose-button ${intake.dose === 0 ? "intake-form__dose-button_disabled" : ""
                  }`}
                type="button"
                aria-label="Reduce dose"
                onClick={() =>
                  Boolean(intake.dose) ? handleDoseChange("less") : undefined
                }
              >
                {REDUCE_ICON}
              </button>
              <input
                className={`intake-form__input intake-form__input_type_dose ${Boolean(intake.dose) ? "intake-form__input_valid" : ""
                  }`}
                id="dose"
                name="dose"
                type="text"
                value={intake.dose}
                readOnly
                required
              />
              <button
                className="intake-form__dose-button intake-form__dose-button_type_increase"
                type="button"
                aria-label="Increase dose"
                onClick={() => handleDoseChange("more")}
              >
                {INCREASE_ICON}
              </button>
            </div>
          </div>
          {isDeleteVisible ? (
            <button
              className="intake-form__delete-button"
              type="button"
              onClick={deleteIntake}
            >
              Delete Intake
            </button>
          ) : null}
        </div>
      </div>
      <div
        className="intake-form__overlay"
        ref={overlay}
        onTouchStart={() => closePopup("intake")}
      />
    </div>
  );
}

export default IntakeForm;
