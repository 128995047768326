import { useState, useEffect, useRef } from "react";
import {
  ACTIONS_MENU_ICON,
  EDIT_BUTTON_ICON,
  DELETE_BUTTON_ICON,
} from "../../../assets/utils/icons";
import "./SeizureCard.css";

function SeizureCard({ seizure, index, onDelete, onEdit }) {
  const overlay = useRef();
  const [isActionsPopupOpen, setActionsPopupOpen] = useState(false);

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        setActionsPopupOpen(false);
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });

  function openActionsPopup() {
    setActionsPopupOpen(true);
  }

  function closeActionsPopup() {
    setActionsPopupOpen(false);
  }

  function handleDeleteClick() {
    closeActionsPopup();
    onDelete(index);
  }

  function handleMedicineEditClick() {
    closeActionsPopup();
    onEdit(index);
  }

  return (
    <li className="daily-seizure-card">
      <p className="daily-seizure-card__text">
        <span className="daily-seizure-card__text_type_bold">Seizure</span>{" "}
        episode at {seizure.exactTime.hours}:{seizure.exactTime.min}, duration{" "}
        {seizure.duration.min} min {seizure.duration.sec} sec
      </p>

      <div className="daily-seizure-card__box-with-drop">
        <button
          className="daily-seizure-card__open-actions-button"
          type="button"
          aria-label="Open actions menu"
          onClick={openActionsPopup}
        >
          {ACTIONS_MENU_ICON}
        </button>
        <ul
          className={`daily-seizure-card__actions-menu ${
            isActionsPopupOpen ? "daily-seizure-card__actions-menu_open" : ""
          }`}
        >
          <li className="daily-seizure-card__action-item">
            <button
              className="daily-seizure-card__action-button"
              type="button"
              onClick={handleMedicineEditClick}
            >
              <p className="daily-seizure-card__action-text">Edit episode</p>
              <div className="daily-seizure-card__action-icon">
                {EDIT_BUTTON_ICON}
              </div>
            </button>
          </li>

          <li className="daily-seizure-card__action-item">
            <button
              className="daily-seizure-card__action-button"
              type="button"
              onClick={handleDeleteClick}
            >
              <p className="daily-seizure-card__action-text">Delete</p>
              <div className="daily-seizure-card__action-icon">
                {DELETE_BUTTON_ICON}
              </div>
            </button>
          </li>
        </ul>
        <div
          className="daily-seizure-card__overlay"
          ref={overlay}
          onTouchStart={closeActionsPopup}
        />
      </div>
    </li>
  );
}

export default SeizureCard;
