import { useEffect } from "react";
import RangeSelector from "../../../RangeSelector/RangeSelector";
import "./SocialPerformance.css";

function SocialPerformance({
  agression,
  setAgression,
  friendship,
  setFriendship,
  isolation,
  setIsolation,
  family,
  setFamily,
  setDisabled,
}) {
  useEffect(() => {
    setDisabled(false);
  }, [setDisabled]);

  return (
    <div className="social">
      <p className="social__title">Social performance</p>
      <div className="social__form">
        <RangeSelector
          stepsCount={4}
          title={"Agression"}
          lastValues={{ min: "never", max: "constant" }}
          defaultValue={agression}
          setFinalValue={setAgression}
        />
        <RangeSelector
          stepsCount={4}
          title={"Friendship"}
          lastValues={{ min: "none", max: "many" }}
          defaultValue={friendship}
          setFinalValue={setFriendship}
        />
        <RangeSelector
          stepsCount={4}
          title={"Isolation"}
          lastValues={{ min: "isolated", max: "popular" }}
          defaultValue={isolation}
          setFinalValue={setIsolation}
        />
        <RangeSelector
          stepsCount={4}
          title={"Family relations"}
          lastValues={{ min: "conflict", max: "warm" }}
          defaultValue={family}
          setFinalValue={setFamily}
        />
      </div>
    </div>
  );
}

export default SocialPerformance;
