import { CHECKED_REG_CHECKBOX_ICON } from "../../../assets/utils/icons";
import "./RegCheckbox.css";

function RegCheckbox({
  name,
  isRequired,
  isChecked,
  onClick,
  label,
  type = "",
}) {
  return (
    <div className="reg-checkbox">
      <label className="reg-checkbox__label" htmlFor={name}>
        <input
          className="reg-checkbox__invisible-input"
          id={name}
          name={name}
          type="checkbox"
          value={isChecked}
          onChange={(evt) => onClick(evt)}
          required={isRequired}
          checked={isChecked}
        />
        <span
          className={`reg-checkbox__pseudo-item ${
            isChecked ? "reg-checkbox__pseudo-item_checked" : ""
          } ${type ? `reg-checkbox__pseudo-item_type_${type}` : ""}`}
        >
          {CHECKED_REG_CHECKBOX_ICON}
        </span>
        <span
          className={`reg-checkbox__label-text ${
            type ? `reg-checkbox__label-text_type_${type}` : ""
          }`}
        >
          {label}
        </span>
      </label>
    </div>
  );
}

export default RegCheckbox;
