
import React, { useEffect, useRef } from 'react';

// import moment from 'moment';
// import styled, { keyframes, css } from "styled-components";

import "./ScheduleCard.css";

import pillIcon from '../../../assets/images/main/med_type/pill.png'
import injectionIcon from '../../../assets/images/main/med_type/injection.png'
import syrupIcon from '../../../assets/images/main/med_type/syrup.png'
import sprayIcon from '../../../assets/images/main/med_type/spray.png'
import tabletIcon from '../../../assets/images/main/med_type/tablet.png'
import dropsIcon from '../../../assets/images/main/med_type/drops.png'
import ointmentIcon from '../../../assets/images/main/med_type/ointment.png'
import procedureIcon from '../../../assets/images/main/med_type/procedure.png'
import { fromatDrugFromApi, parseFoodTime } from '../../../assets/utils/utilis';









console.warn = () => { };

function ScheduleCard({ card, handleSkipCard, cardKey, handleTakeCard, type }) {

    useEffect(() => {
        if (card.pixels_up) {
            console.log(card.pixels_up)
        }
    }, [card])

    // const anim_up = keyframes`
    //         50% {
    //             transform: translateY(0);
    //         }

    //         100% {
    //             transform: translateY(-${card.pixels_up + 12}px);
    //         }
    //     `



    // const Card =
    //     styled.div`
    //     animation:${card.animate_up ? css`
    //     ${anim_up} 0.90s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s 1 normal both running;
    //     ` : ''} 

    // `

    const cardRef = useRef(null)






    function handleSkip() {
        handleSkipCard({ card: card, height: cardRef.current.clientHeight })
    }

    function handleTake() {
        handleTakeCard({ card: card, height: cardRef.current.clientHeight })
    }


    // useEffect(() => {
    //     if (cardRef && cardRef.current) {
    //         setHeight(cardRef.current.clientHeight)
    //     }
    // }, [cardRef, cardKey])


    // const handlers = useSwipeable({
    //     onSwiping: (e) => setDeltaX(e.deltaX),
    //     onSwipedLeft: (e) => { handleSwipeLeft(e) },
    //     onSwipedRight: () => { return },
    //     onTouchEndOrOnMouseUp: () => setDeltaX(0),
    //     // swipeDuration: 500,
    //     preventScrollOnSwipe: true,
    //     trackMouse: true,
    // });




    return (
        <div ref={cardRef} className={`schedule-card ${card.skipping ? 'schedule-card_skipping' : ''} ${card.taking ? 'schedule-card_taking' : ''} ${type === 'medicine-missed' ? 'schedule-card_missed' : ''} `}
            pixelsUp={card.animate_up ? card.pixels_up : 0}
        >
            <div className="schedule-card__heading">
                <div className="schedule-card__main-info">
                    <div className="schedule-card__icon-container">
                        {card.medicine_object.type === 'pill' && <img className="schedule-card__icon" src={pillIcon} alt='' />}
                        {card.medicine_object.type === 'injection' && <img className="schedule-card__icon" src={injectionIcon} alt='' />}
                        {card.medicine_object.type === 'syrup' && <img className="schedule-card__icon" src={syrupIcon} alt='' />}
                        {card.medicine_object.type === 'spray' && <img className="schedule-card__icon" src={sprayIcon} alt='' />}
                        {card.medicine_object.type === 'tablet' && <img className="schedule-card__icon" src={tabletIcon} alt='' />}
                        {card.medicine_object.type === 'drops' && <img className="schedule-card__icon" src={dropsIcon} alt='' />}
                        {card.medicine_object.type === 'ointment' && <img className="schedule-card__icon" src={ointmentIcon} alt='' />}
                        {card.medicine_object.type === 'procedure' && <img className="schedule-card__icon" src={procedureIcon} alt='' />}
                    </div>

                    <div className="schedule-card__name-and-dosage">
                        <p className="schedule-card__name">{card.medicine_object.name}</p>
                        <p className="schedule-card__dosage">
                            {
                                `${card.medicine_object.dosage} ${card.medicine_object.dosage_type}, ${card.frequency === 'one_time_intake' ? `take 1` : `take ${card.dose_amount}`} ${parseFoodTime(card.medicine_object.food_time)}`
                            }
                        </p>
                    </div>
                </div>

            </div>
            <div className='schedule-card__btns'>
                <div className='schedule-card__btn' onClick={() => handleSkip()}>
                    <svg className='schedule-card__btn-icon' width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.41748 6.41748L18.7498 18.7498" stroke="#FF7474" strokeWidth="2" strokeLinecap="round" />
                        <path d="M18.7498 6.41748L6.41753 18.7498" stroke="#FF7474" strokeWidth="2" strokeLinecap="round" />
                    </svg>
                    <p className='schedule-card__btn-text schedule-card__btn-text_type_skip'>{type === 'medicine-for-now' ? 'Skip' : 'Skipped'}</p>
                </div>
                <div className='schedule-card__btn schedule-card__btn_type_taken' onClick={() => handleTake()}>
                    <svg className='schedule-card__btn-icon' width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.3734 6.00012C20.0584 6.00848 19.7589 6.13738 19.5382 6.35958C19.4884 6.40873 18.7706 7.09423 17.8297 7.97752C16.8889 8.86082 15.6733 9.99989 14.4584 11.1385C13.2435 12.2771 12.0293 13.415 11.0871 14.2995C10.5857 14.7701 10.4556 14.8908 10.1532 15.1759L5.64287 11.3335L5.6427 11.3333C5.39813 11.1242 5.07933 11.0197 4.75642 11.0428C4.43372 11.0657 4.13339 11.2143 3.92187 11.4559C3.71018 11.6975 3.60458 12.0121 3.62831 12.3305C3.65204 12.649 3.80306 12.9449 4.04814 13.1534L9.39374 17.7075H9.39391C9.62628 17.906 9.92629 18.0101 10.2334 17.9989C10.5407 17.9879 10.8321 17.8623 11.0492 17.6477C11.099 17.5985 11.8168 16.913 12.7576 16.0297C13.6985 15.1464 14.9141 14.0149 16.129 12.8763C17.3439 11.7377 18.5581 10.5924 19.5003 9.70798C20.4425 8.82353 21.0613 8.25055 21.2544 8.06012C21.4908 7.83475 21.6245 7.52425 21.625 7.1999C21.6254 6.87555 21.4925 6.5649 21.2567 6.33869C21.021 6.11265 20.7023 5.99059 20.3735 6.00029L20.3734 6.00012Z" fill="white" />
                    </svg>

                    <p className='schedule-card__btn-text schedule-card__btn-text_type_taken'>{type === 'medicine-for-now' ? 'Take' : 'Taken'}</p>
                </div>
            </div>

        </div>
    );
}

export default ScheduleCard;
