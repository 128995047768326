import React, { useState } from 'react';
import validator from 'validator'

import './EmailAndPassword.css';
import '../Register.css'
import InputHeading from '../InputsTitle/InputHeading';
import mainApi from '../../../assets/api/mainApi';


function EmailAndPassword({ emailValue, setEmailValue, emailValidity, setEmailValidity, passValue, setPassValue, passValidity, setPassValidity, isLogin }) {

    const [isFocuced, setIsFocuced] = useState({});
    const [isPassVisible, setPassVisible] = useState(false)

    function handleFocused(name) {

        setIsFocuced({ ...isFocuced, [name]: true });
    }

    function handleUnfocused(name) {

        setIsFocuced({ ...isFocuced, [name]: false });
    }

    function handleEmailChange(e) {
        let inputValue = e.target.value.replace(/\s/g, '')
        setEmailValue(inputValue)
        if (!inputValue) {
            setEmailValidity({
                errorMassage: (''),
                validState: false
            })
        } else {
            if (inputValue.length > 5) {
                if (validator.isEmail(inputValue)) {
                    if (isLogin) {
                        setEmailValidity({
                            errorMassage: '',
                            validState: true
                        })
                    } else {
                        mainApi.checkEmailValid({ email: inputValue })
                            .then((res) => {
                                setEmailValidity({
                                    errorMassage: '',
                                    validState: true
                                })
                            })
                            .catch((err) => {
                                switch (err.detail) {
                                    case 'Email or phone are already registered':
                                        setEmailValidity({
                                            errorMassage: 'This email is already registered',
                                            validState: false
                                        })
                                        break;

                                    default:
                                        setEmailValidity({
                                            errorMassage: err.detail,
                                            validState: false
                                        })
                                        break;
                                }

                            })
                    }

                } else {
                    setEmailValidity({
                        errorMassage: ('Enter a valid email'),
                        validState: false
                    })
                }
            }
            else {
                setEmailValidity({
                    errorMassage: (''),
                    validState: false
                })
            }

        }
    }

    function handlePassChange(e) {
        let inputValue = e.target.value.replace(/\s/g, '')
        setPassValue(inputValue)
        if (!inputValue) {
            setPassValidity({
                errorMassage: (''),
                validState: false
            })
        } else {
            if (inputValue.length >= 8) {
                setPassValidity({
                    errorMassage: (''),
                    validState: true
                })
            }
            else {
                setPassValidity({
                    errorMassage: ('Minimum 8 characters'),
                    validState: false
                })
            }

        }
    }

    return (
        <div className="email-and-password">
            <InputHeading title={isLogin ? 'Log in' : 'Email and password'} subtitle={isLogin ? '' : 'In order to save your data, we require your email and password'} />
            <div className={`email-and-password__inputs`}>
                <div
                    className={`email-and-password__input-box ${emailValue || isFocuced.email ? "email-and-password__input-box_focused" : ""
                        }`}

                >
                    <label className="email-and-password__input-label" htmlFor="question">
                        Email
                    </label>
                    <input
                        onClick={() => handleFocused('email')}
                        onFocus={() => handleFocused('email')}
                        onBlur={() => handleUnfocused('email')}
                        name="email"
                        type='text'
                        className={`email-and-password__input ${emailValidity.validState
                            ? "email-and-password__input_valid"
                            : ""
                            }
                            ${emailValidity.errorMassage ? 'email-and-password__input_error' : ''}
                            `}
                        value={emailValue}
                        onChange={handleEmailChange}
                    />

                </div>
                <p className="email-and-password__input-error">{emailValidity.errorMassage}</p>
                <div
                    className={`email-and-password__input-box ${passValue || isFocuced.password ? "email-and-password__input-box_focused" : ""
                        }`}

                >
                    <label className="email-and-password__input-label" htmlFor="question">
                        Password
                    </label>
                    {
                        isPassVisible ?
                            <svg onClick={() => setPassVisible(!isPassVisible)} className="email-and-password__show-pass" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 6C15.79 6 19.17 8.13 20.82 11.5C19.17 14.87 15.79 17 12 17C8.21 17 4.83 14.87 3.18 11.5C4.83 8.13 8.21 6 12 6ZM12 4C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19C17 19 21.27 15.89 23 11.5C21.27 7.11 17 4 12 4ZM12 9C13.38 9 14.5 10.12 14.5 11.5C14.5 12.88 13.38 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 10.12 10.62 9 12 9ZM12 7C9.52 7 7.5 9.02 7.5 11.5C7.5 13.98 9.52 16 12 16C14.48 16 16.5 13.98 16.5 11.5C16.5 9.02 14.48 7 12 7Z" fill="black" />
                            </svg>
                            :
                            <svg onClick={() => setPassVisible(!isPassVisible)} className="email-and-password__show-pass" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 6.00001C15.79 6.00001 19.17 8.13001 20.82 11.5C20.23 12.72 19.4 13.77 18.41 14.62L19.82 16.03C21.21 14.8 22.31 13.26 23 11.5C21.27 7.11001 17 4.00001 12 4.00001C10.73 4.00001 9.51 4.20001 8.36 4.57001L10.01 6.22001C10.66 6.09001 11.32 6.00001 12 6.00001ZM10.93 7.14001L13 9.21001C13.57 9.46001 14.03 9.92001 14.28 10.49L16.35 12.56C16.43 12.22 16.49 11.86 16.49 11.49C16.5 9.01001 14.48 7.00001 12 7.00001C11.63 7.00001 11.28 7.05001 10.93 7.14001ZM2.01 3.87001L4.69 6.55001C3.06 7.83001 1.77 9.53001 1 11.5C2.73 15.89 7 19 12 19C13.52 19 14.98 18.71 16.32 18.18L19.74 21.6L21.15 20.19L3.42 2.45001L2.01 3.87001ZM9.51 11.37L12.12 13.98C12.08 13.99 12.04 14 12 14C10.62 14 9.5 12.88 9.5 11.5C9.5 11.45 9.51 11.42 9.51 11.37ZM6.11 7.97001L7.86 9.72001C7.63 10.27 7.5 10.87 7.5 11.5C7.5 13.98 9.52 16 12 16C12.63 16 13.23 15.87 13.77 15.64L14.75 16.62C13.87 16.86 12.95 17 12 17C8.21 17 4.83 14.87 3.18 11.5C3.88 10.07 4.9 8.89001 6.11 7.97001Z" fill="black" />
                            </svg>


                    }
                    <input
                        onClick={() => handleFocused('password')}
                        onFocus={() => handleFocused('password')}
                        onBlur={() => handleUnfocused('password')}
                        name="password"
                        type={isPassVisible ? 'text' : 'password'}
                        className={`email-and-password__input ${passValidity.validState
                            ? "email-and-password__input_valid"
                            : ""
                            }
                            ${passValidity.errorMassage ? 'email-and-password__input_error' : ''}
                            `}
                        value={passValue}
                        onChange={handlePassChange}
                    />
                </div>
                <p className="email-and-password__input-error">{passValidity.errorMassage}</p>
            </div>

        </div>
    );
}

export default EmailAndPassword;
