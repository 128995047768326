import moment from 'moment';
import * as React from 'react';
import { useEffect } from 'react';
import { Range } from 'react-range-scroll';
import { useSwipeable } from 'react-swipeable';

import "./RangeSelector.css";

const STEP = 1;
const MIN = 1;




const RangeSelector = ({ stepsCount, title, lastValues, classNameToMain, defaultValue, setFinalValue }) => {
    const [values, setValues] = React.useState([defaultValue]);
    const [finalValues, setFinalValues] = React.useState([defaultValue]);

    useEffect(() => {
        let lastValue = Number(finalValues)
        setFinalValue(lastValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalValues])


    const handlers = useSwipeable({
        preventScrollOnSwipe: true,
        trackMouse: true,
    });


    return (
        <div className={`renge-selector ${classNameToMain ? classNameToMain : ''}`} {...handlers}>
            {title && <h3 className='renge-selector__title'>{title}</h3>}
            {lastValues && lastValues.min && lastValues.max &&
                < div className='renge-selector__last-values'>
                    <p className='renge-selector__last-values-text'>{lastValues.min}</p>
                    <p className='renge-selector__last-values-text'>{lastValues.max}</p>
                </div>}
            <div className='renge-selector__range'>
                <Range

                    values={values}
                    step={STEP}
                    min={MIN}
                    max={stepsCount}
                    rtl={false}
                    onChange={(values) => setValues(values)}
                    onFinalChange={(values) => setFinalValues(values)}
                    renderMark={({ props, index }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '4px',
                                width: '4px',
                                borderRadius: '50%',
                                backgroundColor: '#04B994',
                            }}

                        />
                    )}
                    renderTrack={({ props, children }) => (
                        <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                                ...props.style,
                                height: '36px',
                                display: 'flex',
                                width: '100%',
                            }}
                        >
                            <div className='renge-selector__marks' >
                                {/* stepsCount / STEP / 100 */}
                                {Array.apply(null, Array(stepsCount / STEP)).map((item, i) => (
                                    <div className='renge-selector__mark' key={`renge-selector__mark${i}-${moment().format('x')}`}></div>
                                ))}


                            </div>
                            <div
                                ref={props.ref}
                                style={{
                                    height: '4px',
                                    width: '100%',
                                    borderRadius: '14px',
                                    background: '#FBFDFA',
                                    alignSelf: 'center'
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                        <div
                            {...props}
                            className={`renge-selector__thumb ${isDragged ? 'renge-selector__thumb_active' : ''}`}
                            style={{
                                ...props.style,


                                boxShadow: '0px 2px 6px #AAA'
                            }}
                        >

                        </div>
                    )}
                />
            </div>


        </div >
    );
};

export default RangeSelector;