import tablet from "../images/disorders/tablet.png";
import pill from "../images/disorders/pill.png";
import spray from "../images/disorders/spray.png";
import injection from "../images/disorders/injection.png";
import procedure from "../images/disorders/procedure.png";
import drops from "../images/disorders/drops.png";
import ointment from "../images/disorders/ointment.png";
import syrup from "../images/disorders/syrup.png";

import {
  BEFORE_FOOD_ICON,
  WITH_FOOD_ICON,
  AFTER_FOOD_ICON,
  WHENEVER_ICON,
  SEX_MALE_ICON,
  SEX_FEMALE_ICON,
  SEX_OTHER_ICON,
} from "../utils/icons";

export const DISORDERS_LIST = [
  "Epilepsy",
  //"Alzheimer's Disease",
  //"Anxiety",
  //"IBD",
  //"Cancer",
  //"Fibromyalgia",
  //"Migraine",
  //"Multiple Sclerosis",
  //"Parkinson’s Disease",
  //"PTSD",
  //"Autism",
];

export const SYMPTOMS_LIST = ["Seizures"];

export const FAMILY_MEMBERS_LIST = ["Father", "Mother", "Grandparents"];

export const SEIZURE_TYPES_LIST = [
  "Focal aware clonic",
  "Generalized tonic",
  "Focal aware tonic",
  "Absense",
  "Focal aware sensory",
  "Myoclonic",
  "Focal Impared Awareness",
  "Hyperkinetic",
  "Focal to bilateral to tonik clonic",
  "Epileptic spasms",
  "Generalized tonic clonic",
  "Drop tonic",
  "Generalized clonic",
  "Drop atonic",
];

export const SEIZURE_TIME_PERIOD_LIST = [
  "A year or more",
  "Half a year",
  "Two or three months",
  "Month",
  "Two to three weeks",
  "Week or two",
  "Week",
  "Day",
];

//export const SEIZURE_RELATIVES_LIST = ["Mother", "Father", "Siblings"];

/*export const OTHER_MEDICINE_LIST = [
  "Vagal stimulation",
  "Ketogenic diet",
  "Surgery",
];*/

export const MEDICINE_TYPE_LIST = [
  {
    image: tablet,
    type: "tablet",
  },
  {
    image: pill,
    type: "pill",
  },
  {
    image: spray,
    type: "spray",
  },
  {
    image: injection,
    type: "injection",
  },
  {
    image: procedure,
    type: "procedure",
  },
  {
    image: drops,
    type: "drops",
  },
  {
    image: ointment,
    type: "ointment",
  },
  {
    image: syrup,
    type: "syrup",
  },
];

export const MEDICINE_MEASURE_LIST = [
  "g",
  "mg/cm2",
  "mg/kg",
  "mg",
  "mg/g",
  "mg/ml",
  "mcg/ml",
  "ml",
];

export const MEDICINE_FOOD_INTAKE_LIST = [
  { image: BEFORE_FOOD_ICON, text: "before food" },
  { image: WITH_FOOD_ICON, text: "with food" },
  { image: AFTER_FOOD_ICON, text: "after food" },
  { image: WHENEVER_ICON, text: "whenever" },
];

export const WEEK_LIST = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const FREQUENCY_LIST = ["One time intake", "Every day", "Specific days"];

export const SEX_LIST = [
  { gender: "Male", icon: SEX_MALE_ICON },
  { gender: "Female", icon: SEX_FEMALE_ICON },
  { gender: "Other", icon: SEX_OTHER_ICON },
];

export const INTAKE_TIME_OPTION_GROUPS = {
  hours: Array(24)
    .fill("")
    .map((el, i) => (el = i.toString().length < 2 ? "0" + i : i.toString())),
  min: Array(60)
    .fill("")
    .map((el, i) => (el = i.toString().length < 2 ? "0" + i : i.toString())),
};

export const DURATION_OPTION_GROUPS = {
  min: Array(300)
    .fill("")
    .map((el, i) => (el = i.toString().length < 2 ? "0" + i : i)),
  sec: Array(60)
    .fill("")
    .map((el, i) => (el = i.toString().length < 2 ? "0" + i : i)),
};

export const CLINIC_LIST = ["MCC"];

export const DOCTORS_LIST = ["Galaxy Wong"];
