import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';

import { formatMedicineToShedule, mainGreeting } from '../../assets/utils/utilis';
import noCardsIcon from '../../assets/images/main/no-cards.png'

import "./Main.css";
import ScheduleCard from './ScheduleCard/ScheduleCard';
import mainApi from '../../assets/api/mainApi';

function getGreeting() {
    var currentHour = Number(moment().format("HH"));
    if (currentHour >= 3 && currentHour < 12) {
        return mainGreeting.morning;
    } else if (currentHour >= 12 && currentHour < 15) {
        return mainGreeting.day;
    } else if (currentHour >= 15 && currentHour < 20) {
        return mainGreeting.evening;
    } else {
        return mainGreeting.night;
    }
}

// function getCurrentDayTime() {
//     var currentHour = Number(moment().format("HH"));
//     if (currentHour >= 3 && currentHour < 12) {
//         return 'morning';
//     } else if (currentHour >= 12 && currentHour < 15) {
//         return 'afternoon';
//     } else if (currentHour >= 15 && currentHour < 20) {
//         return 'evening';
//     } else {
//         return 'night';
//     }
// }

// const scrollToRef = (ref) => window.scrollTo({ top: ref.current.offsetTop, behavior: "smooth" })


function Main({ handleSkipPopupOpen, cards, setCards, handleTakePopupOpen, currentUser, shedule, setShedule, setCardsMissed, cardsMissed, sheduleMissed, setSheduleMissed }) {

    const scheduleRef = useRef(null)
    const topContentRef = useRef(null)

    // const [selectedDayPart, setSelectedDayPart] = useState(getCurrentDayTime())

    function handleSkipCard({ card, height }) {
        // console.log(id)
        // let upd_cards = cards.filter((item) => {
        //     if (item.id === id) return false
        //     else return true
        // })
        // console.log(upd_cards)
        // setCards(upd_cards)
        handleSkipPopupOpen({ card, height })
    }

    function handleTakeCard({ card, height }) {
        handleTakePopupOpen({ card, height })
    }


    // function handleSelectDayPart(dayPart) {
    //     setSelectedDayPart(dayPart)
    //     console.log(window.scrollY, scheduleRef.current.offsetTop, window.scrollY > scheduleRef.current.offsetTop)
    //     if (window.scrollY > scheduleRef.current.offsetTop) {
    //         scrollToRef(scheduleRef)
    //     }

    //     // scrollPageToTop()
    // }


    const listInnerRef = useRef();

    const [pageValue, setPageValue] = useState(0);
    const [prevScrollPosition, setPrevScrollPosition] = useState(-1);
    const [scrollPosition, setScrollPosition] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [isPreloaderVisible, setPreloaderVisible] = useState(false)

    const [scrollTraking, setScrollTraking] = useState(true);
    const handleScroll = () => {
        const position = window.pageYOffset;

        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {

        if (listInnerRef.current && scrollTraking && scrollPosition > prevScrollPosition && cards && cards.length > 0) {
            setPrevScrollPosition(scrollPosition)
            const { scrollHeight } = listInnerRef.current;
            if (scrollHeight < scrollPosition + 400) {
                setScrollTraking(false)
                setPageValue(pageValue + 1)
                setTimeout(() => {
                    setScrollTraking(true)
                }, 500);
            }
        }
    }, [scrollPosition, scrollTraking, prevScrollPosition, pageValue, cards]);

    useEffect(() => {

        if (pageValue > 0 && cards && cards.length === 10 * pageValue) {
            let last_id = cards[cards.length - 1]._id
            console.log(last_id)
            console.log('ss')
            setPreloaderVisible(true)
            mainApi.getTodayIntakes({ limit: 10, last_id: last_id })
                .then((res) => {

                    console.log(res.medicine_intakes)
                    setCards(prevList => prevList.concat(res.medicine_intakes))
                    setShedule(formatMedicineToShedule({ actualShedule: shedule, cardsToFormat: res.medicine_intakes }))
                })
                .catch((err) => {
                    console.log(err)
                })
            // mainApi.getPatients({ limit: 20, last_id: prevId, last_intakes_skips: prevIntakesMissed })
            //   .then((res) => {
            //     console.log(res.patients)
            //     setPreloaderVisible(false)
            //     setPatients(prewList => prewList.concat(res.patients))
            //   })
            //   .catch((err) => {
            //     setPreloaderVisible(false)
            //     console.log(err)
            //   })

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageValue, cards])


    const listInnerRef2 = useRef();

    const [page2Value, setPage2Value] = useState(0);
    const [prevScrollPosition2, setPrevScrollPosition2] = useState(-1);
    const [scrollPosition2, setScrollPosition2] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [isPreloaderVisible2, setPreloaderVisible2] = useState(false)

    const [scrollTraking2, setScrollTraking2] = useState(true);
    const handleScroll2 = () => {
        const position = window.pageYOffset;

        setScrollPosition2(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll2, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll2);
        };
    }, []);

    useEffect(() => {

        if (listInnerRef2.current && scrollTraking2 && scrollPosition2 > prevScrollPosition2 && cardsMissed && cardsMissed.length > 0) {
            setPrevScrollPosition2(scrollPosition2)
            const { scrollHeight } = listInnerRef2.current;
            if (scrollHeight < scrollPosition2 + 400) {
                setScrollTraking2(false)
                setPage2Value(page2Value + 1)
                setTimeout(() => {
                    setScrollTraking2(true)
                }, 500);
            }
        }
    }, [scrollPosition2, scrollTraking2, prevScrollPosition2, page2Value, cardsMissed]);

    useEffect(() => {

        if (page2Value > 0 && cardsMissed && cardsMissed.length === 10 * page2Value) {
            let last_id = cardsMissed[cardsMissed.length - 1]._id
            console.log(last_id)
            console.log('ss')
            setPreloaderVisible(true)
            mainApi.getTodayIntakes({ limit: 10, last_id: last_id })
                .then((res) => {

                    console.log(res.medicine_intakes)
                    setCardsMissed(prevList => prevList.concat(res.medicine_intakes))
                    setSheduleMissed(formatMedicineToShedule({ actualShedule: shedule, cardsToFormat: res.medicine_intakes }))
                })
                .catch((err) => {
                    console.log(err)
                })
            // mainApi.getPatients({ limit: 20, last_id: prevId, last_intakes_skips: prevIntakesMissed })
            //   .then((res) => {
            //     console.log(res.patients)
            //     setPreloaderVisible(false)
            //     setPatients(prewList => prewList.concat(res.patients))
            //   })
            //   .catch((err) => {
            //     setPreloaderVisible(false)
            //     console.log(err)
            //   })

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page2Value, cardsMissed])




    return (
        <div className="main">
            <div className="main__top-content" ref={topContentRef}>
                <div className="main__heading">
                    <div className="main__greeting">
                        <p className="main__greeting-text">{getGreeting().greetingText}, {currentUser && currentUser.name ? currentUser.name[0].toUpperCase() + currentUser.name.substring(1) : 'Zam'}</p>
                        <p className="main__greeting-date">Today {moment().format('MMMM')}, <span className="main__greeting-date-span">{moment().format('D')}</span></p>
                    </div>
                    <img className='main__heading-icon' src={getGreeting().icon} alt=''></img>
                </div>
                <div className="main__banner">
                    {currentUser && currentUser.suggest_new_questionnaire ?
                        <>
                            <p className="main__banner-text">Don't forget<br />to take the daily questionnaire</p>
                            <Link to='/daily-questionare' className="main__banner-link">Let&#x2019;s go</Link>
                            <div className='main__banner-elips main__banner-elips_position_left'></div>
                            <div className='main__banner-elips main__banner-elips_position_right'></div>
                        </>
                        :
                        <>
                            <p className="main__banner-text">You have successfully completed the questionnaire</p>
                            <div className='main__banner-elips main__banner-elips_position_left'></div>
                            <div className='main__banner-elips main__banner-elips_position_right'></div>
                        </>}

                </div>
            </div>

            <div className="main__schedule-and-title" ref={scheduleRef}>
                <p className="main__schedule-title">Your daily schedule</p>
                <div className="main__schedule" >
                    {/* <div className="main__schedule-selectors">
                        <div className={`main__schedule-selector`} onClick={() => handleSelectDayPart('morning')}>
                            <p className={`main__schedule-selector-text ${selectedDayPart === 'morning' ? 'main__schedule-selector-text_active' : ''}`}>Morning</p>
                            <div className={`main__schedule-selector-bg main__schedule-selector-bg_type_morning ${selectedDayPart === 'morning' ? 'main__schedule-selector-bg_active' : ''}`}></div>
                        </div>
                        <div className={`main__schedule-selector`} onClick={() => handleSelectDayPart('afternoon')}>
                            <p className={`main__schedule-selector-text ${selectedDayPart === 'afternoon' ? 'main__schedule-selector-text_active' : ''}`}>Afternoon</p>
                            <div className={`main__schedule-selector-bg main__schedule-selector-bg_type_afternoon ${selectedDayPart === 'afternoon' ? 'main__schedule-selector-bg_active' : ''}`}></div>
                        </div>
                        <div className={`main__schedule-selector`} onClick={() => handleSelectDayPart('evening')}>
                            <p className={`main__schedule-selector-text ${selectedDayPart === 'evening' ? 'main__schedule-selector-text_active' : ''}`}>Evening</p>
                            <div className={`main__schedule-selector-bg main__schedule-selector-bg_type_evening ${selectedDayPart === 'evening' ? 'main__schedule-selector-bg_active' : ''}`}></div>
                        </div>
                        <div className={`main__schedule-selector`} onClick={() => handleSelectDayPart('night')}>
                            <p className={`main__schedule-selector-text ${selectedDayPart === 'night' ? 'main__schedule-selector-text_active' : ''}`}>Night</p>
                            <div className={`main__schedule-selector-bg main__schedule-selector-bg_type_night ${selectedDayPart === 'night' ? 'main__schedule-selector-bg_active' : ''}`}></div>
                        </div>

                    </div> */}

                    <div className={`main__schedule-cards main__schedule-cards_missed`} ref={listInnerRef2}>
                        {sheduleMissed ?
                            <>
                                {sheduleMissed.length > 0 ? sheduleMissed.map((shedule_item, i) => (
                                    <div className='main__schedule-cards-block' key={`main__schedule-cards-block${i}${shedule_item && shedule_item.time}`}>
                                        <p className='main__schedule-time'>{shedule_item && shedule_item.time}</p>
                                        {shedule_item && shedule_item.items && shedule_item.items.length > 0 ? shedule_item.items.map((item, i2) => (
                                            <ScheduleCard type={'medicine-missed'} handleTakeCard={handleTakeCard} handleSkipCard={handleSkipCard} card={item} cardKey={`ScheduleCard-card${i2}_${item._id}-card-key`} key={`ScheduleCard-card${i2}_${item._id}`} />
                                        ))
                                            : <></>
                                        }
                                    </div>

                                )) :
                                    <></>
                                }
                            </>
                            : <></>}
                    </div>


                    <div className={`main__schedule-cards`} ref={listInnerRef}>
                        {shedule ?
                            <>
                                {shedule.length > 0 ? shedule.map((shedule_item, i) => (
                                    <div className='main__schedule-cards-block' key={`main__schedule-cards-block${i}${shedule_item && shedule_item.time}`}>
                                        <p className='main__schedule-time'>{shedule_item && shedule_item.time}</p>
                                        {shedule_item && shedule_item.items && shedule_item.items.length > 0 ? shedule_item.items.map((item, i2) => (
                                            <ScheduleCard type={'medicine-for-now'} handleTakeCard={handleTakeCard} handleSkipCard={handleSkipCard} card={item} cardKey={`ScheduleCard-card${i2}_${item._id}-card-key`} key={`ScheduleCard-card${i2}_${item._id}`} />
                                        ))
                                            : <></>
                                        }
                                    </div>

                                )) :
                                    <div className='main__schedule-no-cards'>
                                        <img className='main__schedule-no-cards-icon' src={noCardsIcon} alt='' />
                                        <p className='main__schedule-no-cards-text'>Great, you're all done<br />for today!</p>
                                    </div>
                                }
                            </>
                            : <></>}
                    </div>
                </div>
            </div>


        </div>
    );

}

export default Main;


// ${cards.filter((item)=>{
//     if(item.skipping) return true
//     else return false
// }).length > 0 ? 'main__schedule-cards_skipping' : ''}

