import React from 'react';
import '../Register.css'


function InputHeading({ title, subtitle }) {
  return (
    <div className="register__input-box-heading">
      <h2 className="register__input-box-title">{title}</h2>
      {subtitle && <p className="register__input-box-subtitle">{subtitle}</p>}
    </div>
  );
}

export default InputHeading;

