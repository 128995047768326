import { Link } from "react-router-dom";
import { CARD_ARROW_ICON } from "../../assets/utils/icons";
import "./Profile.css";



function Profile({currentUser}) {
  return (
    <section className="profile">
      <div className="profile__content">
        <div className="profile__title-box">
          <p className="profile__title">Your Patient ID:</p>
          <p className="profile__id">{currentUser ? currentUser.ai_id : ''}</p>
        </div>

        <ul className="profile__links-list">
          <li className="profile__link-item">
            <Link className="profile__link" to="/profile/personal-data">
              <p className="profile__link-text">Personal data</p>
              <div className="profile__link-icon">{CARD_ARROW_ICON}</div>
            </Link>
          </li>

          <li className="profile__link-item">
            <Link className="profile__link" to="/profile/medication-data">
              <p className="profile__link-text">Your medicine</p>
              <div className="profile__link-icon">{CARD_ARROW_ICON}</div>
            </Link>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Profile;
