import { useState, useEffect, useRef } from "react";
import {
  ACTIONS_MENU_ICON,
  EDIT_BUTTON_ICON,
  DELETE_BUTTON_ICON,
  SCHEDULE_ICON,
  EDIT_REASONS_ICON,
} from "../../../assets/utils/icons";
import tablet from "../../../assets/images/disorders/tablet.png";
import pill from "../../../assets/images/disorders/pill.png";
import spray from "../../../assets/images/disorders/spray.png";
import injection from "../../../assets/images/disorders/injection.png";
import procedure from "../../../assets/images/disorders/procedure.png";
import drops from "../../../assets/images/disorders/drops.png";
import ointment from "../../../assets/images/disorders/ointment.png";
import syrup from "../../../assets/images/disorders/syrup.png";
import "./DrugCard.css";
import { parseFoodTime } from "../../../assets/utils/utilis";

function DrugCard({
  drug,
  onDelete,
  onMedicineEdit,
  onIntakeEdit,
  onReasonsEdit,
  type,
}) {
  const overlay = useRef();
  const [isActionsPopupOpen, setActionsPopupOpen] = useState(false);
  const [image, setImage] = useState(undefined);

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        setActionsPopupOpen(false);
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });

  useEffect(() => {
    switch (true) {
      case drug.type === "tablet":
        setImage(tablet);
        break;
      case drug.type === "pill":
        setImage(pill);
        break;
      case drug.type === "spray":
        setImage(spray);
        break;
      case drug.type === "injection":
        setImage(injection);
        break;
      case drug.type === "procedure":
        setImage(procedure);
        break;
      case drug.type === "drops":
        setImage(drops);
        break;
      case drug.type === "ointment":
        setImage(ointment);
        break;
      case drug.type === "syrup":
        setImage(syrup);
        break;
      default:
        setImage(pill);
    }
  }, [drug]);

  function openActionsPopup(i) {
    setActionsPopupOpen(true);
  }

  function closeActionsPopup(i) {
    setActionsPopupOpen(false);
  }

  function handleDeleteClick() {
    closeActionsPopup();
    onDelete(drug);
  }

  function handleMedicineEditClick() {
    closeActionsPopup();
    onMedicineEdit(drug);
  }

  function handleIntakeEditClick() {
    closeActionsPopup();
    onIntakeEdit(drug);
  }

  function handleReasonsEditClick() {
    closeActionsPopup();
    onReasonsEdit(drug);
  }

  console.log(drug)

  return (
    <li
      className={`drug-card ${!drug.continued ? "drug-card_discontinued" : ""}`}
    >
      <div className="drug-card__icon-box">
        <img
          className="drug-card__drug-image"
          src={image}
          alt={drug.name}
        />
      </div>
      <div className="drug-card__info-box">
        <p className="drug-card__drug-name">
          {drug.name.slice(0, 1).toUpperCase() + drug.name.slice(1)}
        </p>
        <p className="drug-card__drug-dosage">
          {drug.dosage} {drug.measure},{" "}
          {!drug.continued
            ? `took ${drug.duration} ${drug.duration > 1 ? "days" : "day"}`
            : drug.frequency === "One time intake"
              ? "one time intake"
              : [
                `take ${drug.total_intakes} times a`,
                <span>&nbsp;</span>,
                "day",
              ]}{", "}
          {parseFoodTime(drug.foodIntake)}
        </p>
      </div>
      <div
        className={`drug-card__box-with-drop ${type ? `drug-card__box-with-drop_type_${type}` : ""
          }`}
      >
        <button
          className="drug-card__open-actions-button"
          type="button"
          aria-label="Open actions menu"
          onClick={openActionsPopup}
        >
          {ACTIONS_MENU_ICON}
        </button>
        <ul
          className={`drug-card__actions-menu ${isActionsPopupOpen ? "drug-card__actions-menu_open" : ""
            }`}
        >
          <li className="drug-card__action-item">
            <button
              className="drug-card__action-button"
              type="button"
              onClick={handleMedicineEditClick}
            >
              <p className="drug-card__action-text">Edit medication</p>
              <div className="drug-card__action-icon">{EDIT_BUTTON_ICON}</div>
            </button>
          </li>

          <li className="drug-card__action-item">
            <button
              className="drug-card__action-button"
              type="button"
              onClick={
                drug.continued ? handleIntakeEditClick : handleReasonsEditClick
              }
            >
              <p className="drug-card__action-text">
                Edit {drug.continued ? "schedule" : "reasons"}
              </p>
              <div className="drug-card__action-icon">
                {drug.continued ? SCHEDULE_ICON : EDIT_REASONS_ICON}
              </div>
            </button>
          </li>

          <li className="drug-card__action-item">
            <button
              className="drug-card__action-button"
              type="button"
              onClick={handleDeleteClick}
            >
              <p className="drug-card__action-text">Delete</p>
              <div className="drug-card__action-icon">{DELETE_BUTTON_ICON}</div>
            </button>
          </li>
        </ul>
        <div
          className="drug-card__overlay drug-card__overlay_type_actions"
          ref={overlay}
          onTouchStart={closeActionsPopup}
        />
      </div>
    </li>
  );
}

export default DrugCard;
