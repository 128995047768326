import { useEffect, useRef, useState } from "react";
import Input from "../Input/Input";
import "./ChangeEmail.css";

import validator from "validator";
import mainApi from "../../assets/api/mainApi";
import MiniPreloader from "../MiniPreloader/MiniPreloader";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function ChangeEmail({ currentUser, setCurrentUser }) {

    const navigate = useNavigate()

    const [step, setStep] = useState(0)
    const [emailValue, setEmailValue] = useState('')
    const [emailValidity, setEmailValidity] = useState({})

    const [code, setCode] = useState({})
    const [codeValidity, setCodeValidity] = useState({});
    const [codeError, setCodeError] = useState('')

    const [submitError, setSubmitError] = useState('')

    function handleChange(evt) {
        let inputValue = evt.target.value.replace(/\s/g, '')
        setEmailValue(inputValue)

        if (!inputValue) {
            setEmailValidity({
                errorMessage: (''),
                validState: false
            })
        } else {
            if (inputValue.length > 5) {

                if (validator.isEmail(inputValue)) {
                    console.log(inputValue)
                    mainApi.checkEmailValid({ email: inputValue })
                        .then(() => {
                            setEmailValidity({
                                errorMessage: '',
                                validState: true
                            })
                        })
                        .catch((err) => {
                            switch (err.detail) {
                                case 'Email or phone are already change-emailed':
                                    setEmailValidity({
                                        errorMessage: 'This email is already change-emailed',
                                        validState: false
                                    })
                                    break;

                                default:
                                    setEmailValidity({
                                        errorMessage: err.detail,
                                        validState: false
                                    })
                                    break;
                            }

                        })

                } else {
                    setEmailValidity({
                        errorMessage: ('Enter a valid email'),
                        validState: false
                    })
                }
            }
            else {
                setEmailValidity({
                    errorMassage: (''),
                    validState: false
                })
            }

        }
    }
    // eslint-disable-next-line no-unused-vars
    const [submitPreloaderVisible, setSubmitPreloaderVisible] = useState(false)
    const [isNextBtnActive, setNextBtnActive] = useState(false)
    function handleStepBack() {
        switch (step) {
            case 0:
                navigate(-1)
                break;

            default:
                setStep(step + -1)
                break;
        }
    }

    function handleNextStep() {
        if (isNextBtnActive) {
            switch (step) {
                case 0:

                    mainApi.sendCodeToEmail({ email: emailValue })
                        .then((res) => {
                            setCurrentUser(res)
                            setStep(step + 1)
                            console.log(res)
                        })
                        .catch((err) => {
                            switch (err.detail) {
                                case 'Too Many Requests':
                                    setSubmitError('Too Many Requests')
                                    setTimeout(() => {
                                        setSubmitError('')
                                    }, 10000);
                                    break;

                                case 'Email or phone are already registered':
                                    setSubmitError('This email is already registered')
                                    setTimeout(() => {
                                        setSubmitError('')
                                    }, 5000);
                                    break;

                                case 'Email was not sent':
                                    setSubmitError('We were unable to send the code. Something is wrong with the specified email, check the spelling.')
                                    setTimeout(() => {
                                        setSubmitError('')
                                    }, 5000);
                                    break;

                                case 'Email generation timeout not passed':
                                    setSubmitError('Too little time has passed since the last request, wait and try again')
                                    setTimeout(() => {
                                        setSubmitError('')
                                    }, 5000);
                                    break;

                                default:
                                    if (err.detail) {
                                        setSubmitError(err.detail)
                                    }
                                    else {
                                        setSubmitError('Something went wrong')
                                    }
                                    setTimeout(() => {
                                        setSubmitError('')
                                    }, 5000);
                                    break;
                            }
                            console.log(err)
                        })
                    break;

                case 1:
                    setStep(step + 1)
                    break;

                case 2:
                    navigate(-1)
                    break;

                default:
                    break;
            }
        }

    }

    useEffect(() => {

        switch (step) {
            case 0:
                if (emailValue && emailValidity.validState) {
                    setNextBtnActive(true)
                } else {
                    setNextBtnActive(false)
                }
                break;
            case 1:
                if (code && codeValidity.validState) {
                    setNextBtnActive(true)
                } else {
                    setNextBtnActive(false)
                }
                break;
            case 2:
                setNextBtnActive(true)
                break;

            default:
                setNextBtnActive(false)
                break;
        }

    }, [step, emailValue, emailValidity, code, codeValidity])


    const firstInput = useRef()



    function handleCodeChange(e, i) {
        setCodeError('')
        const input = e.target.value;
        const form = e.target.form;
        const index = Array.prototype.indexOf.call(form, e.target);
        console.log(input.length)
        if (input.length === 4) {
            setCode({
                0: input[0].toUpperCase(),
                1: input[1].toUpperCase(),
                2: input[2].toUpperCase(),
                3: input[3].toUpperCase(),
            });
            form.elements[3].focus();
        } else {
            if (code[i]) {
                setCode({ ...code, [i]: input[1].toUpperCase() });
                if (index !== 3) {
                    form.elements[index + 1].focus();
                }
            } else {
                setCode({ ...code, [i]: input[0].toUpperCase() });
                if (index !== 3) {
                    form.elements[index + 1].focus();
                }
            }

        }



    }



    function handleKeyDown(e) {
        const form = e.target.form;

        const index = Array.prototype.indexOf.call(form, e.target);
        console.log(e.keyCode)
        // if (e.keyCode === 13) {
        //     form.elements[index + 1].focus();
        //     e.preventDefault();
        // }
        if (e.keyCode === 8 || e.keyCode === 37) {
            // setCode({ ...code, [index]: '' });
            if (code[index]) {
                setCode({ ...code, [index]: '' });
            } else {
                if (index === 0) {
                    setCode({ ...code, [index]: '' });
                } else {
                    setCode({ ...code, [index]: '', [index - 1]: '' });
                    form.elements[index - 1].focus();
                }

            }

            e.preventDefault();
        }
    }
    function handleFormEnter(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    }



    // eslint-disable-next-line no-unused-vars
    const [timerValue, setTimerValue] = useState(0);
    const [timeLeft, setTimeLeft] = useState(0)




    useEffect(() => {
        if (currentUser) {
            const timer = setInterval(() => {
                setTimeLeft(Number(moment().format('X')) - Number(moment(`${currentUser.new_email.generated_utc}+00:00`).format('X')))
                clearInterval(timer)
            }, 1000);

        } else {
            setTimerValue(0)
        }

    }, [currentUser, timeLeft])


    const [isEmailResanding, setEmailResending] = useState(false)
    const [emailResendError, setEmailResendError] = useState('')
    function handleResendCode() {

        mainApi.sendCodeToEmail({ email: emailValue })
            .then((res) => {
                console.log(res)
                setCurrentUser(res)
                setTimerValue(0)
            })
            .catch((err) => {
                setEmailResendError(err.detail)
                setTimeout(() => {
                    setEmailResendError('')
                }, 5000);
                console.log(err)
            })
            .finally(() => {
                setEmailResending(false)
            })
        // mainApi.getNewWappCode({ id: userId })
        //     .then((res) => {
        //         console.log(res)
        //         setTimerValue(0)
        //     })
        //     .catch((err) => {
        //         setTimerValue(0)
        //         console.log(err)
        //   

    }

    useEffect(() => {
        if (code[0] && code[1] && code[2] && code[3]) {

            console.log({ code: `${code[0] + code[1] + code[2] + code[3]}` })
            mainApi.checkEmailCode({ code: `${code[0] + code[1] + code[2] + code[3]}` })
                .then((res) => {
                    console.log(res)
                    setCurrentUser(res)
                    setStep(prewStep => prewStep + 1)
                    setCodeValidity({
                        validState: true,
                    })
                })
                .catch((err) => {
                    console.log(err)
                    switch (err.detail) {
                        case 'Wrong code was sent':
                            setCodeError('Wrong code')
                            break;

                        default:
                            break;
                    }
                })

            // setCode({})
        } else {
            setCodeError('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code])

    return (
        <div className="change-email">
            {step === 0 ?
                <div className="change-email__content">
                    <p className="change-email__title">Enter new email address you want to&nbsp;use for notifications</p>
                    <div className="change-email__input-container">
                        <Input
                            label="Email"
                            value={emailValue}
                            name="email"
                            type="email"
                            inputMode="text"
                            handleChange={handleChange}
                            isRequired={true}
                            error={emailValidity}
                        />
                    </div>
                    <p className="change-email__submit-error">{submitError}</p>
                </div>

                : <></>}

            {step === 1 ?
                <div className="change-email__content">
                    <p className="change-email__title">Enter the code we sent you at <span className="change-email__title_span">{emailValue.toLowerCase()}</span> to confirm the email address change</p>

                    <p className="change-email__error">{codeError}</p>
                    <form className="change-email__code" onKeyDown={handleFormEnter}>
                        <div className="change-email__code-element">
                            <input inputMode='numeric' ref={firstInput} onKeyDown={handleKeyDown} className={`change-email__code-input ${codeError ? 'change-email__code-input_error' : ''}`} placeholder="0" name="search" type="text" required value={code['0'] || ''} onChange={(e) => handleCodeChange(e, 0)} minLength="0" maxLength="4" autoComplete='off'></input>
                            <div className={`change-email__code-element-line ${code[0] ? 'change-email__code-element-line_typen' : ''} ${codeError ? 'change-email__code-element-line__error' : ''}`}></div>
                        </div>
                        <div className="change-email__code-element">
                            <input inputMode='numeric' onKeyDown={handleKeyDown} className={`change-email__code-input ${codeError ? 'change-email__code-input_error' : ''}`} placeholder="0" name="search" type="text" required value={code['1'] || ''} onChange={(e) => handleCodeChange(e, 1)} minLength="0" maxLength="2" autoComplete='off'></input>
                            <div className={`change-email__code-element-line ${code[1] ? 'change-email__code-element-line_typen' : ''} ${codeError ? 'change-email__code-element-line__error' : ''}`}></div>
                        </div>
                        <div className="change-email__code-element">
                            <input inputMode='numeric' onKeyDown={handleKeyDown} className={`change-email__code-input ${codeError ? 'change-email__code-input_error' : ''}`} placeholder="0" name="search" type="text" required value={code['2'] || ''} onChange={(e) => handleCodeChange(e, 2)} minLength="0" maxLength="2" autoComplete='off'></input>
                            <div className={`change-email__code-element-line ${code[2] ? 'change-email__code-element-line_typen' : ''} ${codeError ? 'change-email__code-element-line__error' : ''}`}></div>
                        </div>
                        <div className="change-email__code-element">
                            <input inputMode='numeric' onKeyDown={handleKeyDown} className={`change-email__code-input ${codeError ? 'change-email__code-input_error' : ''}`} placeholder="0" name="search" type="text" required value={code['3'] || ''} onChange={(e) => handleCodeChange(e, 3)} minLength="0" maxLength="2" autoComplete='off'></input>
                            <div className={`change-email__code-element-line ${code[3] ? 'change-email__code-element-line_typen' : ''} ${codeError ? 'change-email__code-element-line__error' : ''}`}></div>
                        </div>
                    </form>
                    <p className="change-email__not-recive">Did not recieve the code?</p>
                    {/* {timeLeft} */}
                    {isEmailResanding ?
                        <div className='change-email__code-resend-preloader'>
                            <MiniPreloader />
                        </div>
                        :
                        emailResendError ?
                            <p className="change-email__code-resend-error">{emailResendError}</p>
                            :
                            <>
                                {61 - Number(timeLeft) <= 0 ?
                                    <p className="change-email__code-resend change-email__code-resend_active" onClick={() => {
                                        if (!isEmailResanding) {
                                            setEmailResending(true)
                                            handleResendCode()
                                        }

                                    }}>Send again</p>
                                    :
                                    <p className="change-email__code-resend">Send again in {61 - timeLeft} sec</p>
                                }
                            </>
                    }
                </div>

                : <></>}

            {step === 2 ?
                <div className="change-email__final">
                    <svg className="change-email__final-icon" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.97668 11.6298C8.53503 11.222 9.2083 11.0015 9.89998 11H34.1C34.7767 11.0005 35.4365 11.2107 35.9889 11.6016L22.9115 20.1966C22.6414 20.3742 22.3254 20.4692 22.002 20.4697C21.6789 20.4699 21.3624 20.3761 21.0918 20.1994L7.97668 11.6298ZM24.1197 22.0352C23.4916 22.4482 22.7567 22.6689 22.0051 22.6702C21.2535 22.6712 20.5179 22.4529 19.8888 22.0416L6.72777 13.4415C6.64699 13.7209 6.60402 14.0097 6.6001 14.3004V29.7004C6.60108 30.5752 6.949 31.4142 7.56778 32.0327C8.18629 32.6514 9.02532 32.9994 9.9001 33.0004H34.1001C34.9749 32.9994 35.814 32.6515 36.4324 32.0327C37.0512 31.4142 37.3991 30.5752 37.4001 29.7004V14.3004C37.3957 13.9947 37.3483 13.691 37.2592 13.3983L24.1197 22.0352Z" fill="#006B58" />
                    </svg>
                    <p className="change-email__final-text">Email has been<br />successfuly changed</p>
                </div>
                : <></>}

            <div className='change-email__step-btns'>
                {step !== 2 ?
                    <div className='change-email__step-btn-back' onClick={handleStepBack}>
                        <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 1L1.61152 5.82733C1.26477 6.20875 1.26477 6.79125 1.61152 7.17267L6 12" stroke="#191C1B" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                    </div>
                    : <></>
                }


                <div className={`change-email__step-btn-next ${isNextBtnActive ? 'change-email__step-btn-next_active' : ''}`}
                    onClick={handleNextStep}>
                    {submitPreloaderVisible ?
                        <MiniPreloader isLinkColor={true} />
                        :
                        <>
                            {step === 2 ?
                                <p className='change-email__step-btn-next-text'>Done</p>
                                :
                                <p className='change-email__step-btn-next-text'>Next</p>
                            }
                        </>

                    }

                </div>


            </div>
        </div>
    );
}

export default ChangeEmail;
