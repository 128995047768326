import { useEffect } from "react";
import RegCheckbox from "../../RegCheckbox/RegCheckbox";
import Input from "../../../Input/Input";
import { FAMILY_MEMBERS_LIST } from "../../../../assets/utils/disordersInfo";
import {
  MORE_BUTTON_ICON,
  REMOVE_BUTTON_ICON,
} from "../../../../assets/utils/icons";
import "./FamilyHistory.css";

function FamilyHistory({
  setDisabled,
  history,
  setHistory,
  noDisorders,
  setNoDisorders,
}) {
  useEffect(() => {
    history.find((item) => item.validState) || noDisorders
      ? setDisabled(false)
      : setDisabled(true);
  }, [history, noDisorders, setDisabled]);

  function handleMemberChange(i, member) {
    const arr = history.slice();
    if (Boolean(arr[i].member) || arr[i].member !== member) {
      arr[i].member = member;
    } else {
      arr[i].member = "";
    }
    checkValidity(i);
    setHistory(arr);
    setNoDisorders(false);
  }

  function handleDisorderChange(evt, i) {
    const value = evt.target.value;
    const arr = history.slice();
    arr[i].validState = false;
    if (!value) {
      arr[i].validState = false;
    } else {
      arr[i].disorder = value;
      checkValidity(i);
    }
    arr[i].disorder = value;
    setHistory(arr);
    setNoDisorders(false);
  }

  function handleCheckbox(evt) {
    const value = evt.target.checked;
    const newArr = {
      member: "",
      disorder: "",
      validState: false,
    };
    setHistory([newArr]);
    setNoDisorders(value);
  }

  function checkValidity(i) {
    const arr = history.slice();
    arr[i].validState = Boolean(arr[i].member) && Boolean(arr[i].disorder);
    setHistory(arr);
  }

  function handleAddInput(i) {
    if (history[i].validState) {
      const arr = [
        ...history,
        {
          member: "",
          disorder: "",
          validState: false,
        },
      ];
      setHistory(arr);
    }
  }

  function handleRemoveInput(index) {
    const arr = history.filter((item, i) => i !== index);
    setHistory(arr);
  }

  return (
    <div className="history">
      <p className="history__title">Hereditary syndromes in your&nbsp;family</p>
      <p className="history__subtitle">
        Please inform us about any hereditary syndromes in your family
      </p>
      <div className="history__checkbox">
        <RegCheckbox
          name="noDisoders"
          isRequired={false}
          isChecked={noDisorders}
          onClick={handleCheckbox}
          label="I dont have hereditary syndromes in the family"
        />
      </div>
      <ul className="history__disorders">
        {history.map((item, i) => (
          <li className="history__member-card" key={`inputs-block${i}`}>
            <div className="history__member-box">
              <p className="history__member-title">Choose family member</p>
              <ul className="history__member-list">
                {FAMILY_MEMBERS_LIST.map((member, j) => (
                  <li
                    className={`history__member ${item.member === member ? "history__member_selected" : ""
                      }`}
                    key={`member${j}`}
                    onClick={() => handleMemberChange(i, member)}
                  >
                    <p className="history__member-text">{member}</p>
                  </li>
                ))}
              </ul>
            </div>

            <Input
              index={i}
              label="Name of the syndrome"
              value={item.disorder}
              name="disorder"
              type="text"
              handleChange={handleDisorderChange}
              isRequired={true}
            />

            {i === history.length - 1 ? history.length <= 9 ? (
              <button
                className={`history__more-button ${!item.validState ? "history__more-button_disabled" : ""
                  }`}
                type="button"
                onClick={() => handleAddInput(i)}
              >
                <div className="history__button-icon">{MORE_BUTTON_ICON}</div>
                <p className="history__button-text">Add more</p>
              </button>
            ) : <></> : (
              <button
                className="history__more-button"
                type="button"
                onClick={() => handleRemoveInput(i)}
              >
                <div className="history__button-icon">{REMOVE_BUTTON_ICON}</div>
                <p className="history__button-text">Remove</p>
              </button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FamilyHistory;
