import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import './Login.css';

import step1Icon from '../../../assets/images/register/step-1.png'


import EmailAndPassword from '../EmailAndPassword/EmailAndPassword';

import mainApi from '../../../assets/api/mainApi';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';




function Login({ setCurrentUser, currentUser, getCurentUser }) {
    const navigate = useNavigate();
    const { emailPrewValue } = useParams()


    const [emailValue, setEmailValue] = useState('')
    const [emailValidity, setEmailValidity] = useState({});

    const [passValue, setPassValue] = useState('')
    const [passValidity, setPassValidity] = useState({});


    useEffect(() => {
        if (emailPrewValue) {
            setEmailValue(emailPrewValue)
            setEmailValidity({
                errorMassage: '',
                validState: true
            })
        }
    }, [emailPrewValue])



    useEffect(() => {
        if (currentUser) {

        }
    }, [currentUser])

    const [loginError, setLoginError] = useState('')
    const [submitPreloaderVisible, setSubmitPreloaderVisible] = useState(false)




    const [isNextBtnActive, setNextBtnActive] = useState(true)

    useEffect(() => {
        if (emailValidity.validState && passValidity.validState) {
            setNextBtnActive(true)
        } else {
            setNextBtnActive(false)
        }
    }, [emailValidity, passValidity])

    function handleNextStep() {
        if (isNextBtnActive) {
            console.log({ email: emailValue, password: passValue })
            setSubmitPreloaderVisible(true)
            mainApi.login({ email: emailValue, password: passValue })
                .then((res) => {
                    console.log(res)

                    if (res.register_stage === 0 || res.register_stage === 1) {
                        navigate('/sign-up')
                    } else if (res.register_stage > 1 && res.register_stage < 10) {
                        navigate('/sign-up/disorders')
                    } else {
                        navigate('/')
                        getCurentUser()
                    }
                    setCurrentUser(res)
                })
                .catch((err) => {
                    console.log(err)
                    switch (err.statusCode) {
                        case 500:
                            setLoginError('Something went wrong, try again later')
                            break;

                        default:
                            setLoginError('Incorrect email or password')
                            break;
                    }
                    setTimeout(() => {
                        setLoginError('')
                    }, 5000);
                })
                .finally(() => {

                    setSubmitPreloaderVisible(false)
                })
        }


    }

    function handleStepBack(){
        navigate('/onboarding/1')
    }

    return (
        <div className="login">
            <div className="login__carousel-wrapper">
                <div className={`login__carousel `} >
                    <div className="login__icon-card" >
                        <img className="login__icon"
                            src={step1Icon} alt=''></img>
                    </div>
                </div>
            </div>

            <div className="login__bg"></div>
            <div className="login__bg-white"></div>
            <div className="login__container">
                <div className="login__steps-index">

                </div>
                <div className='login__step-block'>
                    <EmailAndPassword isLogin={true} passValue={passValue} setPassValue={setPassValue} passValidity={passValidity} setPassValidity={setPassValidity} emailValue={emailValue} setEmailValue={setEmailValue} emailValidity={emailValidity} setEmailValidity={setEmailValidity} />
                    {loginError ? <p className='login__submit-error'>{loginError}</p> : <></>}
                </div>
                <div className='login__step-btns'>
                    <div className='login__step-btn-back' onClick={handleStepBack}>
                        <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 1L1.61152 5.82733C1.26477 6.20875 1.26477 6.79125 1.61152 7.17267L6 12" stroke="#191C1B" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                    </div>
                    <div className={`login__step-btn-next ${isNextBtnActive && !loginError ? 'login__step-btn-next_active' : ''}`}
                        onClick={handleNextStep}>
                        {submitPreloaderVisible ?
                            <MiniPreloader isLinkColor={true} />
                            :
                            <p className='login__step-btn-next-text'>Log in</p>
                        }

                    </div>

                </div>
            </div>
        </div>

    );
}

export default Login;
