
// import { Link } from 'react-router-dom';

import { useState } from 'react';
import { countries } from '../../assets/utils/countries';
import './CountryPopup.css';



function CountryPopup({ countryPopupOpened, setCountryPopupOpened, setCountryValue, countryValue }) {

    const [selectedCountry, setSelectedCountry] = useState(countryValue ? countryValue : '')
    const [serachValue, setSearchValue] = useState('')
    const [isFocuced, setIsFocuced] = useState({});

    function handleFocused(name) {

        setIsFocuced({ ...isFocuced, [name]: true });
    }

    function handleUnfocused(name) {

        setIsFocuced({ ...isFocuced, [name]: false });
    }

    function handleSearchChange(e) {
        let inputValue = e.target.value
        setSearchValue(inputValue)
    }

    // useEffect(() => {
    //     if (notifyPopupOpened && notifications && notifications.length > 0 && notifications.filter((item) => {
    //         if (item.viewed) return false
    //         else return true
    //     }).length > 0) {

    //         pythonApi.changeNotifyViewProps({
    //             notifications: notifications.filter((item) => {
    //                 if (item.viewed) return false
    //                 else return true
    //             }).map((item) => {
    //                 return { notification_id: item._id }
    //             })
    //         })
    //             .then((res) => {
    //                 console.log(res)
    //                 setTimeout(() => {
    //                     setCurrentUser(exampleState => ({
    //                         ...exampleState, notifications: exampleState.notifications.map((item) => {
    //                             item.viewed = true
    //                             return item
    //                         }), notifications_not_viewed: 0
    //                     }))
    //                 }, 400);
    //             })
    //             .catch((err) => {
    //                 console.log(err)
    //             })


    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [notifyPopupOpened, notifications]);

    function handleClose() {
        setCountryPopupOpened(false)
        window.scrollTo(0, 0);
        setTimeout(() => {
            var items = document.getElementById("country-items");
            items.scrollTo({ top: 0 });
        }, 300);

    }

    return (
        <div className={`country-popup ${countryPopupOpened ? 'country-popup_active' : ''}`}>
            <div className={`country-popup__container ${countryPopupOpened ? 'country-popup__container_active' : ''}`}>
                <svg className="country-popup__close" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClose}>
                    <path className="country-popup__close-fill" d="M2.98555 13.0263C3.17461 13.2155 3.4304 13.3219 3.69701 13.3219C3.96363 13.3219 4.21942 13.2155 4.40848 13.0263L7.98126 9.37669L11.554 13.0263C11.8171 13.2914 12.2008 13.3949 12.5604 13.2979C12.9199 13.2009 13.2009 12.9179 13.2972 12.5557C13.3935 12.1936 13.2907 11.8071 13.0275 11.5421L9.40421 7.94341L13.0275 4.34471C13.25 4.08314 13.3257 3.72558 13.2287 3.39526C13.1318 3.06494 12.8751 2.80641 12.5472 2.70874C12.219 2.61105 11.8642 2.68729 11.6044 2.91142L7.98125 6.51012L4.40847 2.91142C4.14879 2.68729 3.79381 2.61105 3.46587 2.70874C3.13792 2.80642 2.88126 3.06493 2.78429 3.39526C2.6873 3.72558 2.763 4.08314 2.98551 4.34471L6.55829 7.94341L2.98551 11.5421C2.78175 11.7342 2.66602 12.003 2.66602 12.2841C2.66602 12.5654 2.78175 12.8339 2.98551 13.0262L2.98555 13.0263Z" fill="white" />
                </svg>



                <div className='country-popup__content'>
                    <h3 className='country-popup__title'>Select your country</h3>
                    <div
                        className={`country-popup__input-box ${serachValue || isFocuced.search ? "country-popup__input-box_focused" : ""
                            }`}

                    >
                        <svg className="country-popup__input-search-icon" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.3">
                                <path d="M9.5 3.5C11.2239 3.5 12.8772 4.18482 14.0962 5.40381C15.3152 6.62279 16 8.27609 16 10C16 11.61 15.41 13.09 14.44 14.23L14.71 14.5H15.5L20.5 19.5L19 21L14 16V15.21L13.73 14.94C12.59 15.91 11.11 16.5 9.5 16.5C7.77609 16.5 6.12279 15.8152 4.90381 14.5962C3.68482 13.3772 3 11.7239 3 10C3 8.27609 3.68482 6.62279 4.90381 5.40381C6.12279 4.18482 7.77609 3.5 9.5 3.5ZM9.5 5.5C7 5.5 5 7.5 5 10C5 12.5 7 14.5 9.5 14.5C12 14.5 14 12.5 14 10C14 7.5 12 5.5 9.5 5.5Z" fill="black" />
                            </g>
                        </svg>

                        <label className="country-popup__input-label" htmlFor="question">
                            Search country
                        </label>

                        <input
                            onClick={() => handleFocused('search')}
                            onFocus={() => handleFocused('search')}
                            onBlur={() => handleUnfocused('search')}
                            name="search"
                            type={'text'}
                            inputMode={'text'}
                            className={`country-popup__input`}
                            value={serachValue}
                            onChange={handleSearchChange}

                        />
                    </div>
                    <div className='country-popup__items' id='country-items'>
                        {countries.filter((item) => {
                            if (!serachValue) return true
                            else {
                                if (item.name.toLowerCase().startsWith(serachValue.toLowerCase())) return true
                                else return false
                            }

                        }).length > 0 ?
                            countries.filter((item) => {
                                if (!serachValue) return true
                                else {
                                    if (item.name.toLowerCase().startsWith(serachValue.toLowerCase())) return true
                                    else return false
                                }

                            }).map((item, i) => (
                                <div key={`country-popup__item${i}`} className={`country-popup__item ${selectedCountry === item.name ? 'country-popup__item_selected' : ''}`} onClick={() => {

                                    if (selectedCountry === item.name) {
                                        setSelectedCountry('')
                                    } else {
                                        setSelectedCountry(item.name)
                                    }
                                }}>
                                    <div className='country-popup__item-flag-container' dangerouslySetInnerHTML={item.svg} />
                                    <p className={`country-popup__item-name ${selectedCountry === item.name ? 'country-popup__item-name_selected' : ''}`}>{item.name}</p>
                                </div>
                            )) :
                            <div className={`country-popup__item`}>
                                <div className='country-popup__item-flag-container' />
                                <p className={`country-popup__item-name`}>Nothing found</p>
                            </div>
                        }
                    </div>
                    <div className={`country-popup__submit ${selectedCountry ? 'country-popup__submit_visible' : ''}`}>
                        <div className={`country-popup__submit-btn ${selectedCountry ? 'country-popup__submit-btn_visible' : ''}`}
                            onClick={() => {
                                if (selectedCountry) {
                                    setCountryValue(selectedCountry)
                                    handleClose()
                                }
                            }}>
                            <p className={`country-popup__submit-btn-text`}>Save</p>
                        </div>
                    </div>

                </div>


            </div>
            <div className={`country-popup__background ${countryPopupOpened ? 'country-popup__background_active' : ''}`} onClick={handleClose}>

            </div>
        </div>

    );
}

export default CountryPopup;