import moment from "moment-timezone";
import { MAIN_URL } from "../utils/constants";

class MainApi {
  constructor({ baseUrl }) {
    this._BASE_URL = baseUrl
  }


  refreshJWT() {
    return fetch(`${this._BASE_URL}/users/refresh-jwt`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
        // 'csrf_access_token': Cookies.get('csrf_access_token'),
        // 'csrf_refresh_token': Cookies.get('csrf_refresh_token')
      },
    }).then(this._checkResponse)
  };

  login({ email, password }) {
    return fetch(`${MAIN_URL}/users/login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "email": email,
        "password": password,
      })
    }).then(this._checkResponse)
  };

  logout() {
    return fetch(`${MAIN_URL}/users/logout`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.logout,
      params: {}
    }))
  };

  signUpFirstPart({ email, password }) {
    return fetch(`${MAIN_URL}/users/signup`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "email": email,
        "password": password,
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.signUpFirstPart,
      params: { email, password }
    }))
  };

  checkEmailCode({ code }) {
    return fetch(`${MAIN_URL}/users-change-email/check-code`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "code": code,
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.checkEmailCode,
      params: { code }
    }))
  };

  checkEmailValid({ email }) {
    let params = {}
    if (email) params.email = email
    return fetch(`${MAIN_URL}/users-change-email/validation?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(this._checkResponse)
  };

  sendCodeToEmail({ email }) {
    return fetch(`${MAIN_URL}/users-change-email/main`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "email": email,
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.sendCodeToEmail,
      params: { email }
    }))
  };

  signUpSecondPart({ country, gender, name, last_name, date_of_birth, weight, height }) {
    return fetch(`${MAIN_URL}/users/signup-part-two`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "country": country,
        "gender": gender,
        "name": name,
        "last_name": last_name,
        "date_of_birth": date_of_birth,
        "weight": {
          "type": weight.selector,
          "value": weight.integer
        },
        "height": {
          "type": height.selector,
          "value": height.integer
        }
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.signUpSecondPart,
      params: { country, gender, name, last_name, date_of_birth, weight, height }
    }))
  };

  getUser() {

    return fetch(`${MAIN_URL}/users/get-me`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.getUser,
      params: {}
    }))
  };

  getSyndromes({ last_id, limit }) {
    let params = {}
    if (last_id) params.last_id = last_id
    if (limit) params.limit = limit

    return fetch(`${MAIN_URL}/syndromes/get-mine-syndromes?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.getSyndromes,
      params: { last_id, limit }
    }))
  };


  searchSyndromes({ text, limit }) {
    let params = {}
    if (text) params.text = text
    if (limit) params.limit = limit

    return fetch(`${MAIN_URL}/search/syndrome?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.searchSyndromes,
      params: { text, limit }
    }))
  };

  addSyndrome({ syndrome_id, data }) {


    return fetch(`${MAIN_URL}/syndromes/add-syndrome`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "syndrome_id": syndrome_id,
        "data": data,
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.addSyndrome,
      params: { syndrome_id, data }
    }))
  };

  editSyndrome({ syndrome_id, data, id }) {
    console.log({ syndrome_id, data, id })
    return fetch(`${MAIN_URL}/syndromes/edit-syndrome`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "syndrome_id": syndrome_id,
        "data": data,
        "_id": id
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.editSyndrome,
      params: { syndrome_id, data, id }
    }))
  };

  getUserSyndromeById({ syndrome_id }) {
    let params = {}
    if (syndrome_id) params.syndrome_id = syndrome_id
    return fetch(`${MAIN_URL}/syndromes/get-user-syndrome-by-id?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.getUserSyndromeById,
      params: { syndrome_id }
    }))
  };

  deleteSyndrome({ id }) {

    return fetch(`${MAIN_URL}/syndromes/delete-syndrome`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "_id": id
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.deleteSyndrome,
      params: { id }
    }))
  };


  searchSymptomes({ text, limit }) {
    let params = {}
    if (text) params.text = text
    if (limit) params.limit = limit

    return fetch(`${MAIN_URL}/search/symptom?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.searchSymptomes,
      params: { text, limit }
    }))
  };

  getUserSymptomeById({ symptom_id }) {
    let params = {}
    if (symptom_id) params.symptom_id = symptom_id
    return fetch(`${MAIN_URL}/symptoms/get-user-symptome-by-id?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.getUserSymptomeById,
      params: { symptom_id }
    }))
  };

  addSymptome({ symptom_id, data, user_syndrome_id }) {


    return fetch(`${MAIN_URL}/symptoms/add-symptom`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "symptom_id": symptom_id,
        "data": data,
        "user_syndrome_id": user_syndrome_id,
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.addSymptome,
      params: { symptom_id, data, user_syndrome_id }
    }))
  };


  getSymptomes({ last_id, limit }) {
    let params = {}
    if (last_id) params.last_id = last_id
    if (limit) params.limit = limit

    return fetch(`${MAIN_URL}/symptoms/get-mine-symptoms?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.getSymptomes,
      params: { last_id, limit }
    }))
  };

  editSymptome({ symptom_id, data, id, user_syndrome_id }) {
    console.log({ symptom_id, data, id })
    return fetch(`${MAIN_URL}/symptoms/edit-symptom`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "symptom_id": symptom_id,
        "data": data,
        "_id": id,
        "user_syndrome_id": user_syndrome_id
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.editSymptome,
      params: { symptom_id, data, id, user_syndrome_id }
    }))
  };

  deleteSymptome({ id }) {

    return fetch(`${MAIN_URL}/symptoms/delete-symptom`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "_id": id
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.deleteSymptome,
      params: { id }
    }))
  };



  addMedicine({ continued, name, type, dosage, dosage_type, dosage_days, food_time, frequency, days_list, intake_times, reason, user_syndrome_id }) {

    return fetch(`${MAIN_URL}/medicines/add-medicine`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "drug": {
          "name": name,
          "type": type,
          "dosage": dosage,
          "dosage_type": dosage_type,
          "dosage_days": dosage_days,
          "food_time": food_time,
          "schedule": continued ? {
            "frequency": frequency,
            "days_list": days_list,
            "intake_times": intake_times,
          } : null,
          "reason": reason
        },
        "user_syndrome_id": user_syndrome_id
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.addMedicine,
      params: { continued, name, type, dosage, dosage_type, dosage_days, food_time, frequency, days_list, intake_times, reason, user_syndrome_id }
    }))
  };


  getDrugs({ still_taking, last_id, limit }) {
    let params = {}
    if (still_taking === true || still_taking === false) params.still_taking = still_taking
    if (last_id) params.last_id = last_id
    if (limit) params.limit = limit

    return fetch(`${MAIN_URL}/medicines/get-mine-drugs?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.getDrugs,
      params: { still_taking, last_id, limit }
    }))
  };

  editMedicine({ name, type, dosage, dosage_type, dosage_days, food_time, user_syndrome_id, id }) {

    return fetch(`${MAIN_URL}/medicines/edit-medicine`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "name": name,
        "type": type,
        "dosage": dosage,
        "dosage_type": dosage_type,
        "dosage_days": dosage_days,
        "food_time": food_time,
        "user_syndrome_id": user_syndrome_id,
        "_id": id,
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.editMedicine,
      params: { name, type, dosage, dosage_type, dosage_days, food_time, user_syndrome_id, id }
    }))
  };

  editReasons({ reason, id }) {

    console.log(reason)
    return fetch(`${MAIN_URL}/medicines/edit-medicine-reasons`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "reason": reason,
        "_id": id,
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.editReasons,
      params: { reason, id }
    }))
  };


  getDrugIntakes({ medicine_id, last_id, last_date, limit }) {
    let params = {}
    if (medicine_id) params.medicine_id = medicine_id
    if (last_id) params.last_id = last_id
    if (last_date) params.last_date = last_date
    if (limit) params.limit = limit

    return fetch(`${MAIN_URL}/intake-time/get-mine-intake-times?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.getDrugIntakes,
      params: { medicine_id, last_id, last_date, limit }
    }))
  };

  addIntakeToDrug({ medicine_id, intake_time }) {


    return fetch(`${MAIN_URL}/intake-time/add-intake-time`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "medicine_id": medicine_id,
        "intake_time": intake_time,
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.addIntakeToDrug,
      params: { medicine_id, intake_time }
    }))
  };



  editIntakeInDrug({ medicine_id, intake_time, id }) {


    return fetch(`${MAIN_URL}/intake-time/edit-intake-time`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "_id": id,
        "medicine_id": medicine_id,
        "intake_time": intake_time,
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.editIntakeInDrug,
      params: { medicine_id, intake_time, id }
    }))
  };

  editIntakeDaysList({ medicine_id, days_list }) {


    return fetch(`${MAIN_URL}/intake-time/edit-specific-days-list`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "medicine_id": medicine_id,
        "days_list": days_list,
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.editIntakeDaysList,
      params: { medicine_id, days_list }
    }))
  };

  setNewIntakeTime({ frequency, medicine_id, days_list, intake_times }) {


    return fetch(`${MAIN_URL}/intake-time/set-new-intake-time`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "schedule": {
          "frequency": frequency,
          "days_list": days_list,
          "intake_times": intake_times,
        },
        "medicine_id": medicine_id,
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.setNewIntakeTime,
      params: { frequency, medicine_id, days_list, intake_times }
    }))
  };


  deleteIntakeInDrug({ medicine_id, id }) {


    return fetch(`${MAIN_URL}/intake-time/delete-intake-time`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "_id": id,
        "medicine_id": medicine_id,
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.deleteIntakeInDrug,
      params: { medicine_id, id }
    }))
  };

  deleteDrug({ drug_id }) {


    return fetch(`${MAIN_URL}/medicines/delete-medicine`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "drug_id": drug_id,
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.deleteDrug,
      params: { drug_id }
    }))
  };


  searchClinic({ text, limit }) {
    let params = {}
    if (text) params.text = text
    if (limit) params.limit = limit

    return fetch(`${MAIN_URL}/search/clinic?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.searchClinic,
      params: { text, limit }
    }))
  };

  searchDoctor({ text, limit, clinic_id }) {
    let params = {}
    if (text) params.text = text
    if (limit) params.limit = limit
    if (clinic_id) params.clinic_id = clinic_id

    return fetch(`${MAIN_URL}/search/doctor?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.searchDoctor,
      params: { text, limit, clinic_id }
    }))
  };


  setClinicalData({ clinic_id, doctor_id }) {


    return fetch(`${MAIN_URL}/users/set-clinical-data`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "clinic_id": clinic_id,
        "doctor_id": doctor_id,
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.setClinicalData,
      params: { clinic_id, doctor_id }
    }))
  };


  setHereditarySyndromes({ members }) {


    return fetch(`${MAIN_URL}/hereditary-syndromes/set-hereditary-syndrome`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "members": members,
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.setHereditarySyndromes,
      params: { members }
    }))
  };

  getHereditarySyndromes() {

    return fetch(`${MAIN_URL}/hereditary-syndromes/get-mine-hereditary-syndromes`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      }
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.getHereditarySyndromes,
      params: {}
    }))
  };


  setInitFunctionalQuestionaire({ school_or_work }) {


    return fetch(`${MAIN_URL}/questionnaire/write-init-questionnaire/functional`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "school_or_work": school_or_work,
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.setInitFunctionalQuestionaire,
      params: { school_or_work }
    }))
  };

  setInitSocialQuestionaire({ aggression, friendship, isolation, family_relations }) {


    return fetch(`${MAIN_URL}/questionnaire/write-init-questionnaire/social`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "aggression": aggression,
        "friendship": friendship,
        "isolation": isolation,
        "family_relations": family_relations
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.setInitSocialQuestionaire,
      params: { aggression, friendship, isolation, family_relations }
    }))
  };

  setResearchId({ research_id }) {


    return fetch(`${MAIN_URL}/users/set-clinical-research-id`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "research_id": research_id,
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.setResearchId,
      params: { research_id }
    }))
  };

  gerInitQuestionaire({ type }) {
    let params = {}
    if (type) params.type = type


    return fetch(`${MAIN_URL}/questionnaire/get-init-questionnaire?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.gerInitQuestionaire,
      params: { type }
    }))
  };


  getAppVersion() {
    return fetch(`${MAIN_URL}/admin/get-app-version`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(this._checkResponse)
  };

  getTodayIntakes({ limit, last_id }) {
    let params = {}
    if (limit) params.limit = limit
    if (last_id) params.last_id = last_id

    return fetch(`${MAIN_URL}/intakes/get-mine-intakes?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.getTodayIntakes,
      params: { limit, last_id }
    }))
  };

  getMissedIntakes({ limit, last_id }) {
    let params = {}
    if (limit) params.limit = limit
    if (last_id) params.last_id = last_id

    return fetch(`${MAIN_URL}/intakes/get-intakes-missed-history?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.getMissedIntakes,
      params: { limit, last_id }
    }))
  };

  takeOrSkipIntake({ medicine_id, medicine_intake_id, taken, time, reason }) {


    return fetch(`${MAIN_URL}/intakes/intake`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "medicine_id": medicine_id,
        "medicine_intake_id": medicine_intake_id,
        "taken": taken,
        "time": time !== undefined ? time : null,
        "reason": reason !== undefined ? reason : null,
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.takeOrSkipIntake,
      params: { medicine_id, medicine_intake_id, taken, time, reason }
    }))
  };

  getIntakesHistory({ limit, last_id, user_syndrome_id, last_date }) {
    let params = {}
    if (limit) params.limit = limit
    if (last_id) params.last_id = last_id
    if (user_syndrome_id) params.user_syndrome_id = user_syndrome_id
    if (last_date) params.last_date = last_date

    return fetch(`${MAIN_URL}/intakes/get-intakes-history?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.getIntakesHistory,
      params: { limit, last_id, user_syndrome_id, last_date }
    }))
  };

  getWeeklyHistory() {


    return fetch(`${MAIN_URL}/intakes/get-intakes-weekly-medication`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.getWeeklyHistory,
      params: {}
    }))
  };


  editIntake({ medicine_id, medicine_intake_id, taken, time, reason, frequency }) {


    return fetch(`${MAIN_URL}/intakes/edit-intake`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "medicine_id": medicine_id,
        "medicine_intake_id": medicine_intake_id,
        "taken": taken,
        "time": time !== undefined ? time : null,
        "reason": reason !== undefined ? reason : null,
        "frequency": frequency
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.editIntake,
      params: { medicine_id, medicine_intake_id, taken, time, reason, frequency }
    }))
  };


  editPersonalData({ data }) {


    return fetch(`${MAIN_URL}/users/edit-personal-data`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        ...data,
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.editPersonalData,
      params: { data }
    }))
  };

  answerQuestionaire({ seizures, questionnaire }) {


    return fetch(`${MAIN_URL}/questionnaire/write-seizures-questionnaire`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        'seizures': seizures,
        'questionnaire': questionnaire,
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.answerQuestionaire,
      params: { seizures, questionnaire }
    }))
  };

  forgotPasswordStep1() {


    return fetch(`${MAIN_URL}/users-forgot-password/main`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.forgotPasswordStep1,
      params: {}
    }))
  };

  forgotPasswordStep2({ password, secret }) {


    return fetch(`${MAIN_URL}/users-forgot-password/change-password`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "password": password,
        "secret": secret,
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.forgotPasswordStep2,
      params: { password }
    }))
  };

  checkPasswordCode({ code }) {
    return fetch(`${MAIN_URL}/users-forgot-password/check-code`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'tz': moment.tz.guess()
      },
      body: JSON.stringify({
        "code": code,
      })
    }).then((res) => mainApi._checkResponseWithCookies({
      res: res,
      func: mainApi.checkPasswordCode,
      params: { code }
    }))
  };

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    }
    else {
      var statusCode = res.status
      return res.json().then((res) => {
        return Promise.reject({
          statusCode: statusCode,
          message: res.message,
          detail: res.detail
        })
      })
    }
    ;
  }

  _checkResponseWithCookies({ res, func, params }) {
    if (res.ok) {
      return res.json();
    }
    else {
      var statusCode = res.status
      return res.json().then((res) => {
        if (statusCode === 422 && res.detail === 'Signature has expired') {
          console.log('ss')
          return mainApi.refreshJWT()
            .then((res) => {
              return func(params)
            })
            .catch((err) => {
              return Promise.reject(err)
            })


        } else {
          return Promise.reject({
            statusCode: statusCode,
            message: res.message,
            detail: res.detail
          })
        }

      })
    }
  }

}

const mainApi = new MainApi({
  baseUrl: MAIN_URL
  // baseUrl: 'http://localhost:3003'
});
export default mainApi
