import { useEffect, useRef } from "react";

import "./MedicineInfoPopup.css";

import pillIcon from '../../assets/images/main/med_type/pill.png'
import injectionIcon from '../../assets/images/main/med_type/injection.png'
import syrupIcon from '../../assets/images/main/med_type/syrup.png'
import sprayIcon from '../../assets/images/main/med_type/spray.png'
import tabletIcon from '../../assets/images/main/med_type/tablet.png'
import dropsIcon from '../../assets/images/main/med_type/drops.png'
import ointmentIcon from '../../assets/images/main/med_type/ointment.png'
import procedureIcon from '../../assets/images/main/med_type/procedure.png'
import moment from "moment";



function MedicineInfoPopup({ isPopupOpen, setPopupOpen, selectedItem, setSelectedItem, handleEdit }) {
  // console.log({ selectedItem })
  const overlay = useRef();

  function handleClose() {
    setPopupOpen(false);
    setTimeout(() => {
      setSelectedItem(null)
      
  }, 300);
  }

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        setPopupOpen(false);
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });

  function handleEditToSkip() {
    console.log('skip')
    handleEdit({card: selectedItem, type: 'skip'})
    handleClose()
  }

  function handleEditToTake() {
    console.log('take')
    handleEdit({card: selectedItem, type: 'take'})
    handleClose()
  }

  return (
    <div className={`medicine-info-popup ${isPopupOpen ? "medicine-info-popup_opened" : ""}`}>
      <div className="medicine-info-popup__container">
        <svg className="medicine-info-popup__close" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClose}>
          <path className="medicine-info-popup__close-fill" d="M2.98555 13.0263C3.17461 13.2155 3.4304 13.3219 3.69701 13.3219C3.96363 13.3219 4.21942 13.2155 4.40848 13.0263L7.98126 9.37669L11.554 13.0263C11.8171 13.2914 12.2008 13.3949 12.5604 13.2979C12.9199 13.2009 13.2009 12.9179 13.2972 12.5557C13.3935 12.1936 13.2907 11.8071 13.0275 11.5421L9.40421 7.94341L13.0275 4.34471C13.25 4.08314 13.3257 3.72558 13.2287 3.39526C13.1318 3.06494 12.8751 2.80641 12.5472 2.70874C12.219 2.61105 11.8642 2.68729 11.6044 2.91142L7.98125 6.51012L4.40847 2.91142C4.14879 2.68729 3.79381 2.61105 3.46587 2.70874C3.13792 2.80642 2.88126 3.06493 2.78429 3.39526C2.6873 3.72558 2.763 4.08314 2.98551 4.34471L6.55829 7.94341L2.98551 11.5421C2.78175 11.7342 2.66602 12.003 2.66602 12.2841C2.66602 12.5654 2.78175 12.8339 2.98551 13.0262L2.98555 13.0263Z" fill="white" />
        </svg>
        {selectedItem ?
          <div className="medicine-info-popup__content">
            {selectedItem.medicine_object.type === 'pill' && <img className="medicine-info-popup__icon" src={pillIcon} alt='' />}
            {selectedItem.medicine_object.type === 'injection' && <img className="medicine-info-popup__icon" src={injectionIcon} alt='' />}
            {selectedItem.medicine_object.type === 'syrup' && <img className="medicine-info-popup__icon" src={syrupIcon} alt='' />}
            {selectedItem.medicine_object.type === 'spray' && <img className="medicine-info-popup__icon" src={sprayIcon} alt='' />}
            {selectedItem.medicine_object.type === 'tablet' && <img className="medicine-info-popup__icon" src={tabletIcon} alt='' />}
            {selectedItem.medicine_object.type === 'drops' && <img className="medicine-info-popup__icon" src={dropsIcon} alt='' />}
            {selectedItem.medicine_object.type === 'ointment' && <img className="medicine-info-popup__icon" src={ointmentIcon} alt='' />}
            {selectedItem.medicine_object.type === 'procedure' && <img className="medicine-info-popup__icon" src={procedureIcon} alt='' />}
            <p className="medicine-info-popup__name">{selectedItem.medicine_object.name}</p>
            <div className="medicine-info-popup__info">
              <svg className="medicine-info-popup__info-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22 3H19V1H17V3H7V1H5V3H2V23H22V3ZM20 21H4V8H20V21Z" fill="#006B58" />
              </svg>
              <p className="medicine-info-popup__info-text">Scheduled for {selectedItem && moment(`${selectedItem.utc_date}+00:00`).format('HH:mm')}, {selectedItem && moment(`${selectedItem.utc_date}+00:00`).diff(moment(), 'days') >= 2 ? moment(`${selectedItem.utc_date}+00:00`).format('DD.MM') : moment(`${selectedItem.utc_date}+00:00`).calendar().split(' ')[0].toLowerCase()}</p>
            </div>
            <div className="medicine-info-popup__info">
              <svg className="medicine-info-popup__info-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 17H13V11H11V17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 9H13V7H11V9Z" fill="#006B58" />
                <path d="M11 7H13V9H11V7ZM11 11H13V17H11V11Z" fill="#006B58" />
                <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="#006B58" />
              </svg>

              <p className="medicine-info-popup__info-text">{selectedItem && selectedItem.medicine_object.dosage} {selectedItem && selectedItem.medicine_object.dosage_type}, take {selectedItem && selectedItem.dose_amount} {selectedItem && selectedItem.medicine_object.food_time} food</p>
            </div>
            <div className="medicine-info-popup__btns">
              <button className="medicine-info-popup__btn" onClick={handleClose}>
                <p className="medicine-info-popup__btn-text">Close</p>
              </button>
              <button className={`medicine-info-popup__btn`} onClick={() => {
                if (selectedItem) {
                  if (selectedItem.status === 'intaked') {
                    handleEditToSkip()
                  } else {
                    handleEditToTake()
                  }
                }
              }}>
                <p className="medicine-info-popup__btn-text">{selectedItem ? selectedItem.status === 'intaked' ? 'Un-take' : 'Take' : ''}</p>
              </button>
            </div>
          </div>
          : <></>}
      </div>
      <div
        className="medicine-info-popup__overlay"
        ref={overlay}
        onTouchStart={handleClose}
      />
    </div>
  );
}

export default MedicineInfoPopup;
