import { useState, useEffect, useRef } from "react";
import Picker from "react-mobile-picker";
import { SELECT_ARROW_ICON, CLOSE_ICON } from "../../assets/utils/icons";
import "./SelectInput.css";

function SelectInput({
  type2 = "list",
  type,
  label,
  value,
  name,
  isRequired,
  selectList = [],
  popupTitle,
  onSelect,
  countryPopupOpened,
  setCountryPopupOpened,
  onPickerChange,
  separator,
  separator2,
  optionGroups,
  valueGroups,

}) {
  const overlay = useRef();
  const [isFocuced, setIsFocuced] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        closePopup(value);
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });

  const [scrollWindowPosition, setScrollWindowPosition] = useState(0);
  const [popupScrollPosition, setPopupScrollPosition] = useState(0);

  const handleWindowScroll = () => {
    const position = window.pageYOffset;

    setScrollWindowPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, []);



  useEffect(() => {
    if (isPopupOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.top = `-${scrollWindowPosition}px`;
      setPopupScrollPosition(scrollWindowPosition)
      setTimeout(() => {
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
        document.body.style.height = '100%';
      }, 10);

      console.log(window.pageYOffset)

    } else {
      document.body.style.overflow = 'unset';
      document.body.style.position = 'unset';
      document.body.style.width = 'unset';
      document.body.style.height = 'unset';
      document.body.style.top = `unset`;
      console.log(popupScrollPosition)
      window.scrollTo(0, popupScrollPosition)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopupOpen]);

  function handleFocused() {
    setIsFocuced(true);
  }

  function handleUnfocused(value) {
    Boolean(value) ? setIsFocuced(true) : setIsFocuced(false);
  }

  function openPopup() {
    setPopupOpen(true);
    handleFocused();
  }

  function closePopup(value) {
    setPopupOpen(false);
    handleUnfocused(value);
  }

  function handleSelect(name, value, type) {
    onSelect(name, value, type);
    handleUnfocused(value);

    if (type2 === "picker") {
      closePopup(value);
    }
  }

  return (
    <div className="select-input">
      <div className={`select-input__input-box select-input__input-box_type_${name} ${
          isFocuced || Boolean(value) || isPopupOpen || countryPopupOpened

            ? "select-input__input-box_focused"
            : ""
        }`}
        onClick={() =>
          name !== "country" ? openPopup() : setCountryPopupOpened(true)
        }
      >
        <label className="select-input__label" htmlFor={name}>
          {label}
        </label>
        <input
          className={`select-input__input ${Boolean(value) ? "select-input__input_valid" : ""
            }`}
          id={name}
          name={name}
          type="text"
          value={name === 'diagnosis' ? value && value.text ? value.text : '' : value || ""}
          readOnly
          required={isRequired}
        />
        <div

          className={`select-input__select-icon ${
            isPopupOpen || countryPopupOpened
              ? "select-input__select-icon_reverse"
              : ""
          }`}

        >
          {SELECT_ARROW_ICON}
        </div>
      </div>

      <div 
      className={`select-input__popup select-input__popup_type_${
          type2 === "picker" ? type : name
        } ${isPopupOpen ? "select-input__popup_opened" : ""}`}
      >
        <div className="select-input__popup-container">
          {type2 === "picker" ? (
            <button
              className="select-input__cancel-button"
              type="button"
              onClick={() => closePopup(value)}
            >
              Cancel
            </button>
          ) : (
            <button
              className="select-input__close-button"
              type="button"
              aria-label="Close popup"
              onClick={() => closePopup(value)}
            >
              {CLOSE_ICON}
            </button>
          )}
          <div className="select-input__popup-content">
            <p className={`select-input__popup-title ${type2 === "picker"? 'select-input__popup-title_type_picker' : ''}`}>{popupTitle}</p>

            {type2 === "list" ? (
              <ul className="select-input__popup-list">
                {selectList.map((item, i) => (
                  <li
                  className={`select-input__popup-item ${name === 'diagnosis' ? value && value.text && item && item.text && item.text === value.text ? 'select-input__popup-item_selected' : '' : value === item ? "select-input__popup-item_selected" : ""
                    }`}
                  key={`select-input${i}`}
                  onClick={() => handleSelect(name, item, type)}
                >
                  <p className="select-input__popup-text">{name === 'diagnosis' ? item && item.text ? item.text : '' : item}</p>
                  </li>
                ))}
              </ul>
            ) : null}

            {type2 === "picker" ? (
              <div className="select-input__picker-input">
                <div className="select-input__picker-input-line" />
                {separator ? (
                  <p className="select-input__picker-separator select-input__picker-separator_type_left">
                    {separator}
                  </p>
                ) : null}
                {separator2 ? (
                  <p className="select-input__picker-separator select-input__picker-separator_type_right">
                    {separator2}
                  </p>
                ) : null}
                <div className="select-input__picker-input-container">
                  <Picker
                    optionGroups={optionGroups}
                    valueGroups={valueGroups}
                    onChange={onPickerChange}
                  />
                </div>
              </div>
            ) : null}

            <button
              className={`select-input__save-button ${
                type2 !== "picker" && !Boolean(value)
                  ? "select-input__save-button_disabled"
                  : ""
              }`}

              type="button"
              onClick={() =>
                type2 === "picker"
                  ? handleSelect(name, valueGroups)
                  : closePopup(value)
              }
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <div
        className="select-input__overlay"
        ref={overlay}
        onTouchStart={() => closePopup(value)}
      />
    </div>
  );
}

export default SelectInput;
