import { useEffect, useRef, useState } from "react";
import Input from "../Input/Input";
import "./ChangePassword.css";


import mainApi from "../../assets/api/mainApi";
import MiniPreloader from "../MiniPreloader/MiniPreloader";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { HIDDEN_PASSWORD_ICON, VISIBLE_PASSWORD_ICON } from "../../assets/utils/icons";

function ChangePassword({ currentUser, setCurrentUser }) {

    const [secret, setSecret] = useState('')
        // eslint-disable-next-line no-unused-vars
    const [submitError, setSubmitError] = useState('')
    const [passwords, setPasswords] = useState({
        newPassword: "",
        confirmPassword: "",
    });
    const [passValidity, setPassValidity] = useState({});
    const [isPassVisible, setPassVisible] = useState({
        newPassword: false,
        confirmPassword: false,
    });

    useEffect(() => {
        mainApi.forgotPasswordStep1()
            .then((res) => {
                setCurrentUser(res)
                console.log(res)
            })
            .catch((err) => {
                // switch (err.detail) {
                //     case 'Too Many Requests':
                //         setSubmitError('Too Many Requests')
                //         setTimeout(() => {
                //             setSubmitError('')
                //         }, 10000);
                //         break;

                //     case 'Email or phone are already registered':
                //         setSubmitError('This email is already registered')
                //         setTimeout(() => {
                //             setSubmitError('')
                //         }, 5000);
                //         break;

                //     case 'Email was not sent':
                //         setSubmitError('We were unable to send the code. Something is wrong with the specified email, check the spelling.')
                //         setTimeout(() => {
                //             setSubmitError('')
                //         }, 5000);
                //         break;

                //     case 'Email generation timeout not passed':
                //         setSubmitError('Too little time has passed since the last request, wait and try again')
                //         setTimeout(() => {
                //             setSubmitError('')
                //         }, 5000);
                //         break;

                //     default:
                //         if (err.detail) {
                //             setSubmitError(err.detail)
                //         }
                //         else {
                //             setSubmitError('Something went wrong')
                //         }
                //         setTimeout(() => {
                //             setSubmitError('')
                //         }, 5000);
                //         break;
                // }
                console.log(err)
            })
 // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const navigate = useNavigate()

    const [step, setStep] = useState(0)
   

    const [code, setCode] = useState({})
    const [codeValidity, setCodeValidity] = useState({});
    const [codeError, setCodeError] = useState('')



    // eslint-disable-next-line no-unused-vars
    const [submitPreloaderVisible, setSubmitPreloaderVisible] = useState(false)
    const [isNextBtnActive, setNextBtnActive] = useState(false)
    function handleStepBack() {
        switch (step) {
            case 0:
                navigate(-1)
                break;

            default:
                setStep(step + -1)
                break;
        }
    }

    function handleNextStep() {
        if (isNextBtnActive) {
            switch (step) {


                case 0:
                    setStep(step + 1)
                    break;

                case 1:

                    mainApi.forgotPasswordStep2({ password: passwords.newPassword, secret: secret })
                        .then((res) => {
                            console.log(res)
                            setStep(step + 1)
                            setCurrentUser(res)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                    break;
                case 2:
                    navigate(-1)
                    break;

                default:
                    break;
            }
        }

    }

    useEffect(() => {

        switch (step) {
            case 0:
                if (code && codeValidity.validState) {
                    setNextBtnActive(true)
                } else {
                    setNextBtnActive(false)
                }
                break;
            case 1:
                if (Object.values(passwords).some((el) => Boolean(el)) && Object.values(passValidity).length === Object.values(passwords).length && Object.values(passValidity).every((el) => el.validState)) {
                    setNextBtnActive(true)
                } else {
                    setNextBtnActive(false)
                }
                break;
            case 2:
                setNextBtnActive(true)
                break;

            default:
                setNextBtnActive(false)
                break;
        }

    }, [step, code, codeValidity, passwords, passValidity])


    const firstInput = useRef()



    function handleCodeChange(e, i) {
        setCodeError('')
        const input = e.target.value;
        const form = e.target.form;
        const index = Array.prototype.indexOf.call(form, e.target);
        console.log(input.length)
        if (input.length === 4) {
            setCode({
                0: input[0].toUpperCase(),
                1: input[1].toUpperCase(),
                2: input[2].toUpperCase(),
                3: input[3].toUpperCase(),
            });
            form.elements[3].focus();
        } else {
            if (code[i]) {
                setCode({ ...code, [i]: input[1].toUpperCase() });
                if (index !== 3) {
                    form.elements[index + 1].focus();
                }
            } else {
                setCode({ ...code, [i]: input[0].toUpperCase() });
                if (index !== 3) {
                    form.elements[index + 1].focus();
                }
            }

        }



    }



    function handleKeyDown(e) {
        const form = e.target.form;

        const index = Array.prototype.indexOf.call(form, e.target);
        console.log(e.keyCode)
        // if (e.keyCode === 13) {
        //     form.elements[index + 1].focus();
        //     e.preventDefault();
        // }
        if (e.keyCode === 8 || e.keyCode === 37) {
            // setCode({ ...code, [index]: '' });
            if (code[index]) {
                setCode({ ...code, [index]: '' });
            } else {
                if (index === 0) {
                    setCode({ ...code, [index]: '' });
                } else {
                    setCode({ ...code, [index]: '', [index - 1]: '' });
                    form.elements[index - 1].focus();
                }

            }

            e.preventDefault();
        }
    }
    function handleFormEnter(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    }



    // eslint-disable-next-line no-unused-vars
    const [timerValue, setTimerValue] = useState(0);
    const [timeLeft, setTimeLeft] = useState(0)




    useEffect(() => {
        if (currentUser) {
            const timer = setInterval(() => {
                setTimeLeft(Number(moment().format('X')) - Number(moment(`${currentUser.new_password.generated_utc}+00:00`).format('X')))
                clearInterval(timer)
            }, 1000);

        } else {
            setTimerValue(0)
        }

    }, [currentUser, timeLeft])


    const [isEmailResanding, setEmailResending] = useState(false)
    const [emailResendError, setEmailResendError] = useState('')
    function handleResendCode() {

        mainApi.forgotPasswordStep1()
            .then((res) => {
                console.log(res)
                setCurrentUser(res)
                setTimerValue(0)
            })
            .catch((err) => {
                setEmailResendError(err.detail)
                setTimeout(() => {
                    setEmailResendError('')
                }, 5000);
                console.log(err)
            })
            .finally(() => {
                setEmailResending(false)
            })
        // mainApi.getNewWappCode({ id: userId })
        //     .then((res) => {
        //         console.log(res)
        //         setTimerValue(0)
        //     })
        //     .catch((err) => {
        //         setTimerValue(0)
        //         console.log(err)
        //   

    }

    useEffect(() => {
        if (code[0] && code[1] && code[2] && code[3]) {

            console.log({ code: `${code[0] + code[1] + code[2] + code[3]}` })
            mainApi.checkPasswordCode({ code: `${code[0] + code[1] + code[2] + code[3]}` })
                .then((res) => {
                    console.log(res)
                    setSecret(res.secret)
                    setStep(prewStep => prewStep + 1)
                    setCodeValidity({
                        validState: true,
                    })
                })
                .catch((err) => {
                    console.log(err)
                    switch (err.detail) {
                        case 'Wrong code was sent':
                            setCodeError('Wrong code')
                            break;

                        default:
                            break;
                    }
                })

            // setCode({})
        } else {
            setCodeError('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code])


    function handlePasswordChange(evt) {
        const input = evt.target;
        const name = input.name;
        const value = input.value.replace(/\s/g, "");
        setPasswords({ ...passwords, [name]: value });

        if (!value) {
            setPassValidity({
                ...passValidity,
                [name]: {
                    errorMessage: "",
                    validState: false,
                },
            });
        } else if (name === "confirmPassword") {
            if (value === passwords.newPassword) {
                setPassValidity({
                    ...passValidity,
                    [name]: {
                        errorMessage: "",
                        validState: true,
                    },
                });
            } else {
                setPassValidity({
                    ...passValidity,
                    [name]: {
                        errorMessage: "The value must match the new password",
                        validState: false,
                    },
                });
            }
        } else {
            if (value.length >= 8) {
                setPassValidity({
                    ...passValidity,
                    [name]: {
                        errorMessage: "",
                        validState: true,
                    },
                });
            } else {
                setPassValidity({
                    ...passValidity,
                    [name]: {
                        errorMessage: "Minimum 8 characters",
                        validState: false,
                    },
                });
            }
        }
    }

    function handlePassVisibleClick(name) {
        setPassVisible({ ...isPassVisible, [name]: !isPassVisible[name] });
    }


    return (
        <div className="change-password">
            {step === 1 ?
                <div className="change-password__content">
                    <p className="change-password__title">Enter new email address you want to&nbsp;use for notifications</p>
                    <div className="change-password__input-container">
                        <Input
                            label="New password"
                            value={passwords.newPassword}
                            name="newPassword"
                            type={isPassVisible.newPassword ? "text" : "password"}
                            handleChange={handlePasswordChange}
                            isRequired={false}
                            icon={
                                isPassVisible.newPassword
                                    ? VISIBLE_PASSWORD_ICON
                                    : HIDDEN_PASSWORD_ICON
                            }
                            handleVisibility={handlePassVisibleClick}
                            error={passValidity.newPassword}
                        />
                    </div>
                    <div className="change-password__input-container">
                        <Input
                            label="Repeat new password"
                            value={passwords.confirmPassword}
                            name="confirmPassword"
                            type={isPassVisible.confirmPassword ? "text" : "password"}
                            handleChange={handlePasswordChange}
                            isRequired={false}
                            icon={
                                isPassVisible.confirmPassword
                                    ? VISIBLE_PASSWORD_ICON
                                    : HIDDEN_PASSWORD_ICON
                            }
                            handleVisibility={handlePassVisibleClick}
                            error={passValidity.confirmPassword}
                        />

                    </div>
                </div>

                : <></>}

            {step === 0 ?
                <div className="change-password__content">
                    <p className="change-password__title">Enter the code we sent you at <span className="change-password__title_span">{currentUser.email.toLowerCase()}</span> to retrieve your password</p>

                    <p className="change-password__error">{codeError}</p>
                    <form className="change-password__code" onKeyDown={handleFormEnter}>
                        <div className="change-password__code-element">
                            <input inputMode='numeric' ref={firstInput} onKeyDown={handleKeyDown} className={`change-password__code-input ${codeError ? 'change-password__code-input_error' : ''}`} placeholder="0" name="search" type="text" required value={code['0'] || ''} onChange={(e) => handleCodeChange(e, 0)} minLength="0" maxLength="4" autoComplete='off'></input>
                            <div className={`change-password__code-element-line ${code[0] ? 'change-password__code-element-line_typen' : ''} ${codeError ? 'change-password__code-element-line__error' : ''}`}></div>
                        </div>
                        <div className="change-password__code-element">
                            <input inputMode='numeric' onKeyDown={handleKeyDown} className={`change-password__code-input ${codeError ? 'change-password__code-input_error' : ''}`} placeholder="0" name="search" type="text" required value={code['1'] || ''} onChange={(e) => handleCodeChange(e, 1)} minLength="0" maxLength="2" autoComplete='off'></input>
                            <div className={`change-password__code-element-line ${code[1] ? 'change-password__code-element-line_typen' : ''} ${codeError ? 'change-password__code-element-line__error' : ''}`}></div>
                        </div>
                        <div className="change-password__code-element">
                            <input inputMode='numeric' onKeyDown={handleKeyDown} className={`change-password__code-input ${codeError ? 'change-password__code-input_error' : ''}`} placeholder="0" name="search" type="text" required value={code['2'] || ''} onChange={(e) => handleCodeChange(e, 2)} minLength="0" maxLength="2" autoComplete='off'></input>
                            <div className={`change-password__code-element-line ${code[2] ? 'change-password__code-element-line_typen' : ''} ${codeError ? 'change-password__code-element-line__error' : ''}`}></div>
                        </div>
                        <div className="change-password__code-element">
                            <input inputMode='numeric' onKeyDown={handleKeyDown} className={`change-password__code-input ${codeError ? 'change-password__code-input_error' : ''}`} placeholder="0" name="search" type="text" required value={code['3'] || ''} onChange={(e) => handleCodeChange(e, 3)} minLength="0" maxLength="2" autoComplete='off'></input>
                            <div className={`change-password__code-element-line ${code[3] ? 'change-password__code-element-line_typen' : ''} ${codeError ? 'change-password__code-element-line__error' : ''}`}></div>
                        </div>
                    </form>
                    <p className="change-password__submit-error">{submitError}</p>
                    <p className="change-password__not-recive">Did not recieve the code?</p>
                    {/* {timeLeft} */}
                    {isEmailResanding ?
                        <div className='change-password__code-resend-preloader'>
                            <MiniPreloader />
                        </div>
                        :
                        emailResendError ?
                            <p className="change-password__code-resend-error">{emailResendError}</p>
                            :
                            <>
                                {61 - Number(timeLeft) <= 0 ?
                                    <p className="change-password__code-resend change-password__code-resend_active" onClick={() => {
                                        if (!isEmailResanding) {
                                            setEmailResending(true)
                                            handleResendCode()
                                        }

                                    }}>Send again</p>
                                    :
                                    <p className="change-password__code-resend">Send again in {61 - timeLeft} sec</p>
                                }
                            </>
                    }
                </div>

                : <></>}

            {step === 2 ?
                <div className="change-password__final">

                    <svg className="change-password__final-icon" width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M34.037 21.0667C37.0156 17.8446 36.8169 12.7995 33.5952 9.82176C30.3734 6.8431 25.3288 7.041 22.3502 10.2628C19.7152 13.1138 19.5706 17.3878 21.8029 20.3985L9.06969 34.1727C8.76316 34.5039 8.78366 35.0199 9.1153 35.3257L10.089 36.2267C10.4198 36.532 10.9359 36.5119 11.242 36.1807L12.1151 35.2364L14.1007 37.0712C14.432 37.3778 14.948 37.3577 15.2549 37.0264L18.3901 33.6342C18.6966 33.3033 18.6761 32.7869 18.3453 32.4812L16.3589 30.6456L23.9748 22.4074C27.1516 24.3965 31.4009 23.918 34.0363 21.0673L34.037 21.0667ZM25.3478 13.033C26.798 11.4633 29.256 11.3666 30.8265 12.8173C32.3953 14.2687 32.492 16.7263 31.0414 18.296C29.5899 19.8657 27.1324 19.9615 25.5631 18.5109C23.9922 17.0599 23.8959 14.6027 25.3478 13.033L25.3478 13.033Z" fill="#006B58" />
                    </svg>

                    <p className="change-password__final-text">Password has been<br />successfuly changed</p>
                </div>
                : <></>}

            <div className='change-password__step-btns'>
                {step !== 2 ?
                    <div className='change-password__step-btn-back' onClick={handleStepBack}>
                        <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 1L1.61152 5.82733C1.26477 6.20875 1.26477 6.79125 1.61152 7.17267L6 12" stroke="#191C1B" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                    </div>
                    : <></>
                }


                <div className={`change-password__step-btn-next ${isNextBtnActive ? 'change-password__step-btn-next_active' : ''}`}
                    onClick={handleNextStep}>
                    {submitPreloaderVisible ?
                        <MiniPreloader isLinkColor={true} />
                        :
                        <>
                            {step === 2 ?
                                <p className='change-password__step-btn-next-text'>Done</p>
                                :
                                <p className='change-password__step-btn-next-text'>Next</p>
                            }
                        </>

                    }

                </div>


            </div>
        </div>
    );
}

export default ChangePassword;
