import MiniPreloader from "../../../MiniPreloader/MiniPreloader";
import SearchCard from "../SearchCard/SearchCard";
import "./SearchPopup.css";

function SearchPopup({
  searchList,
  searchValue,
  value,
  onSelect,
  isSearchPopupVisible,
  searchHints,
  isPreloaderVisible,
}) {
  return (
    <div
      className={`search-popup ${isSearchPopupVisible && searchValue ? "search-popup_visible" : ""
        }`}
    >
      <ul className="search-popup__content">
        {isPreloaderVisible ?
          <div className="search-popup__preloader">
            <MiniPreloader />
          </div>
          :


          searchHints && searchHints.length > 0 ?
            searchHints.map((item, i) => (
              <SearchCard
                type={
                  typeof value !== "string" && Boolean(value)
                    ? null
                    : value === item
                      ? "selected"
                      : "search"
                }
                item={{ disease: item, _id: item._id, }}
                {...{ onSelect }}
                keyValue={`search-item-card${item._id}${item.text}${i}`}
                key={`search-item${item._id}${item.text}${i}`}
              />
            ))
            :
            <div className="search-popup__not-found-item">
              <p className="search-popup__not-found-text">Nothing found</p>
            </div>
        }
      </ul>
    </div>
  );
}

export default SearchPopup;
