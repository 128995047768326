import React, { useState } from 'react';
import Picker from 'react-mobile-picker';


import './Weight.css';
import '../Register.css'
import InputHeading from '../InputsTitle/InputHeading';
import useDidMountEffect from '../../../assets/utils/useDidMountEffect';


const lbsInKg = 2.204


function Weight({ weightValueGroups, setWeightValueGroups, weightDefValueGroups }) {

    function range(a, b, c) { c = []; while (a--) c[a] = a + b; return c };

    const [optionGroups, setOptionGroups] = useState({
        integer: weightDefValueGroups && weightDefValueGroups.selector === 'kg' ? range(250 - 4, 5) : range(550 - 9, 10),
        //decimal: range(9 + 1, 0),
        selector: ['lbs', 'kg']
    })

    useDidMountEffect(() => {
        console.log('render')
        console.log({
            int1: weightDefValueGroups.integer,
            int2: weightValueGroups.integer,
            //dec1: weightDefValueGroups.decimal,
            //dec2: weightValueGroups.decimal,
        })
        if (weightDefValueGroups && weightValueGroups && (weightDefValueGroups.integer !== weightValueGroups.integer || /*weightDefValueGroups.decimal !== weightValueGroups.decimal ||*/ weightDefValueGroups.selector !== weightValueGroups.selector)) {

            if (weightValueGroups.selector === 'kg') {
                setOptionGroups(prewOptionsGroup => ({
                    ...prewOptionsGroup,
                    integer: range(250 - 4, 5),
                    //decimal: range(9 + 1, 0),
                }))
                setWeightValueGroups(prewValueGroups => ({
                    ...prewValueGroups,
                    integer: Math.floor(prewValueGroups.integer / lbsInKg) < 5 ? 5 : Math.floor(prewValueGroups.integer / lbsInKg) > 250 ? 250 : Math.floor(prewValueGroups.integer / lbsInKg),
                    //integer: Math.floor((prewValueGroups.integer + (prewValueGroups.decimal / 10)) / lbsInKg) < 5 ? 5 : Math.floor((prewValueGroups.integer + (prewValueGroups.decimal / 10)) / lbsInKg) > 250 ? 250 : Math.floor((prewValueGroups.integer + (prewValueGroups.decimal / 10)) / lbsInKg),
                    //decimal: Math.trunc((((prewValueGroups.integer + (prewValueGroups.decimal / 10)) / lbsInKg) - Math.trunc((prewValueGroups.integer + (prewValueGroups.decimal / 10)) / lbsInKg)) * 10) + 1,
                    selector: 'kg',
                }))

            } else {
                setOptionGroups(prewOptionsGroup => ({
                    ...prewOptionsGroup,
                    integer: range(550 - 9, 10),
                    //decimal: range(9 + 1, 0),
                }))
                setWeightValueGroups(prewValueGroups => ({
                    ...prewValueGroups,
                    integer: Math.floor(prewValueGroups.integer * lbsInKg) < 10 ? 10 : Math.floor(prewValueGroups.integer * lbsInKg) > 550 ? 550 : Math.floor(prewValueGroups.integer * lbsInKg),
                    //integer: Math.floor((prewValueGroups.integer + (prewValueGroups.decimal / 10)) * lbsInKg) < 10 ? 10 : Math.floor((prewValueGroups.integer + (prewValueGroups.decimal / 10)) * lbsInKg) > 550 ? 550 : Math.floor((prewValueGroups.integer + (prewValueGroups.decimal / 10)) * lbsInKg),
                    //decimal: Math.trunc((((prewValueGroups.integer + (prewValueGroups.decimal / 10)) * lbsInKg) - Math.trunc((prewValueGroups.integer + (prewValueGroups.decimal / 10)) * lbsInKg)) * 10) - 1,
                    selector: 'lbs',
                }))
            }
        }

    }, [weightValueGroups.selector, weightDefValueGroups])


    function handleChange(name, value) {
        setWeightValueGroups(prewValueGroups => ({
            ...prewValueGroups,
            [name]: value
        }))
    }

    return (
        <div className="weight">
            <InputHeading title={'Enter your weight '} subtitle={''} />
            <div className="weight__picker-input">
                <div className="weight__picker-input-line"></div>
                {/*<p className="weight__picker-input-comma">,</p>*/}
                <div className="weight__picker-input-container">
                    <Picker
                        optionGroups={optionGroups}
                        valueGroups={weightValueGroups}
                        onChange={handleChange} />
                </div>


            </div>

            {/* <p>{`${weightValueGroups.integer},${weightValueGroups.decimal} ${weightValueGroups.selector}`}</p> */}

        </div>
    );
}

export default Weight;
