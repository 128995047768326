import React from 'react';


import './Country.css';
import '../Register.css'
import InputHeading from '../InputsTitle/InputHeading';



function Country({ countryValue, setCountryPopupOpened, countryPopupOpened }) {





    return (
        <div className="country">
            <InputHeading title={'Country of residence'} subtitle={'The terms and services that apply to you will depend on your country of residence'} />
            <div className={`country__inputs`}>
                <div
                    className={`country__input-box ${countryValue ? "country__input-box_focused" : ""
                        } ${countryValue
                            ? "country__input-box_vaid"
                            : ""
                        }`}
                    onClick={() => {
                        setCountryPopupOpened(true)
                    }}
                >
                    <label className="country__input-label" htmlFor="question">
                        Select country
                    </label>
                    <p className={`country__value`}>{countryValue}</p>
                    <svg className={`country__input-arrow ${countryPopupOpened ? 'country__input-arrow_rotated' : ''}`} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.4862 11.3953L13.3988 6.41182C13.6671 6.12173 13.6671 5.67797 13.3988 5.40487C13.1305 5.13181 12.6779 5.13181 12.4096 5.40487L7.99999 9.89339L3.59043 5.40487C3.30544 5.13181 2.86949 5.13181 2.60118 5.40487C2.33293 5.67792 2.33293 6.12166 2.60118 6.41182L7.49699 11.3953C7.78198 11.6683 8.21793 11.6683 8.48623 11.3953H8.4862Z" fill="#6F7974" />
                    </svg>

                </div>

            </div>

        </div>
    );
}

export default Country;
