import { useState, useEffect } from "react";
import SeizureCard from "../SeizureCard/SeizureCard";
import DeletePopup from "../../Register/Disorders/DeletePopup/DeletePopup";
import { MORE_BUTTON_ICON } from "../../../assets/utils/icons";
import "./SeizuresQuestionare.css";

function SeizuresQuestionare({
  symptoms,
  setSymptoms,
  setFormValid,
  index,
  setIndex,
  onAddClick,
  onEditClick,
}) {
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);

  useEffect(() => {
    switch (true) {
      case symptoms.seizure !== undefined && !symptoms.seizure:
        return setFormValid(true);
      case symptoms.seizure && symptoms.details && symptoms.details.length > 0:
        return setFormValid(true);
      default:
        setFormValid(false);
    }
  }, [setFormValid, symptoms.details, symptoms.seizure]);

  function handleChoiceChange(choice) {
    setSymptoms({ ...symptoms, seizure: choice === "Yes" });
  }

  function openDeletePopup(i) {
    setDeletePopupOpen(true);
    setIndex(i);
  }

  function closeDeletePopup() {
    setDeletePopupOpen(false);
  }

  function handleRemoveCard() {
    const arr = symptoms.details.slice();
    const newDetails = arr.filter((item, i) => i !== index);
    setSymptoms({ ...symptoms, details: newDetails });
    closeDeletePopup();
  }

  return (
    <form className="daily-seizure">
      <p className="daily-seizure__title">Please describe your symptoms</p>

      <div className="daily-seizure__question-box">
        <p className="daily-seizure__question">
          Did you experience any seizures today?
        </p>
        <div className="daily-seizure__choice-box">
          <button
            className={`daily-seizure__choice-button ${symptoms.seizure ? "daily-seizure__choice-button_active" : ""
              }`}
            type="button"
            onClick={() => handleChoiceChange("Yes")}
          >
            Yes
          </button>
          <button
            className={`daily-seizure__choice-button ${symptoms.seizure !== undefined && !symptoms.seizure
              ? "daily-seizure__choice-button_active"
              : ""
              }`}
            type="button"
            onClick={() => handleChoiceChange("No")}
          >
            No
          </button>
        </div>
      </div>

      {symptoms.seizure !== undefined && !symptoms.seizure ? (
        <p className="daily-seizure__success-text">
          {/* You are doing well! */}
          </p>
      ) : symptoms.seizure ? (
        <div className="daily-seizure__details-block">
          <p className="daily-seizure__title">
            Provide a detailed description of each seizure episode
          </p>

          <ul className="daily-seizure__details-list">
            {symptoms.details && symptoms.details.length > 0
              ? symptoms.details.map((seizure, i) => (
                <SeizureCard
                  onDelete={openDeletePopup}
                  onEdit={onEditClick}
                  index={i}
                  {...{ seizure }}
                  key={`seizure${i}`}
                />
              ))
              : null}
          </ul>

          <button
            className="daily-seizure__add-episode-button"
            type="button"
            onClick={onAddClick}
          >
            <div className="daily-seizure__add-episode-icon">
              {MORE_BUTTON_ICON}
            </div>
            <p className="daily-seizure__add-episode-text">
              Add seizure episode
            </p>
          </button>
        </div>
      ) : null}

      <DeletePopup
        isPopupOpen={isDeletePopupOpen}
        title={"Do you really want to remove this episode?"}
        onRemove={handleRemoveCard}
        {...{ closeDeletePopup }}
      />
    </form>
  );
}

export default SeizuresQuestionare;
