import { useEffect, useRef, useState } from "react";

import mainApi from "../../assets/api/mainApi";
import { formatHistoryToShedule } from "../../assets/utils/utilis";
import SelectInput from "../SelectInput/SelectInput";
import { Progress } from 'antd'

import "./History.css";
import moment from "moment";

import pillIcon from '../../assets/images/main/med_type/pill.png'
import injectionIcon from '../../assets/images/main/med_type/injection.png'
import syrupIcon from '../../assets/images/main/med_type/syrup.png'
import sprayIcon from '../../assets/images/main/med_type/spray.png'
import tabletIcon from '../../assets/images/main/med_type/tablet.png'
import dropsIcon from '../../assets/images/main/med_type/drops.png'
import ointmentIcon from '../../assets/images/main/med_type/ointment.png'
import procedureIcon from '../../assets/images/main/med_type/procedure.png'
import Preloader from "../Preloader/Preloader";





function History({ handleEditPopupOpend, shedule, setShedule, cards, setCards, weeklyHistory, setWeeklyHistory }) {

    const [selectedSyndrome, setSelectedSyndrome] = useState({
        text: 'All',
        _id: 'AllSyndromeSelector'
    })
    const [syndromes, setSyndromes] = useState([])




    const listInnerRef = useRef();


    const [pageValue, setPageValue] = useState(0);
    const [prevScrollPosition, setPrevScrollPosition] = useState(-1);
    const [scrollPosition, setScrollPosition] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [isPreloaderVisible, setPreloaderVisible] = useState(false)

    const [scrollTraking, setScrollTraking] = useState(true);

    const [timerValue, setTimerValue] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimerValue(timerValue + 1)
            clearInterval(timer)
        }, 1000);

    }, [timerValue])

    useEffect(() => {
        setPreloaderVisible(true)
        mainApi.getSyndromes({ limit: 25 })
            .then((res_syndromes) => {
                console.log(res_syndromes.user_syndromes)
                setSyndromes(res_syndromes.user_syndromes)
                mainApi.getIntakesHistory({ limit: 10 })
                    .then((res_history) => {
                        console.log(res_history.intakes)
                        setShedule(formatHistoryToShedule({ actualShedule: [], cardsToFormat: res_history.intakes }))
                        setCards(res_history.intakes)
                        mainApi.getWeeklyHistory()
                            .then((res_weekly) => {
                                if (res_weekly.percent < 10) {
                                    res_weekly.percent = ((parseInt(Number(res_weekly.percent) * 10)) / 10)
                                } else {
                                    res_weekly.percent = ((parseInt(Number(res_weekly.percent) * 1)) / 1)
                                }
                                let end_day = moment(`${res_weekly.end_day}`)
                                let start_day = moment(`${res_weekly.start_day}`)
                                let end_week_day = {
                                    D: end_day.format('D'),
                                    MM: end_day.format('MM'),
                                    MMM: end_day.format('MMM'),
                                    MMMM: end_day.format('MMMM'),
                                }
                                let start_week_day = {
                                    D: start_day.format('D'),
                                    MM: start_day.format('MM'),
                                    MMM: start_day.format('MMM'),
                                    MMMM: start_day.format('MMMM'),
                                }
                                setWeeklyHistory({
                                    ...res_weekly,
                                    end_week_day: end_week_day,
                                    start_week_day: start_week_day,
                                })
                                console.log(res_weekly)
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                            .finally(() => {
                                setPreloaderVisible(false)
                            })
                    })
                    .catch((err) => {
                        console.log(err)
                        setPreloaderVisible(false)
                    })
            })
            .catch((err) => {
                console.log(err)
                setPreloaderVisible(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    function handleSelectChange(name, value) {
        setSelectedSyndrome(value)
        if (value.text === 'All') {
            setPageValue(0)
            setScrollPosition(0)
            setPrevScrollPosition(0)
            mainApi.getIntakesHistory({ limit: 10 })
                .then((res_history) => {
                    console.log(res_history.intakes)
                    setShedule(formatHistoryToShedule({ actualShedule: [], cardsToFormat: res_history.intakes }))
                    setCards(res_history.intakes)
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            setPageValue(0)
            setScrollPosition(0)
            setPrevScrollPosition(0)
            mainApi.getIntakesHistory({ limit: 10, user_syndrome_id: value._id })
                .then((res_history) => {
                    console.log(res_history.intakes)
                    setShedule(formatHistoryToShedule({ actualShedule: [], cardsToFormat: res_history.intakes }))
                    setCards(res_history.intakes)
                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }





    const handleScroll = () => {
        const position = window.pageYOffset;

        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {

        if (listInnerRef.current && scrollTraking && scrollPosition > prevScrollPosition && cards && cards.length > 0) {
            setPrevScrollPosition(scrollPosition)
            const { scrollHeight } = listInnerRef.current;
            if (scrollHeight < scrollPosition + 400) {
                setScrollTraking(false)
                setPageValue(pageValue + 1)
                setTimeout(() => {
                    setScrollTraking(true)
                }, 500);
            }
        }
    }, [scrollPosition, scrollTraking, prevScrollPosition, pageValue, cards]);

    useEffect(() => {

        if (pageValue > 0 && cards && cards.length === 10 * pageValue) {
            let last_id = cards[cards.length - 1]._id
            let last_date = cards[cards.length - 1].utc_date
            console.log(last_id, last_date)
            console.log('ss')
            // setPreloaderVisible(true)
            console.log(selectedSyndrome)
            mainApi.getIntakesHistory({ limit: 10, last_date: last_date, last_id: last_id, user_syndrome_id: selectedSyndrome.text === 'All' ? null : selectedSyndrome._id })
                .then((res_history) => {
                    console.log(res_history)
                    setShedule(formatHistoryToShedule({ actualShedule: shedule, cardsToFormat: res_history.intakes }))
                    setCards(res_history.intakes)
                })
                .catch((err) => {
                    console.log(err)
                })
            // mainApi.getTodayIntakes({limit: 10, last_id: last_id})
            // .then((res)=>{

            //     console.log(res.medicine_object_intakes)
            //     setCards(prevList => prevList.concat(res.medicine_object_intakes))
            //     setShedule(formatMedicineToShedule({actualShedule: shedule, cardsToFormat: res.medicine_object_intakes}))
            // })
            // .catch((err)=>{
            //     console.log(err)
            // })
            // mainApi.getPatients({ limit: 20, last_id: prevId, last_intakes_skips: prevIntakesMissed })
            //   .then((res) => {
            //     console.log(res.patients)
            //     setPreloaderVisible(false)
            //     setPatients(prewList => prewList.concat(res.patients))
            //   })
            //   .catch((err) => {
            //     setPreloaderVisible(false)
            //     console.log(err)
            //   })

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageValue, cards])


    function handleEditClick(item) {
        handleEditPopupOpend({ card: item })
    }

    return (
        <>
            {isPreloaderVisible ?
                <div className="history-page__preloader">
                    <Preloader isBgLinkColor={true} />
                </div>
                :
                <section className="history-page">
                    <h3 className="history-page__title">Your intake history</h3>
                    <div className="history-page__select">
                        <SelectInput
                            label="Syndrome"
                            value={selectedSyndrome}
                            name="diagnosis"
                            isRequired={true}
                            selectList={syndromes ? [{ text: 'All', _id: 'AllSyndromeSelector' }].concat(syndromes.map((item) => {
                                return {
                                    _id: item._id,
                                    text: item.syndrome.text
                                }
                            })) : []}
                            popupTitle="Select syndrome"
                            onSelect={handleSelectChange}
                        />
                    </div>

                    <div className="history-page__banner">
                        <div className="history-page__banner-texts">
                            <p className="history-page__banner-title">Weekly medication</p>
                            <p className="history-page__banner-weeks">{weeklyHistory && weeklyHistory.start_week_day && weeklyHistory.end_week_day ? weeklyHistory.start_week_day.MM === weeklyHistory.end_week_day.MM ? `${weeklyHistory.end_week_day.MMMM} ${weeklyHistory.start_week_day.D} - ${weeklyHistory.end_week_day.D}` : `${weeklyHistory.start_week_day.MMM}/${weeklyHistory.end_week_day.MMM} ${weeklyHistory.start_week_day.D} - ${weeklyHistory.end_week_day.D}` : ''}</p>
                        </div>
                        <div className="history-page__banner-progress-container">
                            <p className="history-page__banner-progress-percent">{weeklyHistory && weeklyHistory.percent ? weeklyHistory.percent : 0}%</p>
                            <Progress trailColor="#427867" className="history-page__banner-progress" strokeColor="#FFFFFF" type="dashboard" percent={weeklyHistory && weeklyHistory.percent ? weeklyHistory.percent : 0} gapDegree={0} format={() => { }} />
                        </div>
                    </div>

                    <div className="history-page__schedule" ref={listInnerRef}>
                        {shedule && shedule.length > 0 ?
                            shedule.map((schedule_item, i) => (
                                <div className="history-page__schedule-item" key={`history-page__schedule_item${i}`}>
                                    <p className="history-page__schedule-item-date">{schedule_item.date}</p>
                                    <div className="history-page__schedule-cards">
                                        {schedule_item.items.map((card_item, i2) => (
                                            <div className="history-page__schedule-card" key={`history-page__schedule-card${i2}`}>
                                                <div className="history-page__schedule-card-info">
                                                    <p className="history-page__schedule-card-time">{moment(`${card_item.utc_date}+00:00`).format('HH:mm')}</p>
                                                    <p className={`history-page__schedule-card-status ${card_item.status === 'intaked' ? 'history-page__schedule-card-status_type_intaked' : 'history-page__schedule-card-status_type_skipped'}`}>{card_item.status === 'intaked' ? 'Taken' : card_item.status === 'skipped' ? 'Skipped' : 'Missed'}</p>
                                                    <p className="history-page__schedule-card-dose-amount">{card_item.frequency === 'one_time_intake' ? 'dose of' : `${card_item.dose_amount > 1 ? `${card_item.dose_amount} doses of` : `1 dose of`}`} </p>
                                                    <div className="history-page__schedule-card-medicine">
                                                        {card_item.medicine_object.type === 'pill' && <img className="history-page__schedule-card-icon" src={pillIcon} alt='' />}
                                                        {card_item.medicine_object.type === 'injection' && <img className="history-page__schedule-card-icon" src={injectionIcon} alt='' />}
                                                        {card_item.medicine_object.type === 'syrup' && <img className="history-page__schedule-card-icon" src={syrupIcon} alt='' />}
                                                        {card_item.medicine_object.type === 'spray' && <img className="history-page__schedule-card-icon" src={sprayIcon} alt='' />}
                                                        {card_item.medicine_object.type === 'tablet' && <img className="history-page__schedule-card-icon" src={tabletIcon} alt='' />}
                                                        {card_item.medicine_object.type === 'drops' && <img className="history-page__schedule-card-icon" src={dropsIcon} alt='' />}
                                                        {card_item.medicine_object.type === 'ointment' && <img className="history-page__schedule-card-icon" src={ointmentIcon} alt='' />}
                                                        {card_item.medicine_object.type === 'procedure' && <img className="history-page__schedule-card-icon" src={procedureIcon} alt='' />}
                                                        <p className="history-page__schedule-card-medicine-name">{card_item.medicine_object.name}</p>
                                                    </div>
                                                </div>
                                                {card_item.can_be_changed && Number(moment(`${card_item.can_be_changed_limit_utc}+00:00`).format('x')) > Number(moment().format('x')) && card_item.frequency !== "one_time_intake" ?
                                                    <button className="history-page__schedule-card-edit" onClick={() => { handleEditClick(card_item) }}>
                                                        <svg className="history-page__schedule-card-edit-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3 17.2498V20.9998H6.75L17.81 9.93984L14.06 6.18984L3 17.2498ZM21.41 6.33984L17.66 2.58984L15.13 5.12984L18.88 8.87984L21.41 6.33984Z" fill="#006B58" />
                                                        </svg>

                                                    </button>
                                                    : <></>}

                                            </div>
                                        ))}
                                    </div>

                                </div>
                            ))
                            : <></>}

                    </div>
                </section>
            }
        </>

    );
}

export default History;
