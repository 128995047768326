import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MedicineCards from "../../MedicineCards/MedicineCards";
import NewMedicine from "../../MedicineCards/NewMedicine/NewMedicine";
import EditMedicine from "../../MedicineCards/EditMedicine/EditMedicine";
import EditReasons from "../../MedicineCards/EditReasons/EditReasons";
import EditSchedule from "../../MedicineCards/EditSchedule/EditSchedule";
import { BACK_ARROW_ICON } from "../../../assets/utils/icons";
import "./MedicationData.css";
import mainApi from "../../../assets/api/mainApi";
import { fromatDrugFromApi } from "../../../assets/utils/utilis";

function MedicationData({ medicine, setMedicine, getCurentUser }) {


  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [drugToEdit, setDrugToEdit] = useState({});

  useEffect(() => {
    mainApi.getDrugs({ still_taking: true, limit: 10 })
    .then((res) => {
      console.log(res.drugs)
      mainApi.getDrugs({ still_taking: false, limit: 10 })
        .then((res2) => {
          console.log(res2.drugs)
          setMedicine(res.drugs.map((item) => {
            return fromatDrugFromApi(item)
          }).concat(res2.drugs.map((item) => {
            return fromatDrugFromApi(item)
          })))
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          // setDrugsInitPreloaderVisible(false)
        })
    })
    .catch((err) => {
      console.log(err)
    })
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  

  return (
    <section className="meds-data">
      {step === 0 ? (
        <div className="meds-data__content">
          <MedicineCards
            {...{ medicine, setMedicine, setStep, setDrugToEdit, getCurentUser }}
          />
          <div className="meds-data__buttons-container">
            <div className="meds-data__buttons-box">
              <button
                className="meds-data__back-button"
                type="button"
                aria-label="Go to previous page"
                onClick={() => navigate(-1)}
              >
                {BACK_ARROW_ICON}
              </button>
              {/* <button className={`meds-data__save-button`} type="button">
                Save changes
              </button> */}
            </div>
          </div>
        </div>
      ) : null}

      {step === "new-current-medicine" ||
        step === "new-discontinued-medicine" ? (
        <NewMedicine {...{ medicine, setMedicine, step, setStep, getCurentUser }} />
      ) : null}

      {step === "edit-medicine" ? (
        <EditMedicine
          drug={drugToEdit}
          setDrug={setDrugToEdit}
          {...{ medicine, setMedicine, setStep, getCurentUser }}
        />
      ) : null}

      {step === "edit-schedule" ? (
        <EditSchedule
          drug={drugToEdit}
          setDrug={setDrugToEdit}
          {...{ medicine, setMedicine, setStep, getCurentUser }}
        />
      ) : null}

      {step === "edit-reasons" ? (
        <EditReasons
          drug={drugToEdit}
          setDrug={setDrugToEdit}
          {...{ medicine, setMedicine, step, setStep, getCurentUser }}
        />
      ) : null}
    </section>
  );
}

export default MedicationData;
