import { useState } from "react";


import {
  SEIZURE_TYPES_LIST,
  SEIZURE_TIME_PERIOD_LIST,
} from "../../../assets/utils/disordersInfo";
import { SELECT_ARROW_ICON, CLOSE_ICON } from "../../../assets/utils/icons";
import "./SeizureQues.css";
import RangeSelector from "../../RangeSelector/RangeSelector";

function SeizureQues({  details, setDetails, setSeizuresRangeValue, seizuresRangeValue }) {

  const [isFocuced, setIsFocuced] = useState({});
  const [touched, setTouched] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState({
    type: false,
    timePeriod: false,
  });


  function handleFocused(evt) {
    const name = evt.target.name;
    setIsFocuced({ ...isFocuced, [name]: true });
  }

  function handleUnfocused(evt, popup, newValue) {
    const input = evt ? evt.target : undefined;
    const name = popup ? popup : input.name;
    const value = popup ? newValue : input.value;
    value !== ""
      ? setIsFocuced({ ...isFocuced, [name]: true })
      : setIsFocuced({ ...isFocuced, [name]: false });
    setTouched({ ...touched, [name]: true });
  }

  function handleChange(evt) {
    const input = evt.target;
    const name = input.name;
    const value = input.value;
    setDetails({ ...details, [name]: value });
  }

  function handleSelectChange(evt, name, value) {
    setDetails({ ...details, [name]: value });
    handleUnfocused(evt, name, value);
  }


  function openPopup(evt) {
    const name = evt.target.name;
    setPopupOpen({ ...isPopupOpen, [name]: true });
    handleFocused(evt);
  }

  function closePopup(evt, name, value) {
    setPopupOpen({ ...isPopupOpen, [name]: false });
    handleUnfocused(evt, name, value);
  }

  return (
    <div className="seizure-quest">
      <p className="seizure-quest__title">
        Seizure Disorder
      </p>
      <form className="seizure-quest__form">

        <div
          className={`seizure-quest__input-box ${isFocuced.timePeriod || details.timePeriod ? "seizure-quest__input-box_focused" : ""
            }`}
        >
          <label className="seizure-quest__input-label" htmlFor="timePeriod">
            Current frequency of seizures
          </label>
          <input
            className={`seizure-quest__input ${ details.timePeriod
              ? "seizure-quest__input_valid"
              : ""
              }`}
            id="timePeriod"
            name="timePeriod"
            type="text"
            value={details.timePeriod}
            onClick={openPopup}
            readOnly
            required
          />
          <div
            className={`seizure-quest__select-icon ${isPopupOpen.timePeriod ? "seizure-quest__select-icon_reverse" : ""
              }`}
          >
            {SELECT_ARROW_ICON}
          </div>
        </div>

        <div
          className={`seizure-quest__input-box ${isFocuced.type || details.type ? "seizure-quest__input-box_focused" : ""
            }`}
          onClick={openPopup}
        >
          <label className="seizure-quest__input-label" htmlFor="type">
            Types of seizures
          </label>
          <input
            className={`seizure-quest__input ${ details.type
              ? "seizure-quest__input_valid"
              : ""
              }`}
            id="type"
            name="type"
            type="text"
            value={details.type}
            readOnly
            required
          />
          <div
            className={`seizure-quest__select-icon ${isPopupOpen.type  ? "seizure-quest__select-icon_reverse" : ""
              }`}
          >
            {SELECT_ARROW_ICON}
          </div>
        </div>






        <div className="seizure-quest__numbers-block">
          <div
            className={`seizure-quest__input-box ${isFocuced.seizuresNumber || details.seizuresNumber ? "seizure-quest__input-box_focused" : ""
              }`}
            onClick={handleFocused}
            onBlur={handleUnfocused}
          >
            <label className="seizure-quest__input-label" htmlFor="seizuresNumber">
              Number of seizures
            </label>
            <input
              className={`seizure-quest__input ${touched.seizuresNumber || details.seizuresNumber
                ? "seizure-quest__input_valid"
                : ""
                }`}
              id="seizuresNumber"
              name="seizuresNumber"
              type="number"
              value={details.seizuresNumber}
              onChange={handleChange}
              required
            />
          </div>

          <div className="seizure-quest__duration-block">
            <p className="seizure-quest__duration-title">Seizure duration</p>
            <div className="seizure-quest__duration-inputs">
              <div
                className={`seizure-quest__input-box ${isFocuced.durationMin || details.durationMin ? "seizure-quest__input-box_focused" : ""
                  }`}
                onClick={handleFocused}
                onBlur={handleUnfocused}
              >
                <label className="seizure-quest__input-label" htmlFor="durationMin">
                  min
                </label>
                <input
                  className={`seizure-quest__input ${touched.durationMin || details.durationMin
                    ? "seizure-quest__input_valid"
                    : ""
                    }`}
                  id="durationMin"
                  name="durationMin"
                  type="number"
                  value={details.durationMin}
                  onChange={handleChange}
                  required
                />
              </div>

              <div
                className={`seizure-quest__input-box ${isFocuced.durationSec || details.durationSec ? "seizure-quest__input-box_focused" : ""
                  }`}
                onClick={handleFocused}
                onBlur={handleUnfocused}
              >
                <label className="seizure-quest__input-label" htmlFor="durationSec">
                  sec
                </label>
                <input
                  className={`seizure-quest__input ${touched.durationSec || details.durationSec
                    ? "seizure-quest__input_valid"
                    : ""
                    }`}
                  id="durationSec"
                  name="durationSec"
                  type="number"
                  value={details.durationSec}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>
        </div>


        <div className="seizure-quest__range">
          
          <RangeSelector stepsCount={5} title={'Severity of seizures'} lastValues={{ min: 'very strong', max: 'light' }} defaultValue={seizuresRangeValue} setFinalValue={setSeizuresRangeValue} />
        </div>

      </form>

      <div
        className={`seizure-quest__popup ${isPopupOpen.type ? "seizure-quest__popup_opened" : ""
          }`}
      >
        <div className="seizure-quest__popup-container">
          <button
            className="seizure-quest__close-button"
            type="button"
            onClick={(evt) => closePopup(evt, "type", details.type)}
          >
            {CLOSE_ICON}
          </button>
          <p className="seizure-quest__popup-title">Select types of your seizures</p>
          <ul className="seizure-quest__popup-list">
            {SEIZURE_TYPES_LIST.map((type, i) => (
              <li
                className={`seizure-quest__popup-item ${details.type === type ? "seizure-quest__popup-item_selected" : ""
                  }`}
                key={`seizure-type${i}`}
                onClick={(evt) => handleSelectChange(evt, "type", type)}
              >
                <p className="seizure-quest__popup-text">{type}</p>
              </li>
            ))}
          </ul>
          <button
            className={`seizure-quest__save-button ${details.type === "" ? "seizure-quest__save-button_disabled" : ""
              }`}
            type="button"
            onClick={(evt) => closePopup(evt, "type", details.type)}
          >
            Save
          </button>
        </div>
      </div>
      <div className="seizure-quest__background"   onClick={(evt) => closePopup(evt, "type", details.type)} />

      <div
        className={`seizure-quest__popup ${isPopupOpen.timePeriod ? "seizure-quest__popup_opened" : ""
          }`}
      >
        <div className="seizure-quest__popup-container">
          <button
            className="seizure-quest__close-button"
            type="button"
            onClick={(evt) => closePopup(evt, "timePeriod", details.timePeriod)}
          >
            {CLOSE_ICON}
          </button>
          <p className="seizure-quest__popup-title">Select time period</p>
          <ul className="seizure-quest__popup-list">
            {SEIZURE_TIME_PERIOD_LIST.map((period, i) => (
              <li
                className={`seizure-quest__popup-item ${details.timePeriod === period
                  ? "seizure-quest__popup-item_selected"
                  : ""
                  }`}
                key={`seizure-period${i}`}
                onClick={(evt) => handleSelectChange(evt, "timePeriod", period)}
              >
                <p className="seizure-quest__popup-text">{period}</p>
              </li>
            ))}
          </ul>
          <button
            className={`seizure-quest__save-button ${details.timePeriod === "" ? "seizure-quest__save-button_disabled" : ""
              }`}
            type="button"
            onClick={(evt) => closePopup(evt, "timePeriod", details.timePeriod)}
          >
            Save
          </button>
        </div>
      </div>
      <div className="seizure-quest__background" onClick={(evt) => closePopup(evt, "timePeriod", details.timePeriod)} />
    </div>
  );
}

export default SeizureQues;
