import { useEffect } from "react";
import RangeSelector from "../../RangeSelector/RangeSelector";
import "./GeneralQuestionare.css";

function GeneralQuestionare({
  medicalCondition,
  setMedicalCondition,
  mentalCondition,
  setMentalCondition,
  setFormValid,
}) {
  useEffect(() => {
    setFormValid(true);
  }, [setFormValid]);

  return (
    <div className="daily-general">
      <p className="daily-general__title">
        Describe how do you feel in general
      </p>
      <form className="daily-general__form">
        <RangeSelector
          stepsCount={4}
          title={"General medical condition"}
          lastValues={{ min: "worst", max: "better" }}
          defaultValue={medicalCondition}
          setFinalValue={setMedicalCondition}
        />

        <RangeSelector
          stepsCount={4}
          title={"General mental condition"}
          lastValues={{ min: "worst", max: "better" }}
          defaultValue={mentalCondition}
          setFinalValue={setMentalCondition}
        />
      </form>
    </div>
  );
}

export default GeneralQuestionare;
