import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import scrollPageToTop from '../../assets/utils/scrollPageToTop';

import MedCondition from './MedCondition/MedCondition';
import './Questionare.css'
import SeizureChange from './SeizureChange/SeizureChange';
import SeizureQues from './SeizureQues/SeizureQues';


function Questionare() {
    const navagate = useNavigate()
    const [step, setStep] = useState(0)

    const [seizureDetails, setSeizureDetails] = useState({
        type: "",
        timePeriod: "",
        seizuresNumber: "",
        durationMin: "",
        durationSec: "",
    });
    const [seizuresRangeValue, setSeizuresRangeValue] = useState(1)


    const [frequencyRangeValue, setFrequencyRangeValue] = useState(1)
    const [severityRangeValue, setSeverityRangeValue] = useState(1)
    const [durationRangeValue, setDurationRangeValue] = useState(1)

    const [conditionRangeValue, setConditionRangeValue] = useState(1)


    const [isNextBtnActive, setNextBtnActive] = useState(true)


    useEffect(() => {
        console.log(seizureDetails)
    }, [seizureDetails])

    function handleNextStep() {
        if (isNextBtnActive) {

            if (step === 2) {
                navagate('/')
            } else {
                scrollPageToTop()
                setStep(step + 1)
            }
        }


    }



    function handleStepBack() {
        setStep(step - 1)
        scrollPageToTop()

    }



    useEffect(() => {
        switch (step) {
            case 0:
                if (seizureDetails && seizureDetails.type && seizureDetails.durationMin && seizureDetails.durationSec && seizureDetails.seizuresNumber && seizureDetails.timePeriod) {
                    setNextBtnActive(true)
                    break
                } else {
                    setNextBtnActive(false)
                    break
                }
            case 1:
                setNextBtnActive(true)
                break

            case 2:
                setNextBtnActive(true)
                break

            default:
                break
        }



    }, [step, seizureDetails])



    return (
        <>

            <div className="questionare">
                <div className="questionare__content">
                    <div className="questionare__steps-index">
                        {(Array(3)).fill('').map((item, i) => (
                            <div className={`questionare__step-index ${step === i ? 'questionare__step-index_active' : ''}`} key={`questionare__step-index${i}`}></div>
                        ))}
                    </div>
                    <div className='questionare__step-block'>
                        {step === 0 && <SeizureQues setSeizuresRangeValue={setSeizuresRangeValue} details={seizureDetails} setDetails={setSeizureDetails} seizuresRangeValue={seizuresRangeValue} />}
                        {step === 1 && <SeizureChange durationRangeValue={durationRangeValue} setDurationRangeValue={setDurationRangeValue} severityRangeValue={severityRangeValue} setSeverityRangeValue={setSeverityRangeValue} frequencyRangeValue={frequencyRangeValue} setFrequencyRangeValue={setFrequencyRangeValue} />}
                        {step === 2 && <MedCondition conditionRangeValue={conditionRangeValue} setConditionRangeValue={setConditionRangeValue} />}
                    </div>


                    <div className='questionare__step-btns'>
                        {step !== 0 ?
                            <div className='questionare__step-btn-back' onClick={handleStepBack}>
                                <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 1L1.61152 5.82733C1.26477 6.20875 1.26477 6.79125 1.61152 7.17267L6 12" stroke="#191C1B" strokeWidth="2" strokeLinecap="round" />
                                </svg>

                            </div> :

                            <Link className='questionare__step-btn-back' to='/'>
                                <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 1L1.61152 5.82733C1.26477 6.20875 1.26477 6.79125 1.61152 7.17267L6 12" stroke="#191C1B" strokeWidth="2" strokeLinecap="round" />
                                </svg>

                            </Link>
                        }
                        <div className={`questionare__step-btn-next ${isNextBtnActive ? 'questionare__step-btn-next_active' : ''}`}
                            onClick={handleNextStep}>
                            <p className='questionare__step-btn-next-text'>{step === 2 ? 'Done' : 'Next'}</p>
                        </div>
                    </div>
                </div>

            </div>
        </>

    );
}

export default Questionare;

