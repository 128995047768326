import { useEffect } from "react";
import SelectInput from "../../SelectInput/SelectInput";
import RangeSelector from "../../RangeSelector/RangeSelector";
import {
  INTAKE_TIME_OPTION_GROUPS,
  DURATION_OPTION_GROUPS,
} from "../../../assets/utils/disordersInfo";
import "./SeizureDetails.css";

function SeizureDetails({
  episode,
  seizureSeverity,
  setSeizureSeverity,
  timeValueGroups,
  handleTimeChange,
  durationValueGroups,
  handleDurationChange,
  handleSelectChange,
  setFormValid,
}) {
  useEffect(() => {
    Object.values(episode.exactTime).length > 0 &&
    Object.values(episode.duration).length > 0
      ? setFormValid(true)
      : setFormValid(false);
  }, [episode, setFormValid]);

  return (
    <div className="daily-details">
      <p className="daily-details__title">Provide a detailed description of each seizure episode</p>
      <form className="daily-details__form">
        <SelectInput
          type2="picker"
          label="When it happened?"
          value={
            Object.values(episode.exactTime).length === 0
              ? ""
              : `${episode.exactTime.hours}:${episode.exactTime.min}`
          }
          name="exactTime"
          isRequired={true}
          popupTitle="Set seizure time"
          onSelect={handleSelectChange}
          onPickerChange={handleTimeChange}
          separator="h"
          separator2="m"
          optionGroups={INTAKE_TIME_OPTION_GROUPS}
          valueGroups={timeValueGroups}
        />

        <SelectInput
          type2="picker"
          label="Seizure duration"
          value={
            Object.values(episode.duration).length === 0
              ? ""
              : `${episode.duration.min} min ${episode.duration.sec} sec`
          }
          name="duration"
          isRequired={true}
          selectList={[]}
          popupTitle="Set seizure duration"
          onSelect={handleSelectChange}
          onPickerChange={handleDurationChange}
          separator="m"
          separator2="s"
          optionGroups={DURATION_OPTION_GROUPS}
          valueGroups={durationValueGroups}
        />

        <div className="daily-details__range-box">
          <RangeSelector
            stepsCount={4}
            title={"Severity of the seizure"}
            lastValues={{ min: "light", max: "hard" }}
            defaultValue={seizureSeverity}
            setFinalValue={setSeizureSeverity}
          />
        </div>
      </form>
    </div>
  );
}

export default SeizureDetails;
