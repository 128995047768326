import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import moment from 'moment-timezone';
import scrollPageToTop from '../../assets/utils/scrollPageToTop';
import { monthFormatMap } from '../../assets/utils/utilis';
import { NEXT, PREV } from '../../assets/utils/constants';
import './Register.css';

import step1Icon from '../../assets/images/register/step-1.png'
import step2Icon from '../../assets/images/register/step-2.png'
import step3Icon from '../../assets/images/register/step-3.png'
import step4Icon from '../../assets/images/register/step-4.png'
import step5Icon from '../../assets/images/register/step-5.png'
import step6Icon from '../../assets/images/register/step-6.png'

import EmailAndPassword from './EmailAndPassword/EmailAndPassword';
import Country from './Country/Country';
import NameAndSex from './NameAndSex/NameAndSex';
import DateOfBirth from './DateOfBirth/DateOfBirth';
import Weight from './Weight/Weight';
import Height from './Height/Height';
import mainApi from '../../assets/api/mainApi';
import MiniPreloader from '../MiniPreloader/MiniPreloader';
import EmailCode from './EmailCode/EmailCode';

const steps = [
  step6Icon,
  step1Icon,
  step1Icon,
  step2Icon,
  step3Icon,
  step4Icon,
  step5Icon,
]

function Register({ setCountryPopupOpened, countryPopupOpened, countryValue, setCurrentUser, currentUser, setCountryValue }) {
  const navigate = useNavigate();
  const [step, setStep] = useState(0)

  const [emailValue, setEmailValue] = useState('')
  const [emailValidity, setEmailValidity] = useState({});

  const [passValue, setPassValue] = useState('')
  const [passValidity, setPassValidity] = useState({});

  const [code, setCode] = useState({})
  const [codeValidity, setCodeValidity] = useState({});
  const [codeError, setCodeError] = useState('')

  const [firstNameValue, setFirstNameValue] = useState('')
  const [firstNameValidity, setFirstNameValidity] = useState({});

  const [lastNameValue, setLastNameValue] = useState('')
  const [lastNameValidity, setLastNameValidity] = useState({});

  const [genderValue, setGenderValue] = useState('')

  const [dateValueGroups, setDateValueGroups] = useState({
    month: moment().format('MMM'),
    year: moment().format('YYYY'),
  })

  const [weightValueGroups, setWeightValueGroups] = useState({
    integer: 50,
    //decimal: 1,
    selector: 'kg',
  })

  const [weightDefValueGroups, setWeightDefValueGroups] = useState({
    integer: 50,
    //decimal: 1,
    selector: 'kg',
  })

  const [heightValueGroups, setHeightValueGroups] = useState({
    integer: 160,
    //decimal: 1,
    selector: 'cm',
  })

  const [heightDefValueGroups, setHeightDefValueGroups] = useState({
    integer: 160,
    //decimal: 1,
    selector: 'cm',
  })

  const [direction, setDirection] = useState(NEXT);
  const [isSliding, setSliding] = useState(false);

  function setOptions(swipe) {
    setDirection(swipe);
    setSliding(true);
    setTimeout(() => {
      setSliding(false);
    }, 50);
  }

  function getOrder({ index, step, numItems }) {
    return index - step < 0
      ? numItems - Math.abs(index - step)
      : index - step;
  }

  useEffect(() => {
    if (currentUser) {
      if (currentUser.register_stage === 0) {
        setStep(1)
      }
      if (currentUser.register_stage === 1) {
        setStep(2)
      }
    }
  }, [currentUser])

  const [regError, setRegError] = useState('')
  const [submitPreloaderVisible, setSubmitPreloaderVisible] = useState(false)

  function handleNextStep() {
    if (regError === '') {
      if (isNextBtnActive) {
        setRegError('')
        if (step === 0) {
          setSubmitPreloaderVisible(true)
          mainApi.signUpFirstPart({ email: emailValue, password: passValue })
            .then((res) => {
              console.log(res)
              setCurrentUser(res)
              // setStep(step + 1)
            })
            .catch((err) => {
              switch (err.detail) {
                case 'Too Many Requests':
                  setRegError('Too Many Requests')
                  setTimeout(() => {
                    setRegError('')
                  }, 10000);
                  break;

                case 'Email or phone are already registered':
                  setRegError('This email is already registered')
                  setTimeout(() => {
                    setRegError('')
                  }, 5000);
                  break;

                case 'Email was not sent':
                  setRegError('We were unable to send the code. Something is wrong with the specified email, check the spelling.')
                  setTimeout(() => {
                    setRegError('')
                  }, 5000);
                  break;

                default:
                  if(err.detail){
                    setRegError(err.detail)
                  }
                  else {
                    setRegError('Something went wrong')
                  }
                  setTimeout(() => {
                    setRegError('')
                  }, 5000);
                  break;
              }

              console.log(err)
            })
            .finally(() => {
              setSubmitPreloaderVisible(false)
            })
        } else if (step !== 6) {
          scrollPageToTop()
          setStep(step + 1)
          setOptions(NEXT);
          if (step === 5) {
            setWeightDefValueGroups(weightValueGroups)
          }
          if (step === 6) {
            setHeightDefValueGroups(heightValueGroups)
          }
        } else {
          setSubmitPreloaderVisible(true)
          mainApi.signUpSecondPart({
            country: countryValue,
            gender: genderValue.toLowerCase(),
            name: firstNameValue,
            last_name: lastNameValue,
            date_of_birth: moment(`01.${dateValueGroups.month}.${dateValueGroups.year} +00:00`, 'DD.MMMM.YYYY Z'),
            weight: weightValueGroups,
            height: heightValueGroups,
          })
            .then((res) => {
              console.log(res)
              // console.log('NAVIGATE TO - "Let us know what disorders you have" block')
              setCurrentUser(res)
              navigate("/sign-up/disorders")
            })
            .catch((err) => {
              console.log(err.detail)
              switch (err.detail) {
                case 'Email or phone are already registered':
                  setRegError('This email address is already registered')
                  break;


                default:
                  setRegError(err.detail)
                  break;
              }
              setTimeout(() => {
                setRegError('')
              }, 3000);

            })
            .finally(() => {
              setSubmitPreloaderVisible(false)
            })

        }
      }
    }

  }

  useEffect(() => {
    if (code[0] && code[1] && code[2] && code[3]) {

      console.log({ code: `${code[0] + code[1] + code[2] + code[3]}` })
      mainApi.checkEmailCode({ code: `${code[0] + code[1] + code[2] + code[3]}` })
        .then((res) => {
          console.log(res)
          setCurrentUser(res)
          setStep(prewStep => prewStep + 1)
          setCodeValidity({
            validState: true,
          })
        })
        .catch((err) => {
          console.log(err)
          switch (err.detail) {
            case 'Wrong code was sent':
              setCodeError('Wrong code')
              break;

            default:
              break;
          }
        })

      // setCode({})
    } else {
      setCodeError('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])


  function handleStepBack() {
    if (step === 0) {
      navigate('/onboarding/1')
    } else {
      setStep(step - 1)
      scrollPageToTop()
      setOptions(PREV)

      if (step === 2) {
        setEmailValue('')
        setEmailValidity({})
        setPassValue('')
        setPassValidity({})

        setCode({})
        setCodeValidity({})
        setCodeError('')

        setCountryValue('')

        setFirstNameValue('')
        setFirstNameValidity({})
        setLastNameValue('')
        setLastNameValidity({})
        setGenderValue('')

        setDateValueGroups({
          month: moment().format('MMM'),
          year: moment().format('YYYY'),
        })

        setWeightValueGroups({
          integer: 50,
          //decimal: 1,
          selector: 'kg',
        })
        setWeightDefValueGroups({
          integer: 50,
          //decimal: 1,
          selector: 'kg',
        })

        setHeightValueGroups({
          integer: 160,
          //decimal: 1,
          selector: 'cm',
        })
        setHeightDefValueGroups({
          integer: 160,
          //decimal: 1,
          selector: 'cm',
        })

        mainApi.logout()
          .then((res) => {

            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
        setStep(0)
      }
      if (step === 5) {
        setWeightDefValueGroups(weightValueGroups)
      }
      if (step === 6) {
        setHeightDefValueGroups(heightValueGroups)
      }
    }

  }

  const [isNextBtnActive, setNextBtnActive] = useState(true)
  useEffect(() => {
    switch (step) {
      case 0:
        if (emailValidity.validState && passValidity.validState) {
          setNextBtnActive(true)
          break
        }
        else {
          setNextBtnActive(false)
          break
        }

      case 1:
        if (codeValidity.validState) {
          setNextBtnActive(true)
          break
        }
        else {
          setNextBtnActive(false)
          break
        }

      case 2:
        if (countryValue) {
          setNextBtnActive(true)
          break
        }
        else {
          setNextBtnActive(false)
          break
        }

      case 3:
        if (firstNameValidity.validState && lastNameValidity.validState && genderValue) {
          setNextBtnActive(true)
          break
        }
        else {
          setNextBtnActive(false)
          break
        }

      case 4:
        if (dateValueGroups && (moment() >= moment(`${monthFormatMap[dateValueGroups.month]}.01.${dateValueGroups.year}`, 'MM.DD.YYYY'))) {
          setNextBtnActive(true)
          break
        }
        else {
          setNextBtnActive(false)
          break
        }

      case 5:
        setNextBtnActive(true)
        break

      case 6:
        setNextBtnActive(true)
        break

      default:
        break
    }
  }, [step, emailValidity, passValidity, countryValue, firstNameValidity, lastNameValidity, genderValue, dateValueGroups, codeValidity])

  useEffect(() => {
    setCode({})
  }, [step])

  function handleLogin() {

    navigate(`/sign-in/${emailValue}`)
    scrollPageToTop()
  }


  return (
    <div className="register">
      <div className="register__carousel-wrapper">
        <div className={`register__carousel 
      ${isSliding ? "register__carousel_sliding" : ""
          } ${isSliding && direction === PREV
            ? "register__carousel_type_prev"
            : ""
          } ${isSliding && direction === NEXT
            ? "register__carousel_type_next"
            : ""
          }`}
          dir={direction}
        // sliding={isSliding ? isSliding : undefined}
        >
          {steps.map((item, i) => (
            <div className="register__icon-card"
              style={{
                order: getOrder({
                  index: i,
                  step: step,
                  numItems: steps.length
                })
              }}
              key={`slide-${i}`}
            >
              <img className="register__icon"
                src={item} alt=''></img>
            </div>
          ))}
        </div>
      </div>

      <div className="register__bg"></div>
      <div className="register__bg-white"></div>
      <div className="register__container">
        <div className="register__steps-index">
          {(Array(steps.length + 1)).fill('').map((item, i) => (
            <div className={`register__step-index ${step === i ? 'register__step-index_active' : ''}`} key={`register__step-index${i}`}></div>
          ))}
        </div>
        <div className='register__step-block'>
          {step === 0 && <EmailAndPassword passValue={passValue} setPassValue={setPassValue} passValidity={passValidity} setPassValidity={setPassValidity} emailValue={emailValue} setEmailValue={setEmailValue} emailValidity={emailValidity} setEmailValidity={setEmailValidity} />}

          {step === 1 && <EmailCode setCurrentUser={setCurrentUser} codeError={codeError} setCodeError={setCodeError} code={code} setCode={setCode} currentUser={currentUser} step={step} />}

          {step === 2 && <Country countryValue={countryValue} countryPopupOpened={countryPopupOpened} setCountryPopupOpened={setCountryPopupOpened} />}

          {step === 3 && <NameAndSex genderValue={genderValue} setGenderValue={setGenderValue} firstNameValue={firstNameValue} setFirstNameValue={setFirstNameValue} firstNameValidity={firstNameValidity} setFirstNameValidity={setFirstNameValidity} lastNameValue={lastNameValue} setLastNameValue={setLastNameValue} lastNameValidity={lastNameValidity} setLastNameValidity={setLastNameValidity} />}

          {step === 4 && <DateOfBirth dateValueGroups={dateValueGroups} setDateValueGroups={setDateValueGroups} />}

          {step === 5 && <Weight weightDefValueGroups={weightDefValueGroups} setWeightDefValueGroups={setWeightDefValueGroups} weightValueGroups={weightValueGroups} setWeightValueGroups={setWeightValueGroups} />}

          {step === 6 && <Height heightDefValueGroups={heightDefValueGroups} setHeightDefValueGroups={setHeightDefValueGroups} heightValueGroups={heightValueGroups} setHeightValueGroups={setHeightValueGroups} />}
          {regError ? <p className='register__submit-error'>{regError}</p> : <></>}
        </div>
        <div className='register__step-btns'>

          <div className='register__step-btn-back' onClick={handleStepBack}>
            <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 1L1.61152 5.82733C1.26477 6.20875 1.26477 6.79125 1.61152 7.17267L6 12" stroke="#191C1B" strokeWidth="2" strokeLinecap="round" />
            </svg>
          </div>

          {step === 0 && (emailValidity.errorMassage === 'This email is already registered' || regError === 'This email is already registered') ?
            <div className='register__login-btn' onClick={handleLogin}>
              <p className='register__login-btn-text'>Log in</p>
            </div>
            :
            <div className={`register__step-btn-next ${isNextBtnActive && !regError ? 'register__step-btn-next_active' : ''}`}
              onClick={handleNextStep}>
              {submitPreloaderVisible ?
                <MiniPreloader isLinkColor={true} />
                :
                <p className='register__step-btn-next-text'>Next</p>
              }

            </div>
          }

        </div>
      </div>
    </div>

  );
}

export default Register;
