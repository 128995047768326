import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import Checkbox from "./Checkbox/Checkbox";
import {
  CHECKBOX_REQUIRED_ERR,
  NEXT,
  PREV,
} from "../../assets/utils/constants";

import privacy from "../../assets/images/onboarding/privacy.png";
import reminders from "../../assets/images/onboarding/reminders.png";
import track from "../../assets/images/onboarding/track.png";
import participate from "../../assets/images/onboarding/participate.png";

import "./Onboarding.css";
import terms_and_conditions from '../../assets/files/Terms_and_Conditions.pdf'
import privacy_policy from '../../assets/files/Privacy_Policy.pdf'


const cards = [
  {
    image: reminders,
    title: "Reminders",
    text: "Set reminders so that you never forget to take your medicine on time",
  },
  {
    image: track,
    title: "Track",
    text: "Track how medication affects your health and your journey to recovery",
  },
  {
    image: participate,
    title: "Participate",
    text: "Participate in daily questionnaires to assist us in the search for the best medicine",
  },
];



function Onboarding() {
  const { stepInLink } = useParams()
  const [step, setStep] = useState(stepInLink && (stepInLink === '0' || stepInLink === '1') ? Number(stepInLink) : 0);

  //step 0
  const [isChecked, setChecked] = useState({
    privacy: false,
    personalData: false,
    parentConsent: false,
    notChild: false,
  });
  const [touched, setTouched] = useState({
    privacy: false,
    personalData: false,
    parentConsent: false,
    notChild: false,
  });
  const [isFormValid, setFormValid] = useState(false);
  const [isInputValid, setInputValid] = useState({
    privacy: false,
    personalData: false,
    parentConsent: false,
    notChild: false,
  });

  function handleCheck(evt) {
    const input = evt.target;
    const name = input.name;
    const value = input.checked;
    const isValid = input.checkValidity();
    setChecked({ ...isChecked, [name]: value });
    setInputValid({ ...isInputValid, [name]: isValid });


    setTouched({ ...touched, [name]: true });
  }

  useEffect(() => {
    setFormValid(isChecked.personalData && isChecked.privacy && ((isChecked.parentConsent && !isChecked.notChild) || (!isChecked.parentConsent && isChecked.notChild)));
  }, [isChecked])
  function handleSubmit(evt) {
    evt.preventDefault();
    if (isFormValid) {
      console.log(isChecked);
      setStep(step + 1);
    }
  }

  //step 1
  const timer = useRef(null);
  const numItems = cards.length;
  const [slide, setSlide] = useState(numItems - 1);
  const [direction, setDirection] = useState(NEXT);
  const [isSliding, setSliding] = useState(false);

  useEffect(() => {
    if (step === 1) {
      timer.current = setTimeout(() => {
        if (slide < numItems - 1) {
          setSlide(slide + 1);
        } else {
          setSlide(0);
        }

        setOptions(NEXT);
        setTimeout(() => {
          setSliding(false);
        }, 50);
      }, 10000);
    }
  }, [slide, numItems, step]);

  function getOrder(index, slide, numItems) {
    return index - slide < 0
      ? numItems - Math.abs(index - slide)
      : index - slide;
  }

  function handleSlide(swipe) {
    if (swipe === NEXT) {
      setSlide(slide === numItems - 1 ? 0 : slide + 1);
    } else if (swipe === PREV) {
      setSlide(slide === 0 ? numItems - 1 : slide - 1);
    }

    setOptions(swipe);
    clearTimeout(timer.current);

    setTimeout(() => {
      setSliding(false);
    }, 50);
  }

  function setOptions(swipe) {
    setDirection(swipe);
    setSliding(true);
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSlide(NEXT),
    onSwipedRight: () => handleSlide(PREV),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <section className="onboarding">
      {step === 0 ? (
        <div className="onboarding__container">
          <div className="onboarding__heading">
            <img
              className="onboarding__image"
              src={privacy}
              alt="Shield icon"
            />
            <p className="onboarding__title">
              We value
              <br />
              your privacy
            </p>
          </div>

          <form className="onboarding__form" onSubmit={handleSubmit}>
            <div className="onboarding__checkbox-block">

              <Checkbox
                name="privacy"
                isChecked={isChecked.privacy}
                onClick={handleCheck}
                // isRequired={true}
                touched={touched.privacy}
                valid={isInputValid.privacy}
                error={CHECKBOX_REQUIRED_ERR}
              >
                <span className="checkbox__label-text">
                  I agree to the{" "}
                  <a className="checkbox__link" href={privacy_policy} target="_blank" rel="noreferrer">
                    Privacy&nbsp;Policy
                  </a>{" "}
                  and{" "}
                  <a className="checkbox__link" href={terms_and_conditions} target="_blank" rel="noreferrer">
                    Terms&nbsp;and&nbsp;Conditions
                  </a>
                </span>
              </Checkbox>
              <Checkbox
                name="personalData"
                isChecked={isChecked.personalData}
                onClick={handleCheck}
                isRequired={true}
                touched={touched.personalData}
                valid={isInputValid.personalData}
                error={CHECKBOX_REQUIRED_ERR}
              >
                <span className="checkbox__label-text">
                  I agree to the processing of my
                  <br />
                  personal data for  Research&nbsp;Purposes
                </span>
              </Checkbox>

              <Checkbox
                name="parentConsent"
                isChecked={isChecked.parentConsent}
                onClick={handleCheck}
                // isRequired={true}
                touched={touched.parentConsent}
                valid={isInputValid.parentConsent}
                // error={CHECKBOX_REQUIRED_ERR}
                className={isChecked.notChild ? "checkbox_inactive" : ''}
              >
                <span className="checkbox__label-text">
                  I am the parent or legal guardian of the minor being registered, and I give my consent for their participation.
                </span>
              </Checkbox>
              <Checkbox
                name="notChild"
                isChecked={isChecked.notChild}
                onClick={handleCheck}
                // isRequired={true}
                touched={touched.notChild}
                valid={isInputValid.notChild}
              // error={CHECKBOX_REQUIRED_ERR}
              className={isChecked.parentConsent ? "checkbox_inactive" : ''}
              >
                <span className="checkbox__label-text">
                  I am over 18 years of age.
                </span>
              </Checkbox>
            </div>
            <button
              className={`onboarding__button ${!isFormValid ? "onboarding__button_disabled" : ""
                }`}
              type="submit"
            >
              <p className="onboarding__button-text">Next</p>
            </button>
          </form>
        </div>
      ) : null}

      {step === 1 ? (
        <div className="onboarding__container">
          <div className="onboarding__heading" {...handlers}>
            <div className="onboarding__carousel-wrapper">
              <div
                className={`onboarding__carousel ${isSliding ? "onboarding__carousel_sliding" : ""
                  } ${isSliding && direction === PREV
                    ? "onboarding__carousel_type_prev"
                    : ""
                  } ${isSliding && direction === NEXT
                    ? "onboarding__carousel_type_next"
                    : ""
                  }`}
                dir={direction}
              >
                {cards.map((card, i) => (
                  <div
                    className="onboarding__card"
                    style={{ order: getOrder(i, slide, numItems) }}
                    key={`slide-${i}`}
                  >
                    <img
                      className="onboarding__image"
                      src={card.image}
                      alt={`${card.title} icon`}
                    />
                    <p className="onboarding__title">{card.title}</p>
                    <p className="onboarding__text">{card.text}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="onboarding__slide-tracker">
              <div
                className={`onboarding__slide ${slide === 2 ? "onboarding__slide_active" : ""
                  }`}
              />
              <div
                className={`onboarding__slide ${slide === 0 ? "onboarding__slide_active" : ""
                  }`}
              />
              <div
                className={`onboarding__slide ${slide === 1 ? "onboarding__slide_active" : ""
                  }`}
              />
            </div>
          </div>

          <div className="onboarding__buttons-block">
            <Link
              className="onboarding__button onboarding__button_type_signin"
              to="/sign-in"
            >
              <p className="onboarding__button-text onboarding__button-text_type_signin">
                Sign in
              </p>
            </Link>
            <Link className="onboarding__button" to="/sign-up">
              <p className="onboarding__button-text">Sign up</p>
            </Link>
          </div>
        </div>
      ) : null}
    </section>
  );
}

export default Onboarding;
