import { useEffect, useRef } from "react";

import "./TakeResultPopup.css";

function TakeResultPopup({ isPopupOpen, setPopupOpen, resultError }) {
  const overlay = useRef();

  function handleClose() {
    setPopupOpen(false);
  }

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        setPopupOpen(false);
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });

  return (
    <div className={`take-result-popup ${isPopupOpen ? "take-result-popup_opened" : ""}`}>
      <div className="take-result-popup__container">
        <svg className="take-result-popup__close" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClose}>
          <path className="take-result-popup__close-fill" d="M2.98555 13.0263C3.17461 13.2155 3.4304 13.3219 3.69701 13.3219C3.96363 13.3219 4.21942 13.2155 4.40848 13.0263L7.98126 9.37669L11.554 13.0263C11.8171 13.2914 12.2008 13.3949 12.5604 13.2979C12.9199 13.2009 13.2009 12.9179 13.2972 12.5557C13.3935 12.1936 13.2907 11.8071 13.0275 11.5421L9.40421 7.94341L13.0275 4.34471C13.25 4.08314 13.3257 3.72558 13.2287 3.39526C13.1318 3.06494 12.8751 2.80641 12.5472 2.70874C12.219 2.61105 11.8642 2.68729 11.6044 2.91142L7.98125 6.51012L4.40847 2.91142C4.14879 2.68729 3.79381 2.61105 3.46587 2.70874C3.13792 2.80642 2.88126 3.06493 2.78429 3.39526C2.6873 3.72558 2.763 4.08314 2.98551 4.34471L6.55829 7.94341L2.98551 11.5421C2.78175 11.7342 2.66602 12.003 2.66602 12.2841C2.66602 12.5654 2.78175 12.8339 2.98551 13.0262L2.98555 13.0263Z" fill="white" />
        </svg>
        {!resultError ?
          <>
            <svg className="take-result-popup__icon" width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.5 35.0995L10.4 25.9995L7.3667 29.0328L19.5 41.1661L45.5 15.1661L42.4667 12.1328L19.5 35.0995Z" fill="#323232" />
            </svg>
            <p className="take-result-popup__text">Thank you for providing information<br />about your medications</p>
            <button className="take-result-popup__btn" onClick={handleClose}>
              <p className="take-result-popup__btn-text">Great</p>
            </button>
          </>
          :
          <>
            <svg className="take-result-popup__icon" width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M24.3335 32.5007H28.6668V36.834H24.3335V32.5007ZM24.3335 15.1673H28.6668V28.1673H24.3335V15.1673ZM26.4785 4.33398C14.5185 4.33398 4.8335 14.0407 4.8335 26.0007C4.8335 37.9607 14.5185 47.6673 26.4785 47.6673C38.4602 47.6673 48.1668 37.9607 48.1668 26.0007C48.1668 14.0407 38.4602 4.33398 26.4785 4.33398ZM26.5002 43.334C16.9235 43.334 9.16683 35.5773 9.16683 26.0007C9.16683 16.424 16.9235 8.66732 26.5002 8.66732C36.0768 8.66732 43.8335 16.424 43.8335 26.0007C43.8335 35.5773 36.0768 43.334 26.5002 43.334Z" fill="#323232" />
            </svg>

            <p className="take-result-popup__text">{resultError}</p>
            <button className="take-result-popup__btn" onClick={handleClose}>
              <p className="take-result-popup__btn-text">Close</p>
            </button>
          </>
        }
      </div>
      <div
        className="take-result-popup__overlay"
        ref={overlay}
        onTouchStart={handleClose}
      />
    </div>
  );
}

export default TakeResultPopup;
