import React, { useState } from 'react';
import Picker from 'react-mobile-picker';


import './Height.css';
import '../Register.css'
import InputHeading from '../InputsTitle/InputHeading';
import useDidMountEffect from '../../../assets/utils/useDidMountEffect';


const ftInSm = 0.0328084

function Height({ heightValueGroups, setHeightValueGroups, heightDefValueGroups }) {

    function range(a, b, c) { c = []; while (a--) c[a] = a + b; return c };

    const [optionGroups, setOptionGroups] = useState({
        integer: heightDefValueGroups && heightDefValueGroups.selector === 'cm' ? range(260 - 89, 90) : range(7 - 1, 2),
        //decimal: range(9 + 1, 0),
        selector: ['cm', 'ft']
    })

    useDidMountEffect(() => {
        console.log('render')
        console.log({
            int1: heightDefValueGroups.integer,
            int2: heightValueGroups.integer,
            //dec1: heightDefValueGroups.decimal,
            //dec2: heightValueGroups.decimal,
        })
        if (heightDefValueGroups && heightValueGroups && (heightDefValueGroups.integer !== heightValueGroups.integer || /*heightDefValueGroups.decimal !== heightValueGroups.decimal ||*/ heightDefValueGroups.selector !== heightValueGroups.selector)) {

            if (heightValueGroups.selector === 'cm') {
                setOptionGroups(prewOptionsGroup => ({
                    ...prewOptionsGroup,
                    integer: range(260 - 89, 90),
                    //decimal: range(9 + 1, 0),
                }))
                setHeightValueGroups(prewValueGroups => ({
                    ...prewValueGroups,
                    integer: Math.floor(prewValueGroups.integer / ftInSm) < 90 ? 90 : Math.floor(prewValueGroups.integer / ftInSm) > 260 ? 260 : Math.floor(prewValueGroups.integer / ftInSm),
                    //integer: Math.floor((prewValueGroups.integer + (prewValueGroups.decimal / 10)) / ftInSm) < 90 ? 90 : Math.floor((prewValueGroups.integer + (prewValueGroups.decimal / 10)) / ftInSm) > 260 ? 260 : Math.floor((prewValueGroups.integer + (prewValueGroups.decimal / 10)) / ftInSm),
                    //decimal: Math.trunc((((prewValueGroups.integer + (prewValueGroups.decimal / 10)) / ftInSm) - Math.trunc((prewValueGroups.integer + (prewValueGroups.decimal / 10)) / ftInSm)) * 10) + 1,
                    selector: 'cm',
                }))

            } else {
                setOptionGroups(prewOptionsGroup => ({
                    ...prewOptionsGroup,
                    integer: range(7 - 1, 2),
                    //decimal: range(9 + 1, 0),
                }))
                setHeightValueGroups(prewValueGroups => ({
                    ...prewValueGroups,
                    integer: Math.floor(prewValueGroups.integer * ftInSm) < 2 ? 2 : Math.floor(prewValueGroups.integer * ftInSm) > 7 ? 7 : Math.floor(prewValueGroups.integer * ftInSm),
                    //integer: Math.floor((prewValueGroups.integer + (prewValueGroups.decimal / 10)) * ftInSm) < 2 ? 2 : Math.floor((prewValueGroups.integer + (prewValueGroups.decimal / 10)) * ftInSm) > 7 ? 7 : Math.floor((prewValueGroups.integer + (prewValueGroups.decimal / 10)) * ftInSm),
                    //decimal: Math.trunc((((prewValueGroups.integer + (prewValueGroups.decimal / 10)) * ftInSm) - Math.trunc((prewValueGroups.integer + (prewValueGroups.decimal / 10)) * ftInSm)) * 10) - 1,
                    selector: 'ft',
                }))
            }
        }

    }, [heightValueGroups.selector, heightDefValueGroups])


    function handleChange(name, value) {
        setHeightValueGroups(prewValueGroups => ({
            ...prewValueGroups,
            [name]: value
        }))
    }

    return (
        <div className="height">
            <InputHeading title={'Enter your height '} subtitle={''} />
            <div className="height__picker-input">
                <div className="height__picker-input-line"></div>
                {/*<p className="height__picker-input-comma">,</p>*/}
                <div className="height__picker-input-container">
                    <Picker
                        optionGroups={optionGroups}
                        valueGroups={heightValueGroups}
                        onChange={handleChange} />
                </div>


            </div>

            {/* <p>{`${heightValueGroups.integer},${heightValueGroups.decimal} ${heightValueGroups.selector}`}</p> */}

        </div>
    );
}

export default Height;
