import { useState } from "react";

import DiscontinuedMedicine from "../DiscontinuedMedicine/DiscontinuedMedicine";
import { BACK_ARROW_ICON } from "../../../assets/utils/icons";
import "./EditReasons.css";
import mainApi from "../../../assets/api/mainApi";
import { useLocation } from "react-router-dom";

function EditReasons({ drug, setDrug, setMedicine, step, setStep }) {

  const location =  useLocation()


  const [isDrugFormValid, setDrugFormValid] = useState(false);
  const [isDrugChanged, setDrugChanged] = useState(false);



  function handleContinue() {

    if (isDrugFormValid && isDrugChanged) {
      console.log(drug)
      mainApi.editReasons({
        reason: {
          lack_efficacy: drug.reasons.lackEfficacy,
          side_effects: drug.reasons.sideEffects,
          drugs_interaction: drug.reasons.drugsInteraction,
          form_of_use_reason: {
            difficulty_swallowing: drug.reasons.useFormReason.difficultySwallowing,
            bad_flavor: drug.reasons.useFormReason.badFlavor,
            other_form_of_use: drug.reasons.useFormReason.otherUseForm,
          },
          cost: drug.reasons.cost,
          aviability: drug.reasons.aviability,
          other_reason: drug.reasons.otherReasonsName,
        },
        id: drug._id
      })
        .then((res) => {
          setMedicine(prewArray => prewArray.map((item) => {
            if (item._id === drug._id) {
              return drug
            } else {
              return item
            }
          }));
          if (location.pathname === "/sign-up/disorders") {
            setStep(3);
          } else if (location.pathname === "/profile/medication-data") {
            setStep(0);
          }
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    }



    
  }

  function handleBackStep() {
    if (location.pathname === "/sign-up/disorders") {
      setStep(3);
    } else if (location.pathname === "/profile/medication-data") {
      setStep(0);
    }
  }

  return (
    <section className="edit-reasons">
      <form className="edit-reasons__form" id="form">
        <DiscontinuedMedicine
          {...{
            step,
            drug,
            setDrugFormValid,
            setDrug,
            setDrugChanged,
          }}
        />

        <div className="edit-reasons__buttons-container">
          <div className="edit-reasons__buttons-box">
            <button
              className="edit-reasons__back-button"
              type="button"
              aria-label="Go to previous page"
              onClick={handleBackStep}
            >
              {BACK_ARROW_ICON}
            </button>
            <button
              className={`edit-reasons__next-button ${!(isDrugFormValid && isDrugChanged)
                ? "edit-reasons__next-button_disabled"
                : ""
                }`}
              type="button"
              onClick={handleContinue}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </section>
  );
}

export default EditReasons;
