import { useState, useEffect } from "react";
import Input from "../../../Input/Input";
import SearchPopup from "../SearchPopup/SearchPopup";
import SearchCard from "../SearchCard/SearchCard";
import { SEARCH_ICON } from "../../../../assets/utils/icons";
import {
  CLINIC_LIST,
  DOCTORS_LIST,
} from "../../../../assets/utils/disordersInfo";
import "./Clinic.css";
import mainApi from "../../../../assets/api/mainApi";

function Clinic({ clinic, setClinic, setDisabled }) {
  const [search, setSearch] = useState({});
  const [isSearchPopupVisible, setSearchPopupVisible] = useState({});


  useEffect(() => {
    const clinicValues = Object.values(clinic);
    clinicValues.length >= 2 && clinicValues.every((el) => Boolean(el))
      ? setDisabled(false)
      : setDisabled(true);
  }, [clinic, setDisabled]);

  const [isSearchClinicPreloaderVisible, setSearchClinicPreloaderVisible] = useState(true)
  const [isSearchDoctorPreloaderVisible, setSearchDoctorPreloaderVisible] = useState(true)
  const [searchClinicHints, setSearchClinicHints] = useState([])
  const [searchDoctorHints, setSearchDoctorHints] = useState([])

  function handleSearchChange(evt) {
    const input = evt.target;
    const name = input.name;
    const value = input.value;


    setSearch({ ...search, [name]: value });
    if (value && name === 'searchClinic') {
      setSearchClinicPreloaderVisible(true)
      mainApi.searchClinic({ text: value, limit: 3 })
        .then((res) => {
          console.log(res.sources)
          setSearchClinicHints(res.sources)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setSearchClinicPreloaderVisible(false)
        })
    } else if (clinic && value && name === 'searchDoctor') {
      console.log(clinic.clinic)
      setSearchDoctorPreloaderVisible(true)
      mainApi.searchDoctor({ clinic_id: clinic.clinic._id, text: value, limit: 3 })
        .then((res) => {
          setSearchDoctorHints(res.sources)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setSearchDoctorPreloaderVisible(false)
        })
    }


  }






  function toggleSearchPopupVisibility(value, evt) {
    const name = evt.target.name;
    setSearchPopupVisible({
      ...isSearchPopupVisible,
      [name]: value,
    });
  }

  function handleClinicChange(item) {
    setSearchDoctorHints([])
    setClinic({ ...clinic, clinic: item, doctor: undefined });
  }

  function handleDoctorChange(item) {

    setClinic({ ...clinic, doctor: item });
  }

  function handleDeleteClinic(item) {
    setSearch({ searchDoctor: '' , searchClinic: '' });
    setClinic({ })
  }

  function handleDeleteDoctor(item) {
    setSearch({ ...search, searchDoctor: '' });
    setClinic({ ...clinic, doctor: undefined })
  }

  return (
    <section className="clinic">
      <p className="clinic__title">Search for your current medical institution</p>
      <form className="clinic__form" id="form">
        <div className="clinic__search-box">
          <Input
            label="Find your clinic (Doctors surgery 84 Harley Street)"
            value={search.searchClinic}
            name="searchClinic"
            type="text"
            handleChange={handleSearchChange}
            isRequired={false}
            icon={SEARCH_ICON}
            handleVisibility={toggleSearchPopupVisibility}
          />
          <SearchPopup
            isPreloaderVisible={isSearchClinicPreloaderVisible}
            searchHints={searchClinicHints}
            searchList={CLINIC_LIST}
            onSelect={handleClinicChange}
            value={clinic.clinic}
            searchValue={
              Boolean(search.searchClinic) ? search.searchClinic : ""
            }
            isSearchPopupVisible={isSearchPopupVisible.searchClinic}
          />
          {Boolean(clinic.clinic) ? (
            <SearchCard
              index="clinic"
              type="added"
              item={clinic.clinic}
              onDelete={handleDeleteClinic}
            />
          ) : null}
        </div>

        {Boolean(clinic.clinic) ? (
          <div className="clinic__search-box">
            <Input
              label="Find your doctor (Dr. Nathan Hasson)"
              value={search.searchDoctor}
              name="searchDoctor"
              type="text"
              handleChange={handleSearchChange}
              isRequired={false}
              icon={SEARCH_ICON}
              handleVisibility={toggleSearchPopupVisibility}
            />
            <SearchPopup
              isPreloaderVisible={isSearchDoctorPreloaderVisible}
              searchHints={searchDoctorHints}
              searchList={DOCTORS_LIST}
              onSelect={handleDoctorChange}
              value={clinic.doctor}
              searchValue={
                Boolean(search.searchDoctor) ? search.searchDoctor : ""
              }
              isSearchPopupVisible={isSearchPopupVisible.searchDoctor}
            />
            {Boolean(clinic.doctor) ? (
              <SearchCard
                index="doctor"
                type="added"
                item={clinic.doctor}
                onDelete={handleDeleteDoctor}
              />
            ) : null}
          </div>
        ) : null}
      </form>
    </section>
  );
}

export default Clinic;
