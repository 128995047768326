import { useEffect } from "react";
import Input from "../../../Input/Input";
import "./ClinicalResearch.css";

function ClinicalResearch({ setDisabled, values, setValues }) {
  useEffect(() => {
    if (
      values.research === "Yes"
        ? Boolean(values.researchId) && Boolean(values.research)
        : Boolean(values.research)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  });

  /*useEffect(() => {
    Boolean(values.researchId) ? setIsFocuced(true) : setIsFocuced(false);
  }, [values.researchId]);*/

  function handleChange(evt) {
    const input = evt.target;
    const name = input.name;
    const value = input.value.replace(/[^0-9]/g, "");
    setValues({ ...values, [name]: value });
  }

  function handleChoiceChange(name, value) {
    setValues({ ...values, [name]: value });
  }

  return (
    <div className="research">
      <p className="research__title">Clinical research participation</p>
      <p className="research__subtitle">
        If you are a participant in clinical research, please enter your Clinical Research ID so that we can track the effects of medication
      </p>

      <form className="research__form">
        <div className="research__participant-box">
          <p className="research__participant-title">
            Are you in any clinical research?
          </p>
          <div className="research__choice-box">
            <button
              className={`research__choice-button ${values.research === "Yes"
                  ? "research__choice-button_active"
                  : ""
                }`}
              type="button"
              onClick={() => handleChoiceChange("research", "Yes")}
            >
              Yes
            </button>
            <button
              className={`research__choice-button ${values.research === "No" ? "research__choice-button_active" : ""
                }`}
              type="button"
              onClick={() => handleChoiceChange("research", "No")}
            >
              No
            </button>
          </div>
        </div>

        {values.research === "Yes" ? (
          <Input
            label="Clinical research ID"
            boxType="research-id"
            value={values.researchId}
            name="researchId"
            type="text"
            inputMode="numeric"
            handleChange={handleChange}
            isRequired={values.research === "Yes"}
          />
        ) : null}
      </form>
    </div>
  );
}

export default ClinicalResearch;
