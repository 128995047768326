import { CHECKED_CHECKBOX_ICON } from "../../../assets/utils/icons";
import "./Checkbox.css";

function Checkbox({
  name,
  isRequired = false,
  isChecked,
  onClick,
  children,
  error,
  touched,
  valid,
  className
}) {
  return (
    <div className={`checkbox ${className ? className : ''}`}>
      <label className="checkbox__label" htmlFor={name}>
        <input
          className="checkbox__invisible-input"
          id={name}
          name={name}
          type="checkbox"
          value={isChecked}
          onChange={(evt) => onClick(evt)}
          required={isRequired}
          checked={isChecked}
        />
        <span
          className={`checkbox__pseudo-item ${
            isChecked ? "checkbox__pseudo-item_checked" : ""
          }`}
        >
          {CHECKED_CHECKBOX_ICON}
        </span>
        {children}
      </label>
      {touched && !valid ? (
        <span className="checkbox__error" id={name}>
          {error}
        </span>
      ) : null}
    </div>
  );
}

export default Checkbox;
