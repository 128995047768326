import { useEffect } from "react";
import RangeSelector from "../../../RangeSelector/RangeSelector";
import "./FunctionalPerformance.css";

function FunctionalPerformance({
  schoolPerformance,
  setSchoolPerformance,
  setDisabled,
}) {
  useEffect(() => {
    setDisabled(false);
  }, [setDisabled]);


  return (
    <div className="functional">
      <p className="functional__title">Functional performance</p>
      <div className="functional__form">
        <RangeSelector
          stepsCount={4}
          title={"Work/School performance"}
          lastValues={{ min: "failing", max: "excellent" }}
          defaultValue={schoolPerformance}
          setFinalValue={setSchoolPerformance}
        />
      </div>
    </div>
  );
}

export default FunctionalPerformance;
