import { useEffect, useRef } from "react";
import { CLOSE_ICON } from "../../../../assets/utils/icons";
import "./DeletePopup.css";

function DeletePopup({ isPopupOpen, title, closeDeletePopup, onRemove }) {
  const overlay = useRef();

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        closeDeletePopup();
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });

  return (
    <div className={`delete-popup ${isPopupOpen ? "delete-popup_opened" : ""}`}>
      <div className="delete-popup__container">
        <button
          className="delete-popup__close-button"
          type="button"
          onClick={closeDeletePopup}
        >
          {CLOSE_ICON}
        </button>
        <div className="delete-popup__content">
          <p className="delete-popup__title">{title}</p>
          <div className="delete-popup__buttons-box">
            <button
              className="delete-popup__button"
              type="button"
              onClick={closeDeletePopup}
            >
              No
            </button>

            <button
              className="delete-popup__button delete-popup__button_type_remove"
              type="button"
              onClick={onRemove}
            >
              Yes
            </button>
          </div>
        </div>
      </div>

      <div
        className="delete-popup__overlay"
        ref={overlay}
        onTouchStart={closeDeletePopup}
      />
    </div>
  );
}

export default DeletePopup;
