import "./MedCondition.css";
import RangeSelector from "../../RangeSelector/RangeSelector";


function MedCondition({ conditionRangeValue, setConditionRangeValue }) {


    return (
        <div className="med-condition">
            <p className="med-condition__title">
                General medical condition
            </p>
            <div className="med-condition__range">
                <RangeSelector stepsCount={3} title={'Condition'} lastValues={{ min: 'worse', max: 'better' }} defaultValue={conditionRangeValue} setFinalValue={setConditionRangeValue} />
            </div>

        </div>
    );
}

export default MedCondition;
