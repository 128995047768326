/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import Picker from 'react-mobile-picker';


import './DateOfBirth.css';
import '../Register.css'
import InputHeading from '../InputsTitle/InputHeading';




function DateOfBirth({dateValueGroups, setDateValueGroups}) {
    const [optionGroups, setOptionGroups] = useState({
        month: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        year: Array.from(Array(new Date().getFullYear() - 1899), (_, i) => (i + 1900).toString()),

    })

    function handleChange(name, value) {
        setDateValueGroups(prewValueGroups => ({
            ...prewValueGroups,
            [name]: value
        }))
    }


 
    return (
        <div className="date-of-birth">
            <InputHeading title={'Demographic Data'} subtitle={'Date of Birth'} />
            <div className="date-of-birth__date-input">
                <div className="date-of-birth__date-input-line"></div>
                <div className="date-of-birth__date-input-container">
                     <Picker
                    optionGroups={optionGroups}
                    valueGroups={dateValueGroups}
                    onChange={handleChange} />
                </div>
               

            </div>
            {/* <p>{moment(`${monthFormatMap[dateValueGroups.month]}.01.${dateValueGroups.year}`,'MM.DD.YYYY').format('DD MM YYYY')}</p>
<p>{`${dateValueGroups.year} ${dateValueGroups.month}`}</p> */}

        </div>
    );
}

export default DateOfBirth;
