import { useState, useEffect } from "react";
import Input from "../../../Input/Input";
import SelectInput from "../../../SelectInput/SelectInput";
import SearchPopup from "../SearchPopup/SearchPopup";
import SearchCard from "../SearchCard/SearchCard";
import DeletePopup from "../DeletePopup/DeletePopup";
import { SEARCH_ICON } from "../../../../assets/utils/icons";
import "./Symptoms.css";
import mainApi from "../../../../assets/api/mainApi";
import MiniPreloader from "../../../MiniPreloader/MiniPreloader";

function Symptoms({
  symptoms,
  setSymptoms,
  syndromeStep,
  setSyndromeStep,
  setDisabled,
  selectedItem,
  setSelectedItem,
  isInitPreloaderVisible
}) {
  const [syndromes, setSyndromes] = useState([])

  useEffect(() => {
    mainApi.getSyndromes({ limit: 25 })
      .then((res_syndromes) => {
        console.log(res_syndromes.user_syndromes)
        setSyndromes(res_syndromes.user_syndromes)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const [searchValue, setSearchValue] = useState("");
  const [isSearchPopupVisible, setSearchPopupVisible] = useState(false);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  // const [isFormValid, setFormValid] = useState(false);

  // useEffect(() => {
  //   const form = document.getElementById("form");
  //   setFormValid(form.checkValidity());
  // }, [syndromeStep, symptoms]);

  // useEffect(() => {
  //   if (syndromeStep === 0) {
  //     symptoms.length > 0 ? setDisabled(false) : setDisabled(true);
  //   } else {
  //     console.log(selectedItem)
  //     if(symptoms[index] && Boolean(symptoms[index].period) && isFormValid && selectedItem && selectedItem.diagnosis && selectedItem.diagnosis.value){
  //       setDisabled(false)
  //       console.log('sd')
  //     } else {
  //       setDisabled(true);
  //     }

  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [index, isFormValid, symptoms, syndromeStep, selectedItem]);



  const [isSearchSymptomesPreloaderVisible, setSearchSymptomesPreloaderVisible] = useState(true)
  const [searchHints, setSearchHints] = useState([])

  function handleSearchChange(evt) {

    const value = evt.target.value;

    setSearchValue(value);
    if (value) {
      setSearchSymptomesPreloaderVisible(true)
      mainApi.searchSymptomes({ text: value, limit: 3 })
        .then((res) => {
          setSearchHints(res.sources)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => [
          setSearchSymptomesPreloaderVisible(false)
        ])
    }

  }



  function toggleSearchPopupVisibility(value) {
    setSearchPopupVisible(value);
  }

  const [isSymptomPreloaderVisible, setSymptomPreloaderVisible] = useState(false)

  function handleSymptomChange(item) {
    console.log(item)
    if (!item.symptoms_id) {
      setSymptomPreloaderVisible(true)
      mainApi.getUserSymptomeById({ symptom_id: item._id })
        .then((res) => {
          console.log(res)
          let returnObj = {
            disease: {
              data: res.symptom.data,
              text: res.symptom.text,
              name: res.symptom.text,
              _id: item._id,
            },
            _id: res._id,
            symptoms_id: res.symptoms_id,
            diagnosis: {
              value: {
                _id: res.user_syndrome_id,
                text: res.syndrome.text
              }
            },
          }
          let dataResArray = res.symptom.data
          for (let index = 0; index < dataResArray.length; index++) {
            returnObj[dataResArray[index].key] = res.data[dataResArray[index].key]
            returnObj[dataResArray[index].key].type = dataResArray[index].type
          }
          setSymptoms(prewArray => (prewArray.map((item) => {
            if (item._id === res.symptoms_id) {
              return returnObj
            } else return item
          })))
          setSelectedItem({
            ...returnObj,
          })
        })
        .catch((err) => {
          console.log(err)
          setSelectedItem({
            ...item,
          })
        })
        .finally(() => {
          setSymptomPreloaderVisible(false)
        })
    } else {
      setSelectedItem({
        ...item
      })
    }



    setSyndromeStep(syndromeStep + 1);
  }

  function handleSelectChange(name, value, type) {
    console.log(selectedItem)
    setSelectedItem(prewItem => ({
      ...prewItem,
      [name]: { value, type },
    }))

  }

  function handleInputsChange(evt) {
    const input = evt.target;
    const name = input.name;

    const value = input.value.replace(/[^0-9]/g, "");
    switch (name) {
      case 'seizuresNumber':
        setSelectedItem(prewItem => ({
          ...prewItem,
          seizure_number_and_duration: {
            value: {
              ...prewItem.seizure_number_and_duration && prewItem.seizure_number_and_duration.value && prewItem.seizure_number_and_duration.value,
              amount: Number(value)
            }, type: 'seizure_number_and_duration'
          },
        }))
        break;

      case 'durationMin':
        setSelectedItem(prewItem => ({
          ...prewItem,
          seizure_number_and_duration: {
            value: {
              ...prewItem.seizure_number_and_duration && prewItem.seizure_number_and_duration.value && prewItem.seizure_number_and_duration.value,
              duration: {
                ...prewItem.seizure_number_and_duration && prewItem.seizure_number_and_duration.value && prewItem.seizure_number_and_duration.value.duration && prewItem.seizure_number_and_duration.value.duration,
                seconds: prewItem.seizure_number_and_duration && prewItem.seizure_number_and_duration.value && prewItem.seizure_number_and_duration.value.duration && prewItem.seizure_number_and_duration.value.duration.seconds ? prewItem.seizure_number_and_duration.value.duration.seconds : 0,
                minutes: Number(value) >= 0 ? Number(value) < 1441 ? Number(value) : 1440 : 0
              }
            }, type: 'seizure_number_and_duration'
          },
        }))
        break;

      case 'durationSec':
        setSelectedItem(prewItem => ({
          ...prewItem,
          seizure_number_and_duration: {
            value: {
              ...prewItem.seizure_number_and_duration && prewItem.seizure_number_and_duration.value && prewItem.seizure_number_and_duration.value,
              duration: {
                ...prewItem.seizure_number_and_duration && prewItem.seizure_number_and_duration.value && prewItem.seizure_number_and_duration.value.duration && prewItem.seizure_number_and_duration.value.duration,
                minutes: prewItem.seizure_number_and_duration && prewItem.seizure_number_and_duration.value && prewItem.seizure_number_and_duration.value.duration && prewItem.seizure_number_and_duration.value.duration.minutes ? prewItem.seizure_number_and_duration.value.duration.minutes : 0,
                seconds: Number(value) >= 0 ? Number(value) < 60 ? Number(value) : 59 : 0
              }
            }, type: 'seizure_number_and_duration'
          },
        }))
        break;

      default:
        break;
    }


  }

  useEffect(() => {
    if (syndromeStep === 0 && symptoms.length > 0) {
      setDisabled(false)
    } else if (selectedItem && selectedItem.disease.text === "Seizures") {
        console.log(symptoms, symptoms.length)
        let seizure_number_and_duration = selectedItem.seizure_number_and_duration && selectedItem.seizure_number_and_duration
        if (seizure_number_and_duration && seizure_number_and_duration.value && seizure_number_and_duration.value.duration && (seizure_number_and_duration.value.duration.seconds || seizure_number_and_duration.value.duration.minutes) && seizure_number_and_duration.value.amount && selectedItem.diagnosis && selectedItem.diagnosis.value) {
          setDisabled(false)
        } else {
          setDisabled(true)
        }
      } else {
        setDisabled(true)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, symptoms])



  function handleEditClick(item) {
    setSelectedItem(item)
    setSyndromeStep(syndromeStep + 1);
  }
  const [symptomeToDelite, setSymptomeToDelite] = useState({})
  function handleDeleteClick(item) {
    setDeletePopupOpen(true);
    console.log(item)
    setSymptomeToDelite(item)
  }

  function handleRemoveCard() {
    mainApi.deleteSymptome({ id: symptomeToDelite._id })
      .then(() => {
        setSymptoms(prewArray => prewArray.filter((item) => {
          if (item._id === symptomeToDelite._id) return false
          else return true
        }))
      })
      .catch((err) => {
        console.log(err)
      })
    closeDeletePopup();
  }

  function closeDeletePopup() {
    setDeletePopupOpen(false);
  }

  useEffect(() => {
    if (syndromeStep === 0) {
      setSearchValue('')
    }
  }, [syndromeStep])

  return (
    <section className="symptom">
      <form className="symptom__form" id="form">
        {syndromeStep === 0 ? (
          <div className="symptom__form-block">
            <p className="symptom__title">
              Search your symptoms and enter details
            </p>
            <div className="symptom__search-box">
              <Input
                label="Search for your symptoms (Seizures)"
                value={searchValue}
                name="search"
                type="text"
                handleChange={handleSearchChange}
                isRequired={false}
                icon={SEARCH_ICON}
                handleVisibility={toggleSearchPopupVisibility}
              />
              <SearchPopup
                isPreloaderVisible={isSearchSymptomesPreloaderVisible}
                onSelect={handleSymptomChange}
                value={symptoms}
                {...{ searchValue, isSearchPopupVisible }}
                searchHints={searchHints}
              />
            </div>

            <ul className="symptom__list">
              {isInitPreloaderVisible ?
                <div className="syndrome__preloader">
                  <MiniPreloader />
                </div> :
                symptoms.length > 0
                  ? symptoms.map((symptom, i) => (
                    <SearchCard
                      index={i}
                      type="added"
                      item={symptom}
                      onEdit={handleEditClick}
                      onDelete={handleDeleteClick}
                      key={`symptoms-item${i}`}
                    />
                  ))
                  : null}
            </ul>
          </div>
        ) : null}

        {syndromeStep === 1 ? (
          <div className="symptom__form-block">
            <p className="symptom__title">
              Enter details of&nbsp;your&nbsp;symptom
            </p>
            {isSymptomPreloaderVisible ?
              <div className="symptom__preloader">
                <MiniPreloader />
              </div>
              :
              <>
                {selectedItem ?
                  <>
                    <div className="symptom__card-box">
                      <SearchCard type="selected" item={selectedItem} />
                    </div>
                    <div className="symptom__inputs-box">
                      {selectedItem.disease.data && selectedItem.disease.data.length > 0 ?
                        // <div className="symptom__select-syndrome">

                        // </div>
                        <SelectInput
                          label="Syndrome causing this symptom"
                          value={selectedItem && selectedItem.diagnosis && selectedItem.diagnosis.value ? selectedItem.diagnosis.value : ''}
                          name="diagnosis"
                          isRequired={true}
                          selectList={syndromes ? syndromes.map((item) => {
                            return {
                              _id: item._id,
                              text: item.syndrome.text
                            }
                          }) : []}
                          popupTitle="Select syndrome"
                          onSelect={handleSelectChange}
                        />
                        : <></>}
                      {selectedItem.disease.data && selectedItem.disease.data.length > 0 ? selectedItem.disease.data.map((item, i) => (
                        <>
                          {item.type === 'array_choose_one' ?
                            <SelectInput
                              label={item.name}
                              value={selectedItem && selectedItem[item.key] && selectedItem[item.key].value ? selectedItem[item.key].value : ''}
                              name={item.key}
                              isRequired={true}
                              type={item.type}
                              selectList={item.value}
                              popupTitle={item.title}
                              onSelect={handleSelectChange}
                            />
                            : <></>}

                          {item.type === "seizure_number_and_duration" ?
                            <div className="symptom__numbers-block">
                              <Input
                                label="Number of seizures"
                                boxType="seizures-number"
                                value={selectedItem && selectedItem[item.key] && selectedItem[item.key].value && selectedItem[item.key].value.amount ? selectedItem[item.key].value.amount : ''}
                                name="seizuresNumber"
                                type="text"
                                inputMode="numeric"
                                handleChange={handleInputsChange}
                                isRequired={true}
                              />

                              <div className="symptom__duration-block">
                                <p className="symptom__duration-title">Seizure duration</p>
                                <div className="symptom__duration-inputs">
                                  <Input
                                    label="min"
                                    boxType="min"
                                    value={selectedItem && selectedItem[item.key] && selectedItem[item.key].value && selectedItem[item.key].value.duration && selectedItem[item.key].value.duration.minutes ? selectedItem[item.key].value.duration.minutes : ''}
                                    name="durationMin"
                                    type="text"
                                    inputMode="numeric"
                                    handleChange={handleInputsChange}
                                    isRequired={true}
                                  />

                                  <Input
                                    label="sec"
                                    boxType="sec"
                                    value={selectedItem && selectedItem[item.key] && selectedItem[item.key].value && selectedItem[item.key].value.duration && selectedItem[item.key].value.duration.seconds ? selectedItem[item.key].value.duration.seconds : ''}
                                    name="durationSec"
                                    type="text"
                                    inputMode="numeric"
                                    handleChange={handleInputsChange}
                                    isRequired={true}
                                  />
                                </div>
                              </div>
                            </div>
                            : <></>}


                        </>

                      ))
                        : <></>}

                    </div>
                  </>
                  : <></>}
              </>
            }

          </div>
        ) : null}
      </form>
      <DeletePopup
        isPopupOpen={isDeletePopupOpen}
        title={"Do you really want to remove this symptom?"}
        onRemove={handleRemoveCard}
        {...{ closeDeletePopup }}
      />
    </section>
  );
}

export default Symptoms;
