import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


import Syndromes from "./Syndromes/Syndromes";
import Symptoms from "./Symptoms/Symptoms";
import FamilyHistory from "./FamilyHistory/FamilyHistory";
import Medicine from "./Medicine/Medicine";
import NewMedicine from "../../MedicineCards/NewMedicine/NewMedicine";
import EditMedicine from "../../MedicineCards/EditMedicine/EditMedicine";
import EditSchedule from "../../MedicineCards/EditSchedule/EditSchedule";
import EditReasons from "../../MedicineCards/EditReasons/EditReasons";
import Clinic from "./Clinic/Clinic";
import FunctionalPerformance from "./FunctionalPerformance/FunctionalPerformance";
import SocialPerformance from "./SocialPerformance/SocialPerformance";
import ClinicalResearch from "./ClinicalResearch/ClinicalResearch";

import scrollPageToTop from "../../../assets/utils/scrollPageToTop";
import { BACK_ARROW_ICON } from "../../../assets/utils/icons";
import vial from "../../../assets/images/disorders/vial.png";
import yellowCapsule from "../../../assets/images/disorders/yellow-capsule.png";
import whiteCapsule from "../../../assets/images/disorders/white-capsule.png";
import doctor from "../../../assets/images/disorders/doctor.png";

import "./Disorders.css";
import mainApi from "../../../assets/api/mainApi";
import { fromatDrugFromApi } from "../../../assets/utils/utilis";

const tracker = Array(8).fill("");

function Disorders({ medicine, setMedicine, currentUser, setCurrentUser, getCurentUser }) {
  const navigate = useNavigate()
  const [step, setStep] = useState(currentUser ? currentUser.register_stage === 0 ? 0 : currentUser.register_stage + 1 : 0);





  const [isDisabled, setDisabled] = useState(true);

  //Syndromes
  const [syndromes, setSyndromes] = useState([]);
  const [syndromeStep, setSyndromeStep] = useState(0);

  //Symptoms
  const [symptoms, setSymptoms] = useState([]);

  //Medicine
  const [drugToEdit, setDrugToEdit] = useState({});

  //Clinic
  const [clinic, setClinic] = useState({});

  //Hereditary disorders in the family
  const [noDisorders, setNoDisorders] = useState(false);
  const [history, setHistory] = useState([
    {
      member: "",
      disorder: "",
      validState: false,
    },
  ]);

  //Functional performance
  const [schoolPerformance, setSchoolPerformance] = useState(1);

  //Social performance
  const [agression, setAgression] = useState(1);
  const [friendship, setFriendship] = useState(1);
  const [isolation, setIsolation] = useState(1);
  const [family, setFamily] = useState(1);

  //Clinical research participation
  const [researchParticipation, setResearchParticipation] = useState({
    research: "",
    researchId: "",
  });

  const [selectedSyndromItem, setSelectedSyndromItem] = useState(undefined)
  const [selectedSymptomItem, setSelectedSymptomItem] = useState(undefined)

  useEffect(() => {
    if (currentUser) {
      console.log(currentUser.register_stage)
      setStep(currentUser.register_stage === 2 ? 0 : currentUser.register_stage - 1)
      if (currentUser.clinic && currentUser.doctor) {
        setClinic({
          clinic: {
            _id: currentUser.clinic._id,
            disease: {
              text: currentUser.clinic.text,
            }
          },
          doctor: {
            _id: currentUser.doctor._id,
            disease: {
              text: currentUser.doctor.text,
            }
          }
        })
      }
    }
  }, [currentUser])

  function handleNextStep() {
    if (step === 4 && !isDisabled && ((currentUser && !currentUser.clinic && !currentUser.doctor) || ((currentUser && currentUser.clinic && currentUser.clinic._id !== clinic.clinic._id) || (currentUser && currentUser.doctor && currentUser.doctor._id !== clinic.doctor._id)))) {
      console.log(clinic)
      mainApi.setClinicalData({
        clinic_id: clinic.clinic._id,
        doctor_id: clinic.doctor._id
      })
        .then((res) => {
          setCurrentUser(res)
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })

    }
    if (step === 5 && !isDisabled) {
      if (history.length === 1 && history[0].member === '' && history[0].disorder === '') {
        console.log([])
        mainApi.setHereditarySyndromes({ members: [] })
          .then((res) => {
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        mainApi.setHereditarySyndromes({
          members: history.map((item) => {
            return {
              member_type: item.member,
              syndrome_name: item.disorder,
            }
          })
        })
          .then((res) => {
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }

    if (step === 6 && !isDisabled) {
      console.log(schoolPerformance - 1)
      mainApi.setInitFunctionalQuestionaire({
        school_or_work: schoolPerformance - 1
      })
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    if (step === 7 && !isDisabled) {

      mainApi.setInitSocialQuestionaire({
        aggression: agression - 1,
        friendship: friendship - 1,
        isolation: isolation - 1,
        family_relations: family - 1,
      })
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    if (step === 8 && !isDisabled) {
      console.log(researchParticipation)
      mainApi.setResearchId({ research_id: researchParticipation.research === 'No' ? null : researchParticipation.researchId })
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    switch (true) {
      case step === 1 && syndromeStep === 1:
        setSyndromeStep(syndromeStep - 1);
        console.log(selectedSyndromItem)
        if (selectedSyndromItem.syndromes_id) {
          let dataObj = {}
          let dataArray = selectedSyndromItem.disease.data
          let changesCounter = 0
          for (let index = 0; index < dataArray.length; index++) {
            dataObj[dataArray[index].key] = selectedSyndromItem[dataArray[index].key]
            dataObj[dataArray[index].key].type = dataArray[index].type
            if (syndromes.filter((item) => {
              if (item._id === selectedSyndromItem._id) {
                if (item[dataArray[index].key].value === selectedSyndromItem[dataArray[index].key].value) return false
                else return true
              } else return false
            }).length > 0) {
              changesCounter++
            }
          }
          console.log(changesCounter)
          if (changesCounter > 0) {
            mainApi.editSyndrome({
              syndrome_id: selectedSyndromItem.syndromes_id,
              id: selectedSyndromItem._id,
              data: dataObj,
            })
              .then((res) => {
                console.log(res)
                setSyndromes(prewArray => prewArray.map((item) => {
                  if (item.syndromes_id === selectedSyndromItem.syndromes_id) return selectedSyndromItem
                  else return item
                }))
              })
              .catch((err) => {
                console.log(err)
              })
          }


        } else {
          let dataObj = {}
          let dataArray = selectedSyndromItem.disease.data
          for (let index = 0; index < dataArray.length; index++) {

            dataObj[dataArray[index].key] = selectedSyndromItem[dataArray[index].key]
            dataObj[dataArray[index].key].type = dataArray[index].type
          }
          mainApi.addSyndrome({
            syndrome_id: selectedSyndromItem._id,
            data: dataObj
          })
            .then((res) => {
              console.log(res)
              let returnObj = {
                disease: {
                  data: res.syndrome.data,
                  text: res.syndrome.text,
                  name: res.syndrome.text,
                  _id: res._id,
                },
                _id: res._id,
                syndromes_id: res.syndromes_id,
              }
              let dataResArray = res.syndrome.data
              for (let index = 0; index < dataResArray.length; index++) {
                returnObj[dataResArray[index].key] = res.data[dataResArray[index].key]
                returnObj[dataResArray[index].key].type = dataResArray[index].type
              }
              setSyndromes(prewArray => [...prewArray, returnObj])
            })
            .catch((err) => {
              console.log(err)
            })
        }

        setSelectedSyndromItem(undefined)
        break;
      case step === 2 && syndromeStep === 1:
        console.log(selectedSymptomItem)
        setSyndromeStep(syndromeStep - 1);

        if (selectedSymptomItem.symptoms_id) {
          let dataObj = {}
          let dataArray = selectedSymptomItem.disease.data
          let changesCounter = 0
          for (let index = 0; index < dataArray.length; index++) {
            dataObj[dataArray[index].key] = selectedSymptomItem[dataArray[index].key]
            dataObj[dataArray[index].key].type = dataArray[index].type
            if (symptoms.filter((item) => {
              if (item._id === selectedSymptomItem._id) {
                if (item[dataArray[index].key].value === selectedSymptomItem[dataArray[index].key].value) return false
                else return true
              } else return false
            }).length > 0) {
              changesCounter++
            }
          }
          console.log(changesCounter)
          if (changesCounter > 0) {
            mainApi.editSymptome({
              symptom_id: selectedSymptomItem.symptoms_id,
              id: selectedSymptomItem._id,
              data: dataObj,
              user_syndrome_id: selectedSymptomItem.diagnosis.value._id,
            })
              .then((res) => {
                console.log(res)
                setSymptoms(prewArray => prewArray.map((item) => {
                  if (item.symptoms_id === selectedSymptomItem.symptoms_id) return selectedSymptomItem
                  else return item
                }))
              })
              .catch((err) => {
                console.log(err)
              })
          }


        } else {
          let dataObj = {}
          let dataArray = selectedSymptomItem.disease.data
          for (let index = 0; index < dataArray.length; index++) {

            dataObj[dataArray[index].key] = selectedSymptomItem[dataArray[index].key]
            dataObj[dataArray[index].key].type = dataArray[index].type
          }
          mainApi.addSymptome({
            symptom_id: selectedSymptomItem._id,
            data: dataObj,
            user_syndrome_id: selectedSymptomItem.diagnosis.value._id
          })
            .then((res) => {
              console.log(res)
              let returnObj = {
                disease: {
                  data: res.symptom.data,
                  text: res.symptom.text,
                  name: res.symptom.text,
                  _id: res._id,
                },
                _id: res._id,
                symptoms_id: res.symptoms_id,
                diagnosis: {
                  value: {
                    _id: res.user_syndrome_id,
                    text: res.syndrome.text
                  }
                },
              }
              let dataResArray = res.symptom.data
              for (let index = 0; index < dataResArray.length; index++) {
                returnObj[dataResArray[index].key] = res.data[dataResArray[index].key]
                returnObj[dataArray[index].key].type = dataResArray[index].type
              }
              setSymptoms(prewArray => [...prewArray, returnObj])
            })
            .catch((err) => {
              console.log(err)
            })
        }

        setSelectedSyndromItem(undefined)
        break;
      default:
        setStep(step + 1);
        break;
    }
  }

  function handleBackStep() {
    if (syndromeStep !== 0) {
      setSyndromeStep(0)
    } else {
      setStep(step - 1);
    }

  }

  useEffect(() => {
    console.log(step)
    scrollPageToTop();
  }, [step, syndromeStep]);


  const [isSyndromeInitPreloaderVisible, setSyndromeInitPreloaderVisible] = useState(false)
  const [isSymptomeInitPreloaderVisible, setSymptomeInitPreloaderVisible] = useState(false)
  const [isDrugsInitPreloaderVisible, setDrugsInitPreloaderVisible] = useState(false)

  useEffect(() => {
    switch (step) {
      case 1:
        setSyndromeInitPreloaderVisible(true)
        mainApi.getSyndromes({ limit: 10 })
          .then((res) => {
            console.log(res.user_syndromes)
            setSyndromes(res.user_syndromes.map((item) => {
              let returnObj = {
                disease: {
                  data: item.syndrome.data,
                  text: item.syndrome.text,
                  name: item.syndrome.text,
                  _id: item._id,
                },
                _id: item._id,
                syndromes_id: item.syndromes_id,
              }
              let dataResArray = item.syndrome.data
              for (let index = 0; index < dataResArray.length; index++) {
                returnObj[dataResArray[index].key] = item.data[dataResArray[index].key]
              }
              console.log({ returnObj })
              return returnObj
            }))
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            setSyndromeInitPreloaderVisible(false)
          })

        break;
      case 2:
        setSymptomeInitPreloaderVisible(true)
        mainApi.getSymptomes({ limit: 10 })
          .then((res) => {
            console.log(res)
            setSymptoms(res.user_symptoms.map((item) => {
              let returnObj = {
                disease: {
                  data: item.symptom.data,
                  text: item.symptom.text,
                  name: item.symptom.text,
                  _id: item._id,
                },
                _id: item._id,
                symptoms_id: item.symptoms_id,
                diagnosis: {
                  value: {
                    _id: item.user_syndrome_id,
                    text: item.syndrome.text
                  }
                },
              }
              let dataResArray = item.symptom.data
              for (let index = 0; index < dataResArray.length; index++) {
                returnObj[dataResArray[index].key] = item.data[dataResArray[index].key]
              }
              console.log({ returnObj })
              return returnObj
            }))
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            setSymptomeInitPreloaderVisible(false)
          })

        break;

      case 3:
        setDrugsInitPreloaderVisible(true)
        mainApi.getDrugs({ still_taking: true, limit: 10 })
          .then((res) => {
            console.log(res.drugs)
            mainApi.getDrugs({ still_taking: false, limit: 10 })
              .then((res2) => {
                console.log(res2.drugs)
                setMedicine(res.drugs.map((item) => {
                  return fromatDrugFromApi(item)
                }).concat(res2.drugs.map((item) => {
                  return fromatDrugFromApi(item)
                })))
              })
              .catch((err) => {
                console.log(err)
              })
              .finally(() => {
                setDrugsInitPreloaderVisible(false)
              })
          })
          .catch((err) => {
            console.log(err)
          })

        break;

      case 5:

        mainApi.getHereditarySyndromes()
          .then((res) => {
            setHistory(res.members.length > 0 ? res.members.map((item) => {
              return {
                member: item.member_type,
                disorder: item.syndrome_name,
                validState: true,
              }
            }) : [{
              member: "",
              disorder: "",
              validState: false,
            }])
            setNoDisorders(res.members.length === 0)
            console.log(res.members)
          })
          .catch((err) => {
            console.log(err)
          })

        break;

      case 6:

        mainApi.gerInitQuestionaire({ type: 'functional_performance' })
          .then((res) => {
            console.log(res)
            if (Object.keys(res.data).length !== 0) {
              if (res.data.school_or_work) {
                setSchoolPerformance(res.data.school_or_work + 1)
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })

        break;

      case 7:

        mainApi.gerInitQuestionaire({ type: 'social_performance' })
          .then((res) => {
            console.log(res.data)
            if (Object.keys(res.data).length !== 0) {
              if (res.data.aggression) {
                setAgression(res.data.aggression + 1)
              }
              if (res.data.family_relations) {
                setFamily(res.data.family_relations + 1)
              }
              if (res.data.friendship) {
                setFriendship(res.data.friendship + 1)
              }
              if (res.data.isolation) {
                setIsolation(res.data.isolation + 1)
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })

        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])



  return (
    <section
      className={`disorders ${step === 0 ? "disorders_type_start" : ""} ${step === 9 ? "disorders_type_finale" : ""
        }`}
    >
      {step === 0 ? (
        <div className="disorders__start-page">
          <div className="disorders__image-block">
            <img
              className="disorders__yellow-capsule"
              src={yellowCapsule}
              alt="Yellow capsule"
            />
            <img className="disorders__vial" src={vial} alt="Small vial" />
            <img
              className="disorders__white-capsule"
              src={whiteCapsule}
              alt="White capsule"
            />
          </div>
          <div className="disorders__text-block">
            <p className="disorders__title">
              Let&nbsp;us&nbsp;know what&nbsp;disorders you&nbsp;have
            </p>
            <p className="disorders__subtitle">
              Please enter the medicine you are taking and your current physical condition
            </p>
            <button
              className="disorders__next-button disorders__next-button_type_start"
              type="button"
              onClick={() => setStep(step + 1)}
            >
              Add info
            </button>
          </div>
        </div>
      ) : null}

      {step !== 0 && step !== 9 && typeof step !== "string" ? (
        <div className="disorders__container">
          <div className="disorders__step-tracker">
            {tracker.map((item, i) => (
              <div
                className={`disorders__tracker-item ${step === i + 1 ? "disorders__tracker-item_active" : ""
                  }`}
                key={i}
              />
            ))}
          </div>

          {step === 1 ? (
            <Syndromes
              selectedItem={selectedSyndromItem} setSelectedItem={setSelectedSyndromItem}
              isInitPreloaderVisible={isSyndromeInitPreloaderVisible}
              {...{
                syndromes,
                setSyndromes,
                syndromeStep,
                setSyndromeStep,
                setDisabled,
              }}
            />
          ) : null}

          {step === 2 ? (
            <Symptoms
              selectedItem={selectedSymptomItem} setSelectedItem={setSelectedSymptomItem}
              isInitPreloaderVisible={isSymptomeInitPreloaderVisible}
              {...{
                symptoms,
                setSymptoms,
                syndromeStep,
                setSyndromeStep,
                setDisabled,
              }}
            />
          ) : null}

          {step === 3 ? (
            <Medicine
              isInitPreloaderVisible={isDrugsInitPreloaderVisible}
              {...{
                setDisabled,
                medicine,
                setMedicine,
                setStep,
                setDrugToEdit,
              }}
            />
          ) : null}

          {step === 4 ? (
            <Clinic
              {...{
                setDisabled,
                clinic,
                setClinic,
              }}
            />
          ) : null}

          {step === 5 ? (
            <FamilyHistory
              {...{
                setDisabled,
                history,
                setHistory,
                noDisorders,
                setNoDisorders,
              }}
            />
          ) : null}

          {step === 6 ? (
            <FunctionalPerformance
              {...{ schoolPerformance, setSchoolPerformance, setDisabled }}
            />
          ) : null}

          {step === 7 ? (
            <SocialPerformance
              {...{
                agression,
                setAgression,
                friendship,
                setFriendship,
                isolation,
                setIsolation,
                family,
                setFamily,
                setDisabled,
              }}
            />
          ) : null}

          {step === 8 ? (
            <ClinicalResearch
              values={researchParticipation}
              setValues={setResearchParticipation}
              {...{ setDisabled }}
            />
          ) : null}

          <div className="disorders__buttons-block">
            <div
              className={`disorders__buttons-box ${step === 1 && syndromeStep === 0
                ? "disorders__buttons-box_type_disorders-list"
                : ""
                }`}
            >
              {step !== 0 && !(step === 1 && syndromeStep === 0) ? (
                <button
                  className="disorders__back-button"
                  type="button"
                  onClick={handleBackStep}
                >
                  {BACK_ARROW_ICON}
                </button>
              ) : null}
              <button
                className={`disorders__next-button ${isDisabled ? "disorders__next-button_disabled" : ""
                  }`}
                type="button"
                onClick={!isDisabled ? () => handleNextStep() : undefined}
              >
                {step === 8
                  ? "Done"
                  : (step === 1 && syndromeStep === 1) ||
                    (step === 2 && syndromeStep === 1) ||
                    step === 3
                    ? "Next"
                    : "Continue"}
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {step === "new-current-medicine" ||
        step === "new-discontinued-medicine" ? (
        <NewMedicine {...{ medicine, setMedicine, step, setStep }} />
      ) : null}

      {step === "edit-medicine" ? (
        <EditMedicine
          drug={drugToEdit}
          setDrug={setDrugToEdit}
          {...{ medicine, setMedicine, setStep }}
        />
      ) : null}

      {step === "edit-schedule" ? (
        <EditSchedule
          drug={drugToEdit}
          setDrug={setDrugToEdit}
          {...{ medicine, setMedicine, setStep }}
        />
      ) : null}

      {step === "edit-reasons" ? (
        <EditReasons
          drug={drugToEdit}
          setDrug={setDrugToEdit}
          {...{ medicine, setMedicine, step, setStep }}
        />
      ) : null}

      {step === 9 ? (
        <div className="disorders__finale-page">
          <img
            className="disorders__doctor-image"
            src={doctor}
            alt="Encouraging doctor"
          />
          <div className="disorders__finale-text-block">
            <p className="disorders__finale-title">You are ready to&nbsp;go!</p>
            <p className="disorders__finale-subtitle">
              Thank you for taking the time to create an account with us. Now comes the fun part - let's explore the app!
            </p>
            <p
              className="disorders__next-button disorders__next-button_type_finale"
              onClick={() => {
                navigate('/')
                getCurentUser()
              }}
            >
              Great!
            </p>
          </div>
        </div>
      ) : null}
    </section>
  );
}

export default Disorders;
