import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../Input/Input";
import SelectInput from "../../SelectInput/SelectInput";
import { SEX_LIST } from "../../../assets/utils/disordersInfo";
import {
  HIDDEN_PASSWORD_ICON,
  VISIBLE_PASSWORD_ICON,
  BACK_ARROW_ICON,
} from "../../../assets/utils/icons";
import "./PersonalData.css";
import mainApi from "../../../assets/api/mainApi";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";

function PersonalData({
  countryPopupOpened,
  setCountryPopupOpened,
  countryValue,
  setCountryValue,
  currentUser,
  setCurrentUser,
}) {
  const navigate = useNavigate();
  const [personalData, setPersonalData] = useState({
    firstName: currentUser ? currentUser.name : '',
    lastName: currentUser ? currentUser.last_name : '',
    email: currentUser ? currentUser.email : '',
    gender: currentUser ? currentUser.gender : '',
  });
  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [passValidity, setPassValidity] = useState({});
  const [isPassVisible, setPassVisible] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [isFormValid, setFormValid] = useState(false);
  const [isDataFormValid, setDataFormValid] = useState(false);

  const [submitError, setSubmitError] = useState(false)


  useEffect(() => {
    const form = document.getElementById("form");
    setFormValid(form.checkValidity());
  }, [personalData, countryValue]);

  useEffect(() => {
    const passValues = Object.values(passwords);
    const validityValues = Object.values(passValidity);
    if (submitError) {
      setDataFormValid(false)
    } else {
      if (
        Boolean(personalData.gender) &&
        Boolean(personalData.email) &&
        Boolean(countryValue) &&
        isFormValid &&
        (personalData.firstName !== currentUser.name || personalData.lastName !== currentUser.last_name || personalData.email !== currentUser.email || personalData.gender !== currentUser.gender || countryValue !== currentUser.country)
      ) {
        if (passValues.some((el) => Boolean(el))) {
          validityValues.length === passValues.length &&
            validityValues.every((el) => el.validState)
            ? setDataFormValid(true)
            : setDataFormValid(false);
        } else {
          setDataFormValid(true);
        }
      } else {
        if (passValues.some((el) => Boolean(el))) {
          validityValues.length === passValues.length &&
            validityValues.every((el) => el.validState)
            ? setDataFormValid(true)
            : setDataFormValid(false);
        } else {
          setDataFormValid(false);
        }

      }
    }

  }, [countryValue, isFormValid, passValidity, passwords, personalData, currentUser, submitError]);

  function handlePersonalChange(evt) {
    const input = evt.target;
    const name = input.name;
    const value = input.value;
    setPersonalData({ ...personalData, [name]: value });
  }

  function handleSelectChange(name, value) {
    setPersonalData({ ...personalData, [name]: value });
  }

  function handlePasswordChange(evt) {
    const input = evt.target;
    const name = input.name;
    const value = input.value.replace(/\s/g, "");
    setPasswords({ ...passwords, [name]: value });

    if (!value) {
      setPassValidity({
        ...passValidity,
        [name]: {
          errorMessage: "",
          validState: false,
        },
      });
    } else if (name === "confirmPassword") {
      if (value === passwords.newPassword) {
        setPassValidity({
          ...passValidity,
          [name]: {
            errorMessage: "",
            validState: true,
          },
        });
      } else {
        setPassValidity({
          ...passValidity,
          [name]: {
            errorMessage: "The value must match the new password",
            validState: false,
          },
        });
      }
    } else {
      if (value.length >= 8) {
        setPassValidity({
          ...passValidity,
          [name]: {
            errorMessage: "",
            validState: true,
          },
        });
      } else {
        setPassValidity({
          ...passValidity,
          [name]: {
            errorMessage: "Minimum 8 characters",
            validState: false,
          },
        });
      }
    }
  }

  function handlePassVisibleClick(name) {
    setPassVisible({ ...isPassVisible, [name]: !isPassVisible[name] });
  }

  const [isPreloaderVisible, setPreloaderVisible] = useState(false)


  function handleSaveButtonClick(evt) {
    evt.preventDefault();
    if (!isPreloaderVisible) {
      setPreloaderVisible(true)
      console.log(personalData, passwords)
      let reqObj = {
        country: countryValue !== currentUser.country ? countryValue : null,
        gender: personalData.gender.toLowerCase() !== currentUser.gender.toLowerCase() ? personalData.gender.toLowerCase() : null,
        name: personalData.firstName !== currentUser.name ? personalData.firstName : null,
        last_name: personalData.lastName !== currentUser.last_name ? personalData.lastName : null,
        current_password: Object.values(passwords).some((el) => Boolean(el)) && Object.values(passValidity).length === Object.values(passwords).length && Object.values(passValidity).every((el) => el.validState) ? passwords.oldPassword : null,
        password: Object.values(passwords).some((el) => Boolean(el)) && Object.values(passValidity).length === Object.values(passwords).length && Object.values(passValidity).every((el) => el.validState) ? passwords.newPassword : null,
      }
      console.log(reqObj)

      mainApi.editPersonalData({ data: reqObj })
        .then((res) => {
          console.log(res)
          setCurrentUser(res)
          navigate("/profile");
        })
        .catch((err) => {
          console.log(err)
          if (err.detail) {
            setSubmitError(err.detail)
            if(err.detail === 'Wrong current password'){
              setPassValidity(prevItem => ({
                ...prevItem,
                oldPassword: {
                  errorMessage: "Wrong current password",
                  validState: false,
                },
              }))
            }
          } else {
            setSubmitError('Something went wrong')
          }
          
        })
        .finally(() => {
          setPreloaderVisible(false)
          setTimeout(() => {
            setSubmitError('')
          }, 5000);
        })
    }


  }

  return (
    <section className="personal">
      <div className="personal__content">
        <p className="personal__title">Personal data</p>
        <form
          className="personal__form"
          id="form"
          onSubmit={handleSaveButtonClick}
        >
          <div className="personal__info-block">
            <Input
              label="First name"
              boxType="first-name"
              value={personalData.firstName}
              name="firstName"
              type="text"
              handleChange={handlePersonalChange}
              isRequired={true}
            />

            <Input
              label="Last name"
              boxType="last-name"
              value={personalData.lastName}
              name="lastName"
              type="text"
              handleChange={handlePersonalChange}
              isRequired={true}
            />

            <div className="personal__block-with-button">
              <Input
                label="Email"
                value={personalData.email}
                name="email"
                type="text"
                isRequired={true}
                readOnly={true}
              />
              <Link className="personal__redirect-button" to="/change-email">
                change email
              </Link>
              
            </div>

            <SelectInput
              label="Select country"
              value={countryValue}
              name="country"
              isRequired={true}
              {...{ countryPopupOpened, setCountryPopupOpened }}
            />

            <ul className="personal__select-card-list">
              {SEX_LIST.map((sex) => (
                <li
                  className={`personal__select-card ${sex.gender.toLowerCase() === personalData.gender.toLowerCase()
                    ? "personal__select-card_selected"
                    : ""
                    }`}
                  key={sex.gender}
                  onClick={() => handleSelectChange("gender", sex.gender)}
                >
                  <div className="personal__card-icon">{sex.icon}</div>
                  <p className="personal__card-text">{sex.gender}</p>
                </li>
              ))}
            </ul>
          </div>

          <div className="personal__password-block">
            <p className="personal__title">Change you password</p>
            <div className="personal__info-block">
              <div className="personal__block-with-button">
                <Input
                  label="Password"
                  value={passwords.oldPassword}
                  name="oldPassword"
                  type={isPassVisible.oldPassword ? "text" : "password"}
                  handleChange={handlePasswordChange}
                  isRequired={false}
                  icon={
                    isPassVisible.oldPassword
                      ? VISIBLE_PASSWORD_ICON
                      : HIDDEN_PASSWORD_ICON
                  }
                  handleVisibility={handlePassVisibleClick}
                  error={passValidity.oldPassword}
                />
                <Link
                  className="personal__redirect-button"
                  to="/change-password"
                >
                  forgot password?
                </Link>
              </div>

              <Input
                label="New password"
                value={passwords.newPassword}
                name="newPassword"
                type={isPassVisible.newPassword ? "text" : "password"}
                handleChange={handlePasswordChange}
                isRequired={false}
                icon={
                  isPassVisible.newPassword
                    ? VISIBLE_PASSWORD_ICON
                    : HIDDEN_PASSWORD_ICON
                }
                handleVisibility={handlePassVisibleClick}
                error={passValidity.newPassword}
              />

              <Input
                label="Repeat new password"
                value={passwords.confirmPassword}
                name="confirmPassword"
                type={isPassVisible.confirmPassword ? "text" : "password"}
                handleChange={handlePasswordChange}
                isRequired={false}
                icon={
                  isPassVisible.confirmPassword
                    ? VISIBLE_PASSWORD_ICON
                    : HIDDEN_PASSWORD_ICON
                }
                handleVisibility={handlePassVisibleClick}
                error={passValidity.confirmPassword}
              />
              <p className="personal__submit-error">{submitError}</p>
            </div>
          </div>

          <div className="personal__buttons-container">
            <div className="personal__buttons-box">
              <button
                className="personal__back-button"
                type="button"
                aria-label="Go to previous page"
                onClick={() => navigate(-1)}
              >
                {BACK_ARROW_ICON}
              </button>
              <button
                className={`personal__save-button ${!isDataFormValid ? "personal__save-button_disabled" : ""
                  }`}
                type="submit"
              >
                {isPreloaderVisible ? <MiniPreloader isLinkColor={true} /> : 'Save changes'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}

export default PersonalData;
