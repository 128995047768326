import { useState, useEffect, useRef } from "react";
import IntakeForm from "../IntakeForm/IntakeForm";
import { BACK_ARROW_ICON } from "../../../assets/utils/icons";
import "./EditSchedule.css";
import mainApi from "../../../assets/api/mainApi";
import moment from "moment";
import { useLocation } from "react-router-dom";

function EditSchedule({ drug, setDrug, setMedicine, setStep, getCurentUser }) {
  const location = useLocation()

  const [prevDrug, setPrevDrug] = useState(0);
  const [isFormValid, setFormValid] = useState(false);
  const [isDrugFormValid, setDrugFormValid] = useState(false);
  const [isDrugChanged, setDrugChanged] = useState(false);
  const [index, setIndex] = useState(0);

  const [isPreloaderVisible, setPreloaderVisible] = useState(false)

  useEffect(() => {
    console.log(drug)
    if (drug) {
      setPrevDrug(drug);
      setPreloaderVisible(true)
      mainApi.getDrugIntakes({
        medicine_id: drug._id,
        limit: 15,
      })
        .then((res) => {
          console.log(res.medicine_intakes.map((item) => {
            let time = moment(`${item.intake_time}`)
            return {
              _id: item._id,
              dose: item.dose_amount,
              exactTime: {
                hours: time.format('HH'),
                min: time.format('mm'),
              }
            }
          }))
          setDrug(prewItem => ({
            ...prewItem,
            intakeTime: res.medicine_intakes.map((item) => {
              let time = moment(`${item.intake_time}`)
              return {
                _id: item._id,
                dose: item.dose_amount,
                exactTime: {
                  hours: time.format('HH'),
                  min: time.format('mm'),
                }
              }
            })
          }))
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setPreloaderVisible(false)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChange(evt) {

    const input = evt.target;
    const name = input.name;

    const value = input.value.replace(/[^0-9]/g, "");
    
    setDrug({ ...drug, [name]: value });
    setDrugChanged(true);
  }

  function handleSelectChange(name, value, type) {
    if (name === "days") {
      let newArr = drug.days.slice();
      newArr.includes(value)
        ? (newArr = newArr.filter((el) => el !== value))
        : newArr.push(value);
      setDrug({ ...drug, [name]: newArr });
    } else {
      if (name === 'frequency') {
        if (listInnerRef && listInnerRef.current) {
          listInnerRef.current.scrollTo(0, 0)
        }
        setPrevScrollPosition(0)
        setPageValue(0)
        if (prevDrug.frequency === value) {
          mainApi.getDrugIntakes({
            medicine_id: drug._id,
            limit: 15,
          })
            .then((res) => {
              console.log(res.medicine_intakes.map((item) => {
                let time = moment(`${item.intake_time}`)
                return {
                  _id: item._id,
                  dose: item.dose_amount,
                  exactTime: {
                    hours: time.format('HH'),
                    min: time.format('mm'),
                  }
                }
              }))
              setDrug(prewItem => ({
                ...prewItem,
                intakesPerDay: prevDrug.intakesPerDay,
                days: prevDrug.days,
                [name]: value,
                total_intakes: res.medicine_intakes.total_intakes,
                intakeTime: res.medicine_intakes.map((item) => {
                  let time = moment(`${item.intake_time}`)
                  return {
                    _id: item._id,
                    dose: item.dose_amount,
                    exactTime: {
                      hours: time.format('HH'),
                      min: time.format('mm'),
                    }
                  }
                })
              }))
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          setDrug({ ...drug, intakeTime: [], intakesPerDay: '', days: [], [name]: value, total_intakes: 0 })
        }

      } else if (name === "type") {
        setDrug({ ...drug, [name]: value.type, icon: value.image });
      } else {
        setDrug({ ...drug, [name]: value });
      }
    }
    setDrugChanged(true);
  }

  function handleContinue() {


    if (isDrugFormValid && isDrugChanged) {
      if (listInnerRef && listInnerRef.current) {
        listInnerRef.current.scrollTo(0, 0)
      }

      setPrevScrollPosition(0)
      setPageValue(0)
      console.log({ prevDrug, drug, isFormValid })
      if (prevDrug.frequency === drug.frequency) {

        if (drug.frequency === 'Specific days' && drug.days.filter((item) => {
          if (prevDrug.days.indexOf(item) < 0) return true
          else return false
        }).length > 0) {
          mainApi.editIntakeDaysList({
            medicine_id: drug._id,
            days_list: drug.days.length === 0 || !drug.continued ? null : {
              mon: drug.days.indexOf('Mon') >= 0,
              tue: drug.days.indexOf('Tue') >= 0,
              wed: drug.days.indexOf('Wed') >= 0,
              thu: drug.days.indexOf('Thu') >= 0,
              fri: drug.days.indexOf('Fri') >= 0,
              sat: drug.days.indexOf('Sat') >= 0,
              sun: drug.days.indexOf('Sun') >= 0,
            },
          })
            .then((res) => {
              setMedicine(prevItem => (prevItem.map((item) => {
                if (item._id === drug._id) return drug
                else return item
              })))
              if (location.pathname === "/sign-up/disorders") {
                setStep(3);
              } else if (location.pathname === "/profile/medication-data") {
                setStep(0);
                getCurentUser()
              }
              console.log(res)
            })
            .catch((err) => {
              console.log(err)
            })
        }
        else {
          if (location.pathname === "/sign-up/disorders") {
            setStep(3);
          } else if (location.pathname === "/profile/medication-data") {
            setStep(0);
            getCurentUser()
          }
        }
      } else {
        mainApi.setNewIntakeTime({
          frequency: drug.frequency === 'Specific days' ? 'specific_days' : drug.frequency === 'Every day' ? 'everyday' : 'one_time_intake',
          days_list: drug.days.length === 0 || !drug.continued ? null : {
            mon: drug.days.indexOf('Mon') >= 0,
            tue: drug.days.indexOf('Tue') >= 0,
            wed: drug.days.indexOf('Wed') >= 0,
            thu: drug.days.indexOf('Thu') >= 0,
            fri: drug.days.indexOf('Fri') >= 0,
            sat: drug.days.indexOf('Sat') >= 0,
            sun: drug.days.indexOf('Sun') >= 0,
          },
          intake_times: drug.intakeTime.length === 0 ? null : drug.intakeTime.map((item) => {
            return {
              intake_time: {
                hours: Number(item.exactTime.hours),
                minutes: Number(item.exactTime.min),
              },
              dose_amount: item.dose
            }
          }),
          medicine_id: drug._id
        })
          .then((res) => {
            setMedicine(prevItem => (prevItem.map((item) => {
              if (item._id === drug._id) return drug
              else return item
            })))
            if (location.pathname === "/sign-up/disorders") {
              setStep(3);
            } else if (location.pathname === "/profile/medication-data") {
              setStep(0);
              getCurentUser()
            }
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
      }
      // const newMedicine = medicine.slice();
      // newMedicine[drugIndex] = drug;
      // setMedicine(newMedicine);

    }


  }

  function handleBackStep() {
    if (location.pathname === "/sign-up/disorders") {
      setStep(3);
    } else if (location.pathname === "/profile/medication-data") {
      setStep(0);
    }
  }

  const listInnerRef = useRef();

  const [prevScrollPosition, setPrevScrollPosition] = useState(-1);
  const [pageValue, setPageValue] = useState(0);

  const [scrollPosition, setScrollPosition] = useState(0);
  // const [isPreloaderVisible, setPreloaderVisible] = useState(false)
  const [scrollTraking, setScrollTraking] = useState(true);
  // const handleScroll = () => {

  //   const position = listInnerRef.current.scrollTop;
  //   console.log(listInnerRef.current.scrollTop)

  //   setScrollPosition(position);
  // };




  useEffect(() => {
    // console.log(prevScrollPosition)

    if (listInnerRef.current && scrollTraking && scrollPosition > prevScrollPosition && drug.intakeTime && drug.intakeTime.length > 0) {
      setPrevScrollPosition(scrollPosition)
      const { scrollHeight, clientHeight } = listInnerRef.current;

      if (scrollHeight - clientHeight - 260 <= scrollPosition) {
        console.log('e')
        setScrollTraking(false)
        setPageValue(pageValue + 1)
        setTimeout(() => {
          setScrollTraking(true)
        }, 500);
      }
    }
  }, [scrollPosition, scrollTraking, prevScrollPosition, pageValue, drug]);

  useEffect(() => {

    if (pageValue > 0 && drug.intakeTime && drug.intakeTime.length === 15 * pageValue) {
      let prevId = drug.intakeTime[drug.intakeTime.length - 1]._id
      mainApi.getDrugIntakes({
        medicine_id: drug._id,
        last_id: prevId,
        limit: 15
      })
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })

      // mainApi.getPatients({ limit: 20, last_id: prevId })
      //   .then((res) => {
      //     console.log(res.patients)
      //     setPatients(prewList => prewList.concat(res.patients))
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //   })
      // setPreloaderVisible(true)
      // mainApi.getMedicineInfoIndexed({ medication_id: medicine._id, user_id: patientId, limit: 15, last_id: prevId, last_date: lastDate })
      //   .then((res) => {
      //     console.log(res.medicine_intakes)
      //     setIndexedIntakes(prewList => prewList.concat(res.medicine_intakes))
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //   })
      //   .finally(() => {
      //     setPreloaderVisible(false)
      //   })

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageValue, drug])





  return (
    <section className="edit-schedule">
      <form className="edit-schedule__form" id="form">
        <IntakeForm
          setScrollPosition={setScrollPosition}
          isEdit={true}
          isPreloaderVisible={isPreloaderVisible}
          listInnerRef={listInnerRef}
          {...{
            prevDrug,
            drug,
            index,
            isFormValid,
            handleChange,
            handleSelectChange,
            setFormValid,
            setDrugFormValid,
            setIndex,
            setDrug,
            setPrevScrollPosition,
            setPageValue,
            setDrugChanged
          }}
        />

        <div className="edit-schedule__buttons-container">
          <div className="edit-schedule__buttons-box">
            <button
              className="edit-schedule__back-button"
              type="button"
              aria-label="Go to previous page"
              onClick={handleBackStep}
            >
              {BACK_ARROW_ICON}
            </button>
            <button
              className={`edit-schedule__next-button ${!(isDrugFormValid && isDrugChanged)
                ? "edit-schedule__next-button_disabled"
                : ""
                }`}
              type="button"
              onClick={handleContinue}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </section>
  );
}

export default EditSchedule;
