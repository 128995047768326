import "./SeizureChange.css";
import RangeSelector from "../../RangeSelector/RangeSelector";


function SeizureChange({ frequencyRangeValue, setFrequencyRangeValue, severityRangeValue, setSeverityRangeValue, durationRangeValue, setDurationRangeValue}) {


    return (
        <div className="seizure-change">
            <p className="seizure-change__title">
                Change of seizures since previous report
            </p>
            <div className="seizure-change__range">
                <RangeSelector stepsCount={3} title={'Frequency'} lastValues={{ min: 'more often', max: 'fewer' }} defaultValue={frequencyRangeValue} setFinalValue={setFrequencyRangeValue} />
            </div>
            <div className="seizure-change__range">
                <RangeSelector stepsCount={5} title={'Severity'} lastValues={{ min: 'very strong', max: 'light' }} defaultValue={severityRangeValue} setFinalValue={setSeverityRangeValue} />
            </div>
            <div className="seizure-change__range">
                <RangeSelector stepsCount={5} title={'Duration'} lastValues={{ min: 'longer', max: 'shorter' }} defaultValue={durationRangeValue} setFinalValue={setDurationRangeValue} />
            </div>
        </div>
    );
}

export default SeizureChange;
