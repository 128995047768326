import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MedicineForm from "../MedicineForm/MedicineForm";
import IntakeForm from "../IntakeForm/IntakeForm";
import DiscontinuedMedicine from "../DiscontinuedMedicine/DiscontinuedMedicine";
import { BACK_ARROW_ICON } from "../../../assets/utils/icons";
import "./NewMedicine.css";
import mainApi from "../../../assets/api/mainApi";

function NewMedicine({ medicine, setMedicine, step, setStep, getCurentUser }) {
  const location = useLocation();
  const [drugStep, setDrugStep] = useState(0);
  const [drug, setDrug] = useState({
    continued: step === "new-current-medicine",
    diagnosis: "",
    name: "",
    dosage: "",
    measure: "mg",
    duration: "",
    icon: "",
    foodIntake: "",
    frequency: "",
    days: [],
    intakesPerDay: "",
    intakeTime: [],
    reasons: {
      lackEfficacy: false,
      sideEffects: false,
      drugsInteraction: false,
      useForm: false,
      useFormReason: {
        difficultySwallowing: false,
        badFlavor: false,
        otherUseForm: false,
      },
      cost: false,
      aviablity: false,
      otherReasons: false,
      otherReasonsName: "",
    },
  });
  const [index, setIndex] = useState(0);
  const [isFormValid, setFormValid] = useState(false);
  const [isDrugFormValid, setDrugFormValid] = useState(false);

  function handleChange(evt) {
    const input = evt.target;
    const name = input.name;
    let value =
      input.type === "checkbox"
        ? input.checked
        : input.inputMode === "numeric"
          ? input.value.replace(/[^0-9]/g, "")
          : input.value;
    if (name === 'intakesPerDay') {
      value = Number(value) > 50 ? 50 : value
    }
    if (name === 'duration') {
      value = Number(value) > 7000 ? 7000 : value
    }
    setDrug({
      ...drug,
      [name]: value,
    });
  }

  function handleSelectChange(name, value) {

    if (name === "days") {

      let newArr = drug.days.slice();
      newArr.includes(value)
        ? (newArr = newArr.filter((el) => el !== value))
        : newArr.push(value);
      setDrug({ ...drug, [name]: newArr });
    } else {
      if (name === 'frequency') {
        setDrug({ ...drug, intakeTime: [], intakesPerDay: '', days: [], [name]: value, total_intakes: 0 })
      } else {
        setDrug({ ...drug, [name]: value })
      }

    }
  }


  function handleContinue() {
    if (drugStep === 0 && isDrugFormValid) {
      setDrugStep(drugStep + 1);
    } else if (drugStep === 1 && isDrugFormValid) {
      console.log({
        drug,
        name: drug.name,
        type: drug.type,
        dosage: Number(drug.dosage),
        dosage_type: drug.measure,
        dosage_days: Number(drug.duration),
        food_time: drug.foodIntake === 'before food' ? 'before' : drug.foodIntake === 'after food' ? 'after' : drug.foodIntake === 'with food' ? 'with' : drug.foodIntake,
        frequency: drug.frequency === 'Specific days' ? 'specific_days' : drug.frequency === 'Every day' ? 'everyday' : 'one_time_intake',
        days_list: {
          mon: drug.days.indexOf('Mon') >= 0,
          tue: drug.days.indexOf('Tue') >= 0,
          wed: drug.days.indexOf('Wed') >= 0,
          thu: drug.days.indexOf('Thu') >= 0,
          fri: drug.days.indexOf('Fri') >= 0,
          sat: drug.days.indexOf('Sat') >= 0,
          sun: drug.days.indexOf('Sun') >= 0,
        },
        intake_times: drug.intakeTime.map((item) => {
          return {
            intake_time: {
              hours: Number(item.exactTime.hours),
              minutes: Number(item.exactTime.min),
            },
            dose_amount: item.dose
          }
        }),
        reason: drug.reasons,
      })
      mainApi.addMedicine({
        continued: drug.continued,
        name: drug.name,
        type: drug.type,
        dosage: Number(drug.dosage),
        dosage_type: drug.measure,
        dosage_days: Number(drug.duration),
        food_time: drug.foodIntake === 'before food' ? 'before' : drug.foodIntake === 'after food' ? 'after' : 'with',
        frequency: drug.frequency === 'Specific days' ? 'specific_days' : drug.frequency === 'Every day' ? 'everyday' : 'one_time_intake',
        days_list: drug.days.length === 0 || !drug.continued ? null : {
          mon: drug.days.indexOf('Mon') >= 0,
          tue: drug.days.indexOf('Tue') >= 0,
          wed: drug.days.indexOf('Wed') >= 0,
          thu: drug.days.indexOf('Thu') >= 0,
          fri: drug.days.indexOf('Fri') >= 0,
          sat: drug.days.indexOf('Sat') >= 0,
          sun: drug.days.indexOf('Sun') >= 0,
        },
        intake_times: drug.intakeTime.length === 0 || !drug.continued ? null : drug.intakeTime.map((item) => {
          return {
            intake_time: {
              hours: Number(item.exactTime.hours),
              minutes: Number(item.exactTime.min),
            },
            dose_amount: item.dose
          }
        }),
        reason: drug.continued ? null : {
          lack_efficacy: drug.reasons.lackEfficacy,
          side_effects: drug.reasons.sideEffects,
          drugs_interaction: drug.reasons.drugsInteraction,
          form_of_use_reason: {
            difficulty_swallowing: drug.reasons.useFormReason.difficultySwallowing,
            bad_flavor: drug.reasons.useFormReason.badFlavor,
            other_form_of_use: drug.reasons.useFormReason.otherUseForm,
          },
          cost: drug.reasons.cost,
          aviability: drug.reasons.aviability,
          other_reason: drug.reasons.otherReasonsName,
        },
        user_syndrome_id: drug.diagnosis._id,
      })
        .then((res) => {
          // setStep(4);
          if (location.pathname === "/sign-up/disorders") {
            setStep(3);
          } else if (location.pathname === "/profile/medication-data") {
            setStep(0);
            getCurentUser()
          }
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
      // const newMedicine = medicine.slice();
      // newMedicine.push(drug);
      // setMedicine(newMedicine);


    }
  }

  function handleBackStep() {
    if (drugStep === 0) {
      if (location.pathname === "/sign-up/disorders") {
        setStep(3);
      } else if (location.pathname === "/profile/medication-data") {
        setStep(0);
      }
    } else if (drugStep === 1) {
      setDrugStep(drugStep - 1);
    }
  }

  return (
    <section className="new-medicine">
      <form className="new-medicine__form" id="form">
        {drugStep === 0 ? (
          <MedicineForm
            title="Add medicine"
            {...{
              drug,
              isFormValid,
              handleChange,
              handleSelectChange,
              setFormValid,
              setDrugFormValid,
            }}
          />
        ) : null}

        {drugStep === 1 && step === "new-current-medicine" ? (
          <IntakeForm
            {...{
              drug,
              index,
              isFormValid,
              handleChange,
              handleSelectChange,
              setFormValid,
              setDrugFormValid,
              setIndex,
              setDrug,
            }}
          />
        ) : null}

        {drugStep === 1 && step === "new-discontinued-medicine" ? (
          <DiscontinuedMedicine
            {...{
              drug,
              setDrugFormValid,
              setDrug,
            }}
          />
        ) : null}

        <div className="new-medicine__buttons-container">
          <div className="new-medicine__buttons-box">
            <button
              className="new-medicine__back-button"
              type="button"
              aria-label="Go to previous page"
              onClick={handleBackStep}
            >
              {BACK_ARROW_ICON}
            </button>
            <button
              className={`new-medicine__next-button ${!isDrugFormValid ? "new-medicine__next-button_disabled" : ""
                }`}
              type="button"
              onClick={handleContinue}
            >
              {drugStep === 1 ? "Save" : "Continue"}
            </button>
          </div>
        </div>
      </form>
    </section>
  );
}

export default NewMedicine;
