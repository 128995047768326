import { useEffect, useRef, useState } from "react";
import DrugCard from "./DrugCard/DrugCard";
import DeletePopup from "../Register/Disorders/DeletePopup/DeletePopup";
import { MORE_BUTTON_ICON } from "../../assets/utils/icons";
import "./MedicineCards.css";
import mainApi from "../../assets/api/mainApi";
import { fromatDrugFromApi } from "../../assets/utils/utilis";
import MiniPreloader from "../MiniPreloader/MiniPreloader";
import { useLocation } from "react-router-dom";

function MedicineCards({ medicine, setMedicine, setStep, setDrugToEdit, isInitPreloaderVisible, getCurentUser }) {
  const location = useLocation()
  const [drugToDelete, setDrugToDelete] = useState({});
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);




  const listCONTINUEDInnerRef = useRef();
  const listDISCONTINUEDInnerRef = useRef();

  const [pageCONTINUEDValue, setPageCONTINUEDValue] = useState(0);
  const [prevCONTINUEDScrollPosition, setPrevCONTINUEDScrollPosition] = useState(-1);

  const [pageDISCONTINUEDValue, setPageDISCONTINUEDValue] = useState(0);
  const [prevDISCONTINUEDScrollPosition, setPrevDISCONTINUEDScrollPosition] = useState(-1);


  const [scrollPosition, setScrollPosition] = useState(0);
  // const [isPreloaderVisible, setPreloaderVisible] = useState(false)
  const [scrollTraking, setScrollTraking] = useState(true);
  const handleScroll = () => {
    const position = window.pageYOffset;

    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {

    if (listCONTINUEDInnerRef.current && scrollTraking && scrollPosition > prevCONTINUEDScrollPosition && medicine && medicine.length > 0 && medicine.filter((el) => el.continued).length > 0) {
      setPrevCONTINUEDScrollPosition(scrollPosition)
      const { scrollHeight } = listCONTINUEDInnerRef.current;
      if (scrollHeight < scrollPosition + 300) {
        setScrollTraking(false)
        setPageCONTINUEDValue(pageCONTINUEDValue + 1)
        setTimeout(() => {
          setScrollTraking(true)
        }, 500);
      }
    }
  }, [scrollPosition, scrollTraking, prevCONTINUEDScrollPosition, pageCONTINUEDValue, medicine]);

  useEffect(() => {

    if (pageCONTINUEDValue > 0 && medicine && medicine.length > 0 && medicine.filter((el) => el.continued).length === 10 * pageCONTINUEDValue) {
      let prevId = medicine.filter((el) => el.continued)[medicine.filter((el) => el.continued).length - 1]._id
      console.log(prevId)
      console.log('ss')
      mainApi.getDrugs({ still_taking: true, last_id: prevId, limit: 10 })
        .then((res) => {

          setMedicine(prewList => prewList.concat(res.drugs.map((item) => {
            return fromatDrugFromApi(item)
          })))
        })
        .catch((err) => {
          console.log(err)
        })
      // mainApi.getPatientMedicalIntakesIndexed({ user_id: patientId, limit: 20, last_id: prevId  })
      //   .then((res) => {
      //     console.log(res.intakes)
      //     setIndexedIntakeData(prewList => prewList.concat(res.intakes))
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //   })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCONTINUEDValue, medicine])



  useEffect(() => {

    if (listDISCONTINUEDInnerRef.current && scrollTraking && scrollPosition > prevDISCONTINUEDScrollPosition && medicine && medicine.length > 0 && medicine.filter((el) => !el.continued).length > 0) {
      setPrevDISCONTINUEDScrollPosition(scrollPosition)
      const { scrollHeight } = listDISCONTINUEDInnerRef.current;

      if (scrollHeight < scrollPosition + 300) {
        setScrollTraking(false)
        setPageDISCONTINUEDValue(pageDISCONTINUEDValue + 1)
        setTimeout(() => {
          setScrollTraking(true)
        }, 500);
      }
    }
  }, [scrollPosition, scrollTraking, prevDISCONTINUEDScrollPosition, pageDISCONTINUEDValue, medicine]);

  useEffect(() => {

    if (pageDISCONTINUEDValue > 0 && medicine && medicine.length > 0 && medicine.filter((el) => !el.continued).length === 10 * pageDISCONTINUEDValue) {
      let prevId = medicine.filter((el) => !el.continued)[medicine.filter((el) => !el.continued).length - 1]._id
      console.log(prevId)
      console.log('ss')
      mainApi.getDrugs({ still_taking: false, last_id: prevId, limit: 10 })
        .then((res) => {
          console.log(res.medicines)
          setMedicine(prewList => prewList.concat(res.drugs.map((item) => {
            return fromatDrugFromApi(item)
          })))
        })
        .catch((err) => {
          console.log(err)
        })
      // mainApi.getPatientMedicalIntakesIndexed({ user_id: patientId, limit: 20, last_id: prevId  })
      //   .then((res) => {
      //     console.log(res.intakes)
      //     setIndexedIntakeData(prewList => prewList.concat(res.intakes))
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //   })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageDISCONTINUEDValue, medicine])



  function handleRemoveCard() {

    mainApi.deleteDrug({ drug_id: drugToDelete._id })
      .then((res) => {
        console.log(res)
        mainApi.getDrugs({ still_taking: true, limit: 10 })
          .then((res) => {
            console.log(res.drugs)
            mainApi.getDrugs({ still_taking: false, limit: 10 })
              .then((res2) => {
                console.log(res2.drugs)
                window.scrollTo(0, 0)
                setPrevCONTINUEDScrollPosition(0)
                setPageCONTINUEDValue(0)
                setPrevDISCONTINUEDScrollPosition(0)
                setPageDISCONTINUEDValue(0)

                setMedicine(res.drugs.map((item) => {
                  return fromatDrugFromApi(item)
                }).concat(res2.drugs.map((item) => {
                  return fromatDrugFromApi(item)
                })))
                if (location.pathname === "/profile/medication-data") {
                  getCurentUser()
                }
               
              })
              .catch((err) => {
                console.log(err)
              })
          })
          .catch((err) => {
            console.log(err)
          })
      })
      .catch((err) => {

      })
    closeDeletePopup();
  }

  function openDeletePopup(drug) {
    setDeletePopupOpen(true);
    setDrugToDelete(drug);
  }

  function closeDeletePopup() {
    setDeletePopupOpen(false);
  }

  function handleMedicineEditClick(drug) {
    setStep("edit-medicine");
    setDrugToEdit(drug);
  }

  function handleIntakeEditClick(drug) {
    setStep("edit-schedule");
    setDrugToEdit(drug);
  }

  function handleReasonsEditClick(drug) {
    setStep("edit-reasons");
    setDrugToEdit(drug);
  }


  return (
    <div className="medicine-cards">
    {isInitPreloaderVisible && medicine.length === 0 ?
        <div className="medicine-cards__preloader">
          <MiniPreloader />
        </div>
        :
        <div className="medicine-cards__content">
          <div className="medicine-cards__cards-block">
            <p className="medicine-cards__block-title">Current medication</p>
            {medicine.length > 0 ? (
              <ul className="medicine-cards__drug-list" ref={listCONTINUEDInnerRef}>
                {medicine
                  .filter((el) => el.continued)
                  .map((drug, i) => (
                    <DrugCard
                      onDelete={openDeletePopup}
                      onMedicineEdit={handleMedicineEditClick}
                      onIntakeEdit={handleIntakeEditClick}
                      {...{ drug }}
                      key={`current-drug-main${i}`}
                    />
                  ))}
              </ul>
            ) : null}
            <button
              className="medicine-cards__add-button"
              type="button"
              onClick={() => setStep("new-current-medicine")}
            >
              <div className="medicine-cards__button-icon">
                {MORE_BUTTON_ICON}
              </div>
              <p className="medicine-cards__button-text">
                Add{" "}
                {medicine.filter((el) => el.continued).length === 0
                  ? "a"
                  : "another"}{" "}
                drug
              </p>
            </button>
          </div>
        <div className="medicine-cards__cards-block">
            <p className="medicine-cards__block-title">Discontinued</p>
            {medicine.length > 0 ? (
              <ul className="medicine-cards__drug-list" ref={listDISCONTINUEDInnerRef}>
                {medicine
                  .filter((el) => !el.continued)
                  .map((drug, i) => (
                    <DrugCard
                      onDelete={openDeletePopup}
                      onMedicineEdit={handleMedicineEditClick}
                      onReasonsEdit={handleReasonsEditClick}
                      {...{ drug }}
                      key={`discontinued-drug-main${i}`}
                    />
                  ))}
              </ul>
            ) : null}
            <button
              className="medicine-cards__add-button"
              type="button"
              onClick={() => setStep("new-discontinued-medicine")}
            >
              <div className="medicine-cards__button-icon">
                {MORE_BUTTON_ICON}
              </div>
              <p className="medicine-cards__button-text">Add discontinued drug</p>
            </button>
          </div>

        </div>
      }


      <DeletePopup
        isPopupOpen={isDeletePopupOpen}
        title={"Do you really want to remove this medicine?"}
        onRemove={handleRemoveCard}
        {...{ closeDeletePopup }}
      />
    </div>
  );
}

export default MedicineCards;
