import { useState, useEffect, useRef } from "react";
import Input from "../../../Input/Input";
import SelectInput from "../../../SelectInput/SelectInput";
import SearchPopup from "../SearchPopup/SearchPopup";
import SearchCard from "../SearchCard/SearchCard";
import DeletePopup from "../DeletePopup/DeletePopup";

import { SEARCH_ICON } from "../../../../assets/utils/icons";
import "./Syndromes.css";
import mainApi from "../../../../assets/api/mainApi";
import MiniPreloader from "../../../MiniPreloader/MiniPreloader";


function Syndromes({
  syndromes,
  setSyndromes,
  syndromeStep,
  setSyndromeStep,
  setDisabled,
  selectedItem,
  setSelectedItem,
  isInitPreloaderVisible
}) {
  const [index, setIndex] = useState(syndromes.length);
  const [searchValue, setSearchValue] = useState("");
  const [isSearchPopupVisible, setSearchPopupVisible] = useState(false);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);

  useEffect(() => {
    if (syndromeStep === 0) {
      syndromes.length > 0 ? setDisabled(false) : setDisabled(true);
    } else {
      selectedItem && Boolean(selectedItem && selectedItem[selectedItem.disease.data[0].key] && selectedItem[selectedItem.disease.data[0].key].value)
        ? setDisabled(false)
        : setDisabled(true);
    }
  }, [setDisabled, syndromeStep, syndromes.length, syndromes, index, selectedItem]);

  const [isSearchSyndromesPreloaderVisible, setSearchSyndromesPreloaderVisible] = useState(true)
  const [searchHints, setSearchHints] = useState([])

  function handleSearchChange(evt) {

    const value = evt.target.value;

    setSearchValue(value);
    if (value) {
      setSearchSyndromesPreloaderVisible(true)
      mainApi.searchSyndromes({ text: value, limit: 3 })
        .then((res) => {
          setSearchHints(res.sources)
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setSearchSyndromesPreloaderVisible(false)
        })
    }

  }



  function toggleSearchPopupVisibility(value) {
    setSearchPopupVisible(value);
  }


  const [isSyndromePreloaderVisible, setSyndromePreloaderVisible] = useState(false)

  function handleSyndromeChange(item) {
    console.log(item)
    if (!item.syndromes_id) {
      setSyndromePreloaderVisible(true)
      mainApi.getUserSyndromeById({ syndrome_id: item._id })
        .then((res) => {
          console.log(res)
          let returnObj = {
            disease: {
              data: res.syndrome.data,
              text: res.syndrome.text,
              name: res.syndrome.text,
              _id: item._id,
            },
            _id: res._id,
            syndromes_id: res.syndromes_id,
          }
          let dataResArray = res.syndrome.data
          for (let index = 0; index < dataResArray.length; index++) {
            returnObj[dataResArray[index].key] = res.data[dataResArray[index].key]
          }
          setSyndromes(prewArray => (prewArray.map((item) => {
            if (item._id === res.syndromes_id) {
              return returnObj
            } else return item
          })))
          setSelectedItem({
            ...returnObj
          })
        })
        .catch((err) => {
          console.log(err)
          setSelectedItem({
            ...item
          })
        })
        .finally(() => {
          setSyndromePreloaderVisible(false)
        })
    } else {
      setSyndromePreloaderVisible(false)
      setSelectedItem({
        ...item
      })
    }



    setIndex(syndromes.length)
    setSyndromeStep(syndromeStep + 1);
  }


  function handleSelectChange(name, value, type) {
    // const arr = syndromes.slice();

    // console.log(name, value, arr[index], arr)
    // arr[index] = { ...arr[index], [name]: value };
    // setSyndromes(arr);

    console.log(selectedItem)

    setSelectedItem(prewItem => ({
      ...prewItem,
      [name]: { value, type },
    }))
    setDisabled(false)
  }

  function handleEditClick(item) {
    setSelectedItem(item)
    setSyndromeStep(syndromeStep + 1);
    console.log(item)
  }

  const [syndromeToDelite, setSyndromeToDelite] = useState({})
  function handleDeleteClick(item) {
    setDeletePopupOpen(true);
    console.log(item)
    setSyndromeToDelite(item)
  }

  function handleRemoveCard() {
    // const arr = syndromes.slice();
    // const newSyndromes = arr.filter((item, i) => i !== index);
    // setSyndromes(newSyndromes);
    console.log(syndromeToDelite)
    mainApi.deleteSyndrome({ id: syndromeToDelite._id })
      .then(() => {
        setSyndromes(prewArray => prewArray.filter((item) => {
          if (item._id === syndromeToDelite._id) return false
          else return true
        }))
      })
      .catch((err) => {
        console.log(err)
      })
    closeDeletePopup();
  }

  function closeDeletePopup() {
    setDeletePopupOpen(false);
  }

  const listInnerRef = useRef();

  const [pageValue, setPageValue] = useState(0);
  const [prevScrollPosition, setPrevScrollPosition] = useState(-1);
  const [scrollPosition, setScrollPosition] = useState(0);
  // const [isPreloaderVisible, setPreloaderVisible] = useState(false)
  const [scrollTraking, setScrollTraking] = useState(true);
  const handleScroll = () => {
    const position = window.pageYOffset;

    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {

    if (listInnerRef.current && scrollTraking && scrollPosition > prevScrollPosition && syndromes && syndromes.length > 0) {
      setPrevScrollPosition(scrollPosition)
      const { scrollHeight } = listInnerRef.current;
      if (scrollHeight < scrollPosition + 800) {
        setScrollTraking(false)
        setPageValue(pageValue + 1)
        setTimeout(() => {
          setScrollTraking(true)
        }, 500);
      }
    }
  }, [scrollPosition, scrollTraking, prevScrollPosition, pageValue, syndromes]);

  useEffect(() => {

    if (pageValue > 0 && syndromes && syndromes.length === 10 * pageValue) {
      let prevId = syndromes[syndromes.length - 1]._id
      console.log(prevId)
      console.log('ss')
      // mainApi.getPatients({ limit: 20, last_id: prevId })
      //   .then((res) => {
      //     console.log(res.patients)
      //     setPatients(prewList => prewList.concat(res.patients))
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //   })
      // setPreloaderVisible(true)

      mainApi.getSyndromes({ limit: 10, last_id: prevId })
        .then((res) => {
          console.log(res.user_syndromes)
          setSyndromes(prewList => prewList.concat(res.user_syndromes.map((item) => {
            let returnObj = {
              disease: {
                data: item.syndrome.data,
                text: item.syndrome.text,
                name: item.syndrome.text,
                _id: item._id,
              },
              _id: item._id,
              syndromes_id: item.syndromes_id,
            }
            let dataResArray = item.syndrome.data
            for (let index = 0; index < dataResArray.length; index++) {
              returnObj[dataResArray[index].key] = item.data[dataResArray[index].key]
            }
            console.log({ returnObj })
            return returnObj
          })))
        })
        .catch((err) => {
          console.log(err)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageValue, syndromes])

  useEffect(() => {
    if (syndromeStep === 0) {
      setSearchValue('')
    }
  }, [syndromeStep])

  return (
    <section className="syndrome">
      <form className="syndrome__form" id="form">
        {syndromeStep === 0 ? (
          <div className="syndrome__form-block">
            <p className="syndrome__title">Search for your syndrome</p>
            <div className="syndrome__search-box">
              <Input
                label="Search for your syndrome (Epilepsy)"
                value={searchValue}
                name="search"
                type="text"
                handleChange={handleSearchChange}
                isRequired={false}
                icon={SEARCH_ICON}
                handleVisibility={toggleSearchPopupVisibility}
              />
              <SearchPopup
                isPreloaderVisible={isSearchSyndromesPreloaderVisible}
                onSelect={handleSyndromeChange}
                value={syndromes}
                searchHints={searchHints}
                {...{ searchValue, isSearchPopupVisible }}
              />
            </div>

            <ul className="syndromes__list" ref={listInnerRef}>
              {isInitPreloaderVisible ?
                <div className="syndrome__preloader">
                  <MiniPreloader />
                </div>
                : syndromes.length > 0
                  ? syndromes.map((syndrome, i) => (
                    <SearchCard
                      index={i}
                      type="added"
                      item={syndrome}
                      onSelect={() => { }}
                      onEdit={handleEditClick}
                      onDelete={handleDeleteClick}
                      key={`syndromes-item${i}`}
                    />
                  ))
                  : null}
            </ul>
          </div>
        ) : null}

        {syndromeStep === 1 ? <div className="syndrome__form-block">
          <p className="syndrome__title">
            Enter details of&nbsp;your syndrome
          </p>

          {
            isSyndromePreloaderVisible ?
              <div className="syndrome__preloader">
                <MiniPreloader />
              </div>
              :
              <>
                {selectedItem ? (

                  <>
                    <div className="syndrome__card-box">
                      <SearchCard type="selected" item={selectedItem} />
                    </div>
                    <div className="syndrome__select-box">
                      {selectedItem.disease.data && selectedItem.disease.data.length > 0 ? selectedItem.disease.data.map((item, i) => (
                        <div key={`syndrome input - ${i}`}>
                          {item.type === 'array_choose_one' ?
                            <SelectInput
                              label={item.name}
                              value={selectedItem && selectedItem[item.key] && selectedItem[item.key].value ? selectedItem[item.key].value : ''}
                              name={item.key}
                              isRequired={true}
                              type={item.type}
                              selectList={item.value}
                              popupTitle={item.title}
                              onSelect={handleSelectChange}
                            />
                            : <></>}
                        </div>

                      )) : <></>}


                    </div>
                  </>
                ) : null}
              </>
          }

        </div>
          : <></>
        }


      </form>
      <DeletePopup
        isPopupOpen={isDeletePopupOpen}
        title={"Do you really want to remove this syndrome?"}
        onRemove={handleRemoveCard}
        {...{ closeDeletePopup }}
      />
    </section>
  );
}

export default Syndromes;
