import React, { useState } from 'react';
import './NameAndSex.css';
import '../Register.css'
import InputHeading from '../InputsTitle/InputHeading';


function NameAndSex({ firstNameValue, setFirstNameValue, firstNameValidity, setFirstNameValidity, lastNameValue, setLastNameValue, lastNameValidity, setLastNameValidity, genderValue, setGenderValue }) {

    const [isFocuced, setIsFocuced] = useState({});


    function handleFocused(name) {

        setIsFocuced({ ...isFocuced, [name]: true });
    }

    function handleUnfocused(name) {

        setIsFocuced({ ...isFocuced, [name]: false });
    }

    function handleFirstNameChange(e) {
        let inputValue = e.target.value
        setFirstNameValue(inputValue)
        if (!inputValue) {
            setFirstNameValidity({
                errorMassage: (''),
                validState: false
            })
        } else {
            setFirstNameValidity({
                errorMassage: (''),
                validState: true
            })
        }
    }

    function handleLastNameChange(e) {
        let inputValue = e.target.value
        setLastNameValue(inputValue)
        if (!inputValue) {
            setLastNameValidity({
                errorMassage: (''),
                validState: false
            })
        } else {
            setLastNameValidity({
                errorMassage: (''),
                validState: true
            })
        }
    }

function handleGenderChange(gender){
    if(gender === genderValue){
        setGenderValue('')
    } else{
        setGenderValue(gender)
    }
}
    return (
        <div className="name-and-sex">
            <InputHeading title={'Name and gender'} subtitle={'To make our research most effective, we require your name and gender'} />
            <div className={`name-and-sex__inputs`}>
                <div
                    className={`name-and-sex__input-box ${firstNameValue || isFocuced.first_name ? "name-and-sex__input-box_focused" : ""
                        }`}

                >
                    <label className="name-and-sex__input-label" htmlFor="question">
                        First name
                    </label>
                    <input
                        onClick={() => handleFocused('first_name')}
                        onFocus={() => handleFocused('first_name')}
                        onBlur={() => handleUnfocused('first_name')}
                        name="first name"
                        type='text'
                        className={`name-and-sex__input ${firstNameValidity.validState
                            ? "name-and-sex__input_valid"
                            : ""
                            }
                            ${firstNameValidity.errorMassage ? 'name-and-sex__input_error' : ''}
                            `}
                        value={firstNameValue}
                        onChange={handleFirstNameChange}
                    />

                </div>
                <p className="name-and-sex__input-error">{firstNameValidity.errorMassage}</p>
                <div
                    className={`name-and-sex__input-box ${lastNameValue || isFocuced.last_name ? "name-and-sex__input-box_focused" : ""
                        }`}

                >
                    <label className="name-and-sex__input-label" htmlFor="question">
                        Last name
                    </label>

                    <input
                        onClick={() => handleFocused('last_name')}
                        onFocus={() => handleFocused('last_name')}
                        onBlur={() => handleUnfocused('last_name')}
                        name="last name"
                        type={'text'}
                        className={`name-and-sex__input ${lastNameValidity.validState
                            ? "name-and-sex__input_valid"
                            : ""
                            }
                            ${lastNameValidity.errorMassage ? 'name-and-sex__input_error' : ''}
                            `}
                        value={lastNameValue}
                        onChange={handleLastNameChange}
                    />
                </div>
                <p className="name-and-sex__input-error">{lastNameValidity.errorMassage}</p>
                <div className="name-and-sex__gender-selectors">
                    <div className={`name-and-sex__gender-selector ${genderValue === 'Male' ? 'name-and-sex__gender-selector_selected' :''}`} onClick={()=> handleGenderChange('Male')}>
                        <svg className={`name-and-sex__gender-selector-icon`} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className={`name-and-sex__gender-selector-icon-fill ${genderValue === 'Male' ? 'name-and-sex_gender-selector-icon-fill_selected' :''}`} d="M26.3073 0C25.5427 0 24.9226 0.620056 24.9226 1.38469C24.9226 2.14932 25.5426 2.76938 26.3073 2.76938H31.2687L24.1869 9.85114C21.6291 7.72989 18.3414 6.46141 14.7691 6.46141C6.6288 6.46141 0 13.0902 0 21.2305C0 29.3709 6.6288 35.9997 14.7691 35.9997C22.9095 35.9997 29.5383 29.3709 29.5383 21.2305C29.5383 17.6544 28.2591 14.3712 26.1344 11.8127L33.2306 4.71648V9.69241C33.2306 10.457 33.8507 11.0771 34.6153 11.0771C35.3799 11.0771 36 10.457 36 9.69241V1.38477H35.9997C35.9997 1.01776 35.8538 0.66555 35.5943 0.405739C35.3345 0.146257 34.9823 0.000411859 34.6153 0.000411859L26.3073 0ZM14.7689 9.23071C21.4128 9.23071 26.7688 14.5868 26.7688 21.2306C26.7688 27.8742 21.4127 33.2305 14.7689 33.2305C8.12508 33.2305 2.76896 27.8744 2.76896 21.2306C2.76896 14.5871 8.12508 9.23071 14.7689 9.23071Z" fill="#191C1B" />
                        </svg>
                        <p className={`name-and-sex__gender-selector-name ${genderValue === 'Male' ? 'name-and-sex__gender-selector-name_selected' :''}`}>Male</p>
                    </div>
                    <div className={`name-and-sex__gender-selector ${genderValue === 'Female' ? 'name-and-sex__gender-selector_selected' :''}`} onClick={()=> handleGenderChange('Female')}>
                        <svg className={`name-and-sex__gender-selector-icon`} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className={`name-and-sex__gender-selector-icon-fill ${genderValue === 'Female' ? 'name-and-sex_gender-selector-icon-fill_selected' :''}`} d="M34.55 0C34.1696 0.0141536 33.8254 0.256704 33.599 0.432342L29.968 4.06418L26.3371 0.432342C26.0473 0.1306 25.6151 -0.0279828 25.1989 0.0144773C24.6811 0.0701284 24.2106 0.443921 24.0399 0.936066C23.8688 1.42824 24.0061 2.01338 24.3779 2.37819L28.0088 6.02412L24.1618 9.87213C21.6066 7.75291 18.3223 6.48545 14.7538 6.48545C6.6219 6.48545 0 13.1089 0 21.2427C0 29.3765 6.6219 36 14.7538 36C22.8856 36 29.5075 29.3765 29.5075 21.2427C29.5075 17.6694 28.2297 14.3889 26.1072 11.8325L29.9683 7.9704L33.5993 11.6022C34.0952 12.17 35.0809 12.2054 35.6158 11.6743C36.1506 11.1432 36.1229 10.1569 35.5588 9.65669L31.9279 6.02486L35.5588 2.37894C35.9579 1.99324 36.0879 1.35466 35.8711 0.843503C35.6544 0.332352 35.105 -0.0179691 34.5503 0.00105395L34.55 0ZM14.7535 9.25212C21.3904 9.25212 26.7409 14.6039 26.7409 21.2424C26.7409 27.8806 21.3904 33.2327 14.7535 33.2327C8.1165 33.2327 2.76604 27.8809 2.76604 21.2424C2.76604 14.6042 8.11658 9.25212 14.7535 9.25212Z" fill="#04B994" />
                        </svg>
                        <p className={`name-and-sex__gender-selector-name ${genderValue === 'Female' ? 'name-and-sex__gender-selector-name_selected' :''}`}>Female</p>
                    </div>
                    <div className={`name-and-sex__gender-selector ${genderValue === 'Other' ? 'name-and-sex__gender-selector_selected' :''}`} onClick={()=> handleGenderChange('Other')}>
                        <svg className={`name-and-sex__gender-selector-icon`} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className={`name-and-sex__gender-selector-icon-fill ${genderValue === 'Other' ? 'name-and-sex_gender-selector-icon-fill_selected' :''}`} d="M26.3073 0C25.5427 0 24.9226 0.619904 24.9226 1.38435C24.9226 2.14879 25.5426 2.7687 26.3073 2.7687H31.2687L24.1869 9.84873C21.6291 7.728 18.3414 6.45983 14.7691 6.45983C6.6288 6.45983 0 13.087 0 21.2253C0 29.3637 6.6288 35.9909 14.7691 35.9909C22.9095 35.9909 29.5383 29.3637 29.5383 21.2253C29.5383 17.6501 28.2591 14.3677 26.1344 11.8099L33.2306 4.71533V9.69003C33.2306 10.4545 33.8507 11.0744 34.6153 11.0744C35.3799 11.0744 36 10.4545 36 9.69003V1.38443H35.9997C35.9997 1.01751 35.8538 0.665388 35.5943 0.40564C35.3345 0.146222 34.9823 0.000411758 34.6153 0.000411758L26.3073 0ZM14.7689 9.22845C21.4128 9.22845 26.7688 14.5833 26.7688 21.2254C26.7688 27.8674 21.4127 33.2224 14.7689 33.2224C8.12508 33.2224 2.76896 27.8676 2.76896 21.2254C2.76896 14.5835 8.12508 9.22845 14.7689 9.22845Z" fill="#191C1B" />
                            <path className={`name-and-sex__gender-selector-icon-fill ${genderValue === 'Other' ? 'name-and-sex_gender-selector-icon-fill_selected' :''}`} d="M32.1135 2.45178C31.7326 2.46594 31.3882 2.70863 31.1615 2.88437L27.5267 6.51823L23.892 2.88437C23.6019 2.58246 23.1692 2.42378 22.7527 2.46627C22.2343 2.52195 21.7633 2.89595 21.5924 3.38837C21.4211 3.88082 21.5586 4.46629 21.9308 4.8313L25.5655 8.47926L24.1869 9.85751C21.6291 7.73711 18.3414 6.46894 14.7691 6.46894C6.6288 6.46894 0 13.0961 0 21.2345C0 29.3728 6.6288 36 14.7691 36C22.9095 36 29.5383 29.3728 29.5383 21.2345C29.5383 17.6592 28.2591 14.3768 26.1344 11.819L27.527 10.4266L31.1618 14.0605C31.6582 14.6286 32.645 14.664 33.1803 14.1326C33.7157 13.6012 33.6881 12.6143 33.1234 12.1139L29.4886 8.48L33.1234 4.83205C33.5229 4.44613 33.653 3.80719 33.436 3.29576C33.219 2.78432 32.6691 2.4338 32.1138 2.45284L32.1135 2.45178ZM14.7688 9.23716C21.4127 9.23716 26.7688 14.592 26.7688 21.2341C26.7688 27.8761 21.4126 33.2311 14.7688 33.2311C8.12496 33.2311 2.76892 27.8763 2.76892 21.2341C2.76892 14.5922 8.12504 9.23716 14.7688 9.23716Z" fill="#191C1B" />
                        </svg>
                        <p className={`name-and-sex__gender-selector-name ${genderValue === 'Other' ? 'name-and-sex__gender-selector-name_selected' :''}`}>Other</p>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default NameAndSex;
