import { useState, useEffect } from "react";
import DrugCard from "../DrugCard/DrugCard";
import RegCheckbox from "../../Register/RegCheckbox/RegCheckbox";
import Input from "../../Input/Input";
import "./DiscontinuedMedicine.css";

function DiscontinuedMedicine({
  drug,
  step,
  setDrugFormValid,
  setDrug,
  setDrugChanged,
}) {
  const [isFormValid, setFormValid] = useState(false);

  useEffect(() => {
    const form = document.getElementById("form");
    setFormValid(form.checkValidity());
  }, [drug, setFormValid]);

  useEffect(() => {
    if (
      isFormValid &&
      Object.values(drug.reasons).find((el) => typeof el == "boolean" && el)
    ) {
      if (drug.reasons.useForm) {
        Object.values(drug.reasons.useFormReason).find(
          (reason) => typeof reason == "boolean" && reason
        )
          ? setDrugFormValid(true)
          : setDrugFormValid(false);
      } else {
        setDrugFormValid(true);
      }
    } else {
      setDrugFormValid(false);
    }
  }, [isFormValid, drug.reasons, setDrugFormValid]);

  function handleCheckboxChange(evt) {
    const input = evt.target;
    const name = input.name;
    const value = input.type === "checkbox" ? input.checked : input.value;

    if (
      name === "difficultySwallowing" ||
      name === "badFlavor" ||
      name === "otherUseForm"
    ) {
      setDrug({
        ...drug,
        reasons: {
          ...drug.reasons,
          useForm: value ? true : drug.reasons.useForm,
          useFormReason: { ...drug.reasons.useFormReason, [name]: value },
        },
      });
    } else if (name === "useForm") {
      setDrug({
        ...drug,
        reasons: {
          ...drug.reasons,
          [name]: value,
          useFormReason: !value
            ? {
                difficultySwallowing: false,
                badFlavor: false,
                otherUseForm: false,
              }
            : { ...drug.reasons.useFormReason },
        },
      });
    } else if (name === "otherReasons") {
      setDrug({
        ...drug,
        reasons: {
          ...drug.reasons,
          [name]: value,
          otherReasonsName: value ? drug.reasons.otherReasonsName : "",
        },
      });
      setFormValid(false);
    } else {
      setDrug({
        ...drug,
        reasons: {
          ...drug.reasons,
          [name]: value,
        },
      });
      setFormValid(input.closest("form").checkValidity());
    }

    if (step === "edit-reasons") {
      setDrugChanged(true);
    }
  }

  return (
    <div className="discontinued">
      <DrugCard type="discontinued" {...{ drug }} />

      <p className="discontinued__title">
        Tell us why you have discontined the treatment above
      </p>
      <div className="discontinued__form">
        <RegCheckbox
          name="lackEfficacy"
          isRequired={false}
          isChecked={drug.reasons.lackEfficacy}
          onClick={handleCheckboxChange}
          label="Lack of efficacy"
          type="discontinued"
        />

        <RegCheckbox
          name="sideEffects"
          isRequired={false}
          isChecked={drug.reasons.sideEffects}
          onClick={handleCheckboxChange}
          label="Side effects"
          type="discontinued"
        />

        <RegCheckbox
          name="drugsInteraction"
          isRequired={false}
          isChecked={drug.reasons.drugsInteraction}
          onClick={handleCheckboxChange}
          label="Interaction with other drugs"
          type="discontinued"
        />

        <div className="discontinued__reasons-block">
          <RegCheckbox
            name="useForm"
            isRequired={false}
            isChecked={drug.reasons.useForm}
            onClick={handleCheckboxChange}
            label="Form of use"
            type="discontinued"
          />

          <div className="discontinued__reasons-box">
            <RegCheckbox
              name="difficultySwallowing"
              isRequired={false}
              isChecked={drug.reasons.useFormReason.difficultySwallowing}
              onClick={handleCheckboxChange}
              label="Difficulty swallowing"
              type="use-form"
            />

            <RegCheckbox
              name="badFlavor"
              isRequired={false}
              isChecked={drug.reasons.useFormReason.badFlavor}
              onClick={handleCheckboxChange}
              label="Bad flavor"
              type="use-form"
            />

            <RegCheckbox
              name="otherUseForm"
              isRequired={false}
              isChecked={drug.reasons.useFormReason.otherUseForm}
              onClick={handleCheckboxChange}
              label="Other"
              type="use-form"
            />
          </div>
        </div>

        <RegCheckbox
          name="cost"
          isRequired={false}
          isChecked={drug.reasons.cost}
          onClick={handleCheckboxChange}
          label="Cost"
          type="discontinued"
        />

        <RegCheckbox
          name="aviability"
          isRequired={false}
          isChecked={drug.reasons.aviability}
          onClick={handleCheckboxChange}
          label="Supply / aviability issues"
          type="discontinued"
        />

        <div className="discontinued__reasons-block">
          <RegCheckbox
            name="otherReasons"
            isRequired={false}
            isChecked={drug.reasons.otherReasons}
            onClick={handleCheckboxChange}
            label="Other reasons"
            type="discontinued"
          />

          <Input
            label="Tell us the reason"
            value={drug.reasons.otherReasonsName}
            name="otherReasonsName"
            type="text"
            handleChange={handleCheckboxChange}
            isRequired={drug.reasons.otherReasons}
            readOnly={!drug.reasons.otherReasons}
          />
        </div>
      </div>
    </div>
  );
}

export default DiscontinuedMedicine;
