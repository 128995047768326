import { useState } from "react";
import MedicineForm from "../MedicineForm/MedicineForm";
import { BACK_ARROW_ICON } from "../../../assets/utils/icons";
import "./EditMedicine.css";
import mainApi from "../../../assets/api/mainApi";
import { useLocation } from "react-router-dom";

function EditMedicine({ drug, setDrug, setMedicine, setStep, getCurentUser }) {

  const location = useLocation()

  const [isFormValid, setFormValid] = useState(false);
  const [isDrugFormValid, setDrugFormValid] = useState(false);
  const [isDrugChanged, setDrugChanged] = useState(false);



  function handleChange(evt) {
    const input = evt.target;
    const name = input.name;
    const value =
      input.inputMode === "numeric"
        ? input.value.replace(/[^0-9]/g, "")
        : input.value;
    if (name === 'duration') {
      value = Number(value) > 7000 ? 7000 : value
    }
    setDrug({
      ...drug,
      [name]: value,
    });

    setDrugChanged(true);
  }

  function handleSelectChange(name, value) {
    setDrug({ ...drug, [name]: value });
    setDrugChanged(true);
  }

  function handleContinue() {

    console.log(drug)
    if (isDrugFormValid && isDrugChanged) {
      mainApi.editMedicine({
        name: drug.name,
        type: drug.type,
        dosage: Number(drug.dosage),
        dosage_type: drug.measure,
        dosage_days: Number(drug.duration),
        food_time: drug.foodIntake === 'before food' ? 'before' : drug.foodIntake === 'after food' ? 'after' : drug.foodIntake === 'with food' ? 'with' : drug.foodIntake,
        id: drug._id,
        user_syndrome_id: drug.diagnosis._id,
      })
        .then((res) => {
          console.log(res)
          setMedicine(prewArray => prewArray.map((item) => {
            if (item._id === drug._id) {
              return drug
            } else {
              return item
            }
          }));
          if (location.pathname === "/sign-up/disorders") {
            setStep(3);
          } else if (location.pathname === "/profile/medication-data") {
            setStep(0);
            getCurentUser()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

    // const newMedicine = medicine.slice();
    // newMedicine[index] = drug;
    // 
    // setMedicine(newMedicine);


  }

  function handleBackStep() {
    if (location.pathname === "/sign-up/disorders") {
      setStep(3);
    } else if (location.pathname === "/profile/medication-data") {
      setStep(0);
    }
  }

  return (
    <section className="edit-medicine">
      <form className="edit-medicine__form" id="form">
        <MedicineForm
          title="Edit medicine"
          {...{
            drug,
            isFormValid,
            handleChange,
            handleSelectChange,
            setFormValid,
            setDrugFormValid,
          }}
        />

        <div className="edit-medicine__buttons-container">
          <div className="edit-medicine__buttons-box">
            <button
              className="edit-medicine__back-button"
              type="button"
              aria-label="Go to previous page"
              onClick={handleBackStep}
            >
              {BACK_ARROW_ICON}
            </button>
            <button
              className={`edit-medicine__next-button ${!(isDrugFormValid && isDrugChanged)
                ? "edit-medicine__next-button_disabled"
                : ""
                }`}
              type="button"
              onClick={handleContinue}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </section>
  );
}

export default EditMedicine;
