import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import mainApi from '../../../assets/api/mainApi';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';
import './EmailCode.css'


function EmailCode({ currentUser, step, code, setCode, setCodeError, codeError, setCurrentUser }) {



    const firstInput = useRef()



    function handleCodeChange(e, i) {
        setCodeError('')
        const input = e.target.value;
        const form = e.target.form;
        const index = Array.prototype.indexOf.call(form, e.target);
        console.log(input.length)
        if (input.length === 4) {
            setCode({
                0: input[0].toUpperCase(),
                1: input[1].toUpperCase(),
                2: input[2].toUpperCase(),
                3: input[3].toUpperCase(),
            });
            form.elements[3].focus();
        } else {
            if (code[i]) {
                setCode({ ...code, [i]: input[1].toUpperCase() });
                if (index !== 3) {
                    form.elements[index + 1].focus();
                }
            } else {
                setCode({ ...code, [i]: input[0].toUpperCase() });
                if (index !== 3) {
                    form.elements[index + 1].focus();
                }
            }

        }



    }



    function handleKeyDown(e) {
        const form = e.target.form;

        const index = Array.prototype.indexOf.call(form, e.target);
        console.log(e.keyCode)
        // if (e.keyCode === 13) {
        //     form.elements[index + 1].focus();
        //     e.preventDefault();
        // }
        if (e.keyCode === 8 || e.keyCode === 37) {
            // setCode({ ...code, [index]: '' });
            if (code[index]) {
                setCode({ ...code, [index]: '' });
            } else {
                if (index === 0) {
                    setCode({ ...code, [index]: '' });
                } else {
                    setCode({ ...code, [index]: '', [index - 1]: '' });
                    form.elements[index - 1].focus();
                }

            }

            e.preventDefault();
        }
    }
    function handleFormEnter(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    }



    // eslint-disable-next-line no-unused-vars
    const [timerValue, setTimerValue] = useState(0);
    const [timeLeft, setTimeLeft] = useState(0)




    useEffect(() => {
        if (currentUser) {
            const timer = setInterval(() => {
                setTimeLeft(Number(moment().format('X')) - Number(moment(`${currentUser.new_email.generated_utc}+00:00`).format('X')))
                clearInterval(timer)
            }, 1000);

        } else {
            setTimerValue(0)
        }

    }, [currentUser, timeLeft])


    const [isEmailResanding, setEmailResending] = useState(false)
    const [emailResendError, setEmailResendError] = useState('')
    function handleResendCode() {

        mainApi.sendCodeToEmail({ email: currentUser.new_email.email })
            .then((res) => {
                console.log(res)
                setCurrentUser(res)
                setTimerValue(0)
            })
            .catch((err) => {
                setEmailResendError(err.detail)
                setTimeout(() => {
                    setEmailResendError('')
                }, 5000);
                console.log(err)
            })
            .finally(() => {
                setEmailResending(false)
            })
        // mainApi.getNewWappCode({ id: userId })
        //     .then((res) => {
        //         console.log(res)
        //         setTimerValue(0)
        //     })
        //     .catch((err) => {
        //         setTimerValue(0)
        //         console.log(err)
        //     })

    }

    return (
        <div className="email-code">
            <p className="email-code__title">Enter the code we sent to <span className='email-code__title_span'>{currentUser ? currentUser.register_stage === 0 ? currentUser.new_email.email : currentUser.email : ''}</span> to confirm your email address</p>
            <p className="email-code__error">{codeError}</p>
            <form className="email-code__code" onKeyDown={handleFormEnter}>
                <div className="email-code__code-element">
                    <input inputMode='numeric' ref={firstInput} onKeyDown={handleKeyDown} className={`email-code__code-input ${codeError ? 'email-code__code-input_error' : ''}`} placeholder="0" name="search" type="text" required value={code['0'] || ''} onChange={(e) => handleCodeChange(e, 0)} minLength="0" maxLength="4" autoComplete='off'></input>
                    <div className={`email-code__code-element-line ${code[0] ? 'email-code__code-element-line_typen' : ''} ${codeError ? 'email-code__code-element-line__error' : ''}`}></div>
                </div>
                <div className="email-code__code-element">
                    <input inputMode='numeric' onKeyDown={handleKeyDown} className={`email-code__code-input ${codeError ? 'email-code__code-input_error' : ''}`} placeholder="0" name="search" type="text" required value={code['1'] || ''} onChange={(e) => handleCodeChange(e, 1)} minLength="0" maxLength="2" autoComplete='off'></input>
                    <div className={`email-code__code-element-line ${code[1] ? 'email-code__code-element-line_typen' : ''} ${codeError ? 'email-code__code-element-line__error' : ''}`}></div>
                </div>
                <div className="email-code__code-element">
                    <input inputMode='numeric' onKeyDown={handleKeyDown} className={`email-code__code-input ${codeError ? 'email-code__code-input_error' : ''}`} placeholder="0" name="search" type="text" required value={code['2'] || ''} onChange={(e) => handleCodeChange(e, 2)} minLength="0" maxLength="2" autoComplete='off'></input>
                    <div className={`email-code__code-element-line ${code[2] ? 'email-code__code-element-line_typen' : ''} ${codeError ? 'email-code__code-element-line__error' : ''}`}></div>
                </div>
                <div className="email-code__code-element">
                    <input inputMode='numeric' onKeyDown={handleKeyDown} className={`email-code__code-input ${codeError ? 'email-code__code-input_error' : ''}`} placeholder="0" name="search" type="text" required value={code['3'] || ''} onChange={(e) => handleCodeChange(e, 3)} minLength="0" maxLength="2" autoComplete='off'></input>
                    <div className={`email-code__code-element-line ${code[3] ? 'email-code__code-element-line_typen' : ''} ${codeError ? 'email-code__code-element-line__error' : ''}`}></div>
                </div>
            </form>
            <p className="email-code__not-recive">Did not recieve the code?</p>
            {/* {timeLeft} */}
            {isEmailResanding ?
                <div className='email-code__code-resend-preloader'>
                    <MiniPreloader />
                </div>
                :
                emailResendError ?
                    <p className="email-code__code-resend-error">{emailResendError}</p>
                    :
                    <>
                        {61 - Number(timeLeft) <= 0 ?
                            <p className="email-code__code-resend email-code__code-resend_active" onClick={() => {
                                if (!isEmailResanding) {
                                    setEmailResending(true)
                                    handleResendCode()
                                }

                            }}>Send again</p>
                            :
                            <p className="email-code__code-resend">Send again in {61 - timeLeft} sec</p>
                        }
                    </>
            }

        </div>
    );
}

export default EmailCode;

