import { useEffect } from "react";
//import RegCheckbox from "../../RegCheckbox/RegCheckbox";
import MedicineCards from "../../../MedicineCards/MedicineCards";
//import { OTHER_MEDICINE_LIST } from "../../../../assets/utils/disordersInfo";
import "./Medicine.css";

function Medicine({ setDisabled, medicine, setMedicine, setStep, setDrugToEdit, isInitPreloaderVisible }) {
  useEffect(() => {
    medicine.length > 0 ? setDisabled(false) : setDisabled(true);
  });

  /*function handleCheckboxChange(evt) {
    const input = evt.target;
    const name = input.name;
    const value = input.checked;
    setMedicine({ ...medicine, [name]: value });
  }*/

  return (
    <div className="medicine">
      <p className="medicine__title">
        Enter the medicine
        <br />
        you are currently taking or have discontinued
      </p>
      <MedicineCards isInitPreloaderVisible={isInitPreloaderVisible} {...{ medicine, setMedicine, setStep, setDrugToEdit }} />
      {/*<ul className="medicine__other-list">
          {OTHER_MEDICINE_LIST.map((item, i) => (
            <li className="medicine__other-item" key={`other-medicine${i}`}>
              <RegCheckbox
                name={item.split(" ").slice(0, 1).toString().toLowerCase()}
                isRequired={false}
                isChecked={
                  medicine[item.split(" ").slice(0, 1).toString().toLowerCase()]
                }
                onClick={handleCheckboxChange}
                label={item}
              />
            </li>
          ))}
              </ul>*/}
    </div>
  );
}

export default Medicine;
