import {
  DISEASE_EDIT_ICON,
  DISEASE_DELETE_ICON,
} from "../../../../assets/utils/icons";
import "./SearchCard.css";

function SearchCard({ index, item, type, onSelect, onEdit, onDelete, keyValue }) {
  function handleSelect(item) {
    console.log({ item_in_card: item })
    onSelect(item);
  }

  function handleEdit() {
    onEdit(item);
  }

  function handleDelete() {
    onDelete(item);
  }

  return (
    <li
      className={`search-card ${type === "selected" ? "search-card_selected" : ""
        }`}
      onClick={() => (type = "search" ? handleSelect(item) : undefined)}
      key={keyValue}
    >
      <p className="search-card__title">{item && item.disease && item.disease.text}</p>
      {type !== "added" ? (
        <div className="search-card__circle" />
      ) : (
        <div
          className={`search-card__buttons-box ${!onEdit ? "search-card__buttons-box_type_clinic" : ""
            }`}
        >
          {onEdit ? (
            <button
              className="search-card__button"
              type="button"
              aria-label="Edit card"
              onClick={handleEdit}
            >
              {DISEASE_EDIT_ICON}
            </button>
          ) : null}
          <button
            className="search-card__button"
            type="button"
            aria-label="Delete card"
            onClick={handleDelete}
          >
            {DISEASE_DELETE_ICON}
          </button>
        </div>
      )}
    </li>
  );
}

export default SearchCard;
