import morningIcon from '../images/main/morning.png'
import dayIcon from '../images/main/day.png'
import eveningIcon from '../images/main/evening.png'
import nightIcon from '../images/main/night.png'
import moment from 'moment';

export const monthFormatMap = {
    'Jan': '1',
    'Feb': '2',
    'Mar': '3',
    'Apr': '4',
    'May': '5',
    'Jun': '6',
    'Jul': '7',
    'Aug': '8',
    'Sep': '9',
    'Oct': '10',
    'Nov': '11',
    'Dec': '12',
};

export const mainGreeting = {
    morning: {
        icon: morningIcon,
        greetingText: 'Good morning'
    },
    day: {
        icon: dayIcon,
        greetingText: 'Good afternoon'
    },
    evening: {
        icon: eveningIcon,
        greetingText: 'Good evening'
    },
    night: {
        icon: nightIcon,
        greetingText: 'Good night'
    }

}

function formatDayValuesFromApi(values) {
    let array = []
    Object.keys(values).forEach(function (key, index) {
        if (values[key]) {
            array.push(key.substring(0, 1).toUpperCase() + key.substring(1))
        }
    })
    return array
}

export function parseFoodTime(value) {
    return value === "before" ? 'before food' : value === 'with' ? 'with food' : value === "after" ? 'after food' : value
}

export function fromatDrugFromApi(item) {
    console.log(item)
    return {
        continued: item.still_taking,
        name: item.name,
        dosage: Number(item.dosage),
        measure: item.dosage_type,
        duration: item.dosage_days,
        icon: '',
        total_intakes: item.total_intakes,
        foodIntake: parseFoodTime(item.food_time),
        frequency: item.frequency === "one_time_intake" ? 'One time intake' : item.frequency === "specific_days" ? 'Specific days' : 'Every day',
        days: item.days_list ? formatDayValuesFromApi(item.days_list) : [],
        intakesPerDay: item.intake_times ?
            item.intake_times.length
            : '',
        intakeTime: item.intake_times ? item.intake_times : [],
        reasons: item.still_taking ? null : {
            lackEfficacy: item.reason.lack_efficacy,
            sideEffects: item.reason.side_effects,
            drugsInteraction: item.reason.drugs_interaction,
            useForm: item.reason.form_of_use_reason.difficulty_swallowing && item.reason.form_of_use_reason.bad_flavor && item.reason.form_of_use_reason.other_form_of_use,
            useFormReason: {
                difficultySwallowing: item.reason.form_of_use_reason.difficulty_swallowing,
                badFlavor: item.reason.form_of_use_reason.bad_flavor,
                otherUseForm: item.reason.form_of_use_reason.other_form_of_use,
            },
            cost: item.reason.cost,
            aviability: item.reason.aviability,
            otherReasons: Boolean(item.reason.other_reason),
            otherReasonsName: item.reason.other_reason,
        },
        type: item.type,
        _id: item._id,
        diagnosis: {
            _id: item.user_syndrome_id,
            text: item.syndromes_text
        },
    }
}



export function formatMedicineToShedule({ actualShedule, cardsToFormat, type = 'actual' }) {
    let formatedTimeTable = actualShedule
    let medicineIntakes = cardsToFormat
    console.log(cardsToFormat, type)
    medicineIntakes = medicineIntakes.map((item) => {
        return {
            ...item,
            time: type === 'actual' ? `${moment(item.intake_time).format('HH:mm')}` : `${moment(`${item.utc_date}+00:00`).format('HH:mm DD.MM')}`
        }
    })


    medicineIntakes.forEach(item => {

        if (formatedTimeTable.length === 0) {
            formatedTimeTable = [{
                time: item.time,
                items: [item]
            }]

        } else if (formatedTimeTable.filter((time_table_item) => {
            if (time_table_item.time === item.time) return true
            else return false
        }).length > 0) {
            formatedTimeTable = formatedTimeTable.map((elem) => {
                if (elem.time === item.time) {
                    return {
                        ...elem,
                        items: [...elem.items, item]
                    }
                } else return elem
            })
        } else {
            formatedTimeTable = [...formatedTimeTable, {
                time: item.time,
                items: [item]
            }]

        }

    });


    return formatedTimeTable
}


export const REASONS_TO_SKIP = [
    {
        text: "The medication is not near me",
        name: 'not_near',
    },
    {
        text: "I forgot, was busy, or fell asleep",
        name: 'forgot_busy_asleep',
    },
    {
        text: "I ran out of medication",
        name: 'ran_out',
    },
    {
        text: "I don't need to take this dose",
        name: 'dont_need_to_take',
    },
    {
        text: "I am experiencing side effects",
        name: 'side_effects',
    },
    {
        text: "Other",
        name: 'other',
    },
]


export function formatHistoryToShedule({ actualShedule, cardsToFormat }) {
    let formatedTimeTable = actualShedule
    let medicineIntakes = cardsToFormat
    medicineIntakes = medicineIntakes.map((item) => {
        return {
            ...item,
            date: `${moment(`${item.utc_date}+00:00`).format('DD MMMM')}`
        }
    })


    medicineIntakes.forEach(item => {

        if (formatedTimeTable.length === 0) {
            formatedTimeTable = [{
                date: item.date,
                items: [item]
            }]

        } else if (formatedTimeTable.filter((time_table_item) => {
            if (time_table_item.date === item.date) return true
            else return false
        }).length > 0) {
            formatedTimeTable = formatedTimeTable.map((elem) => {
                if (elem.date === item.date) {
                    return {
                        ...elem,
                        items: [...elem.items, item]
                    }
                } else return elem
            })
        } else {
            formatedTimeTable = [...formatedTimeTable, {
                date: item.date,
                items: [item]
            }]

        }

    });

    console.log(formatedTimeTable)
    return formatedTimeTable
}